/**=====================
    List CSS Start
==========================**/
.list-group-item {
	&.active {
		background-color: var(--theme-default);
		border-color: var(--theme-default);
		z-index: unset;
	}
}
@each $list-name,
$list-light-color in (primary, rgba($primary-color, 0.2)),
(secondary, rgba($secondary-color, 0.2)),
(success, rgba($success-color, 0.2)),
(danger, rgba($danger-color, 0.2)),
(warning, rgba($warning-color, 0.2)),
(info, rgba($light-color, 0.2)),
(light, rgba($light-color, 0.2)),
(dark, rgba($body-font-color, 0.1)) {
	.list-light-#{$list-name} {
		background-color: $list-light-color;
		&:hover,
		&:active,
		&:focus {
			background-color: $list-light-color;
		}
	}
	.list-hover-#{$list-name} {
		background-color: unset;
		&:hover,
		&:active,
		&.active,
		&:focus {
			background-color: $list-light-color;
			color: var(--dark);
			z-index: 0;
		}
	}
}
.list-group {
	.list-group-item {
		.form-check-input {
			&:focus {
				box-shadow: unset !important;
			}
		}
	}
}
.list-behavior-1 {
	.flex-grow-1 {
		margin-left: 16px;
		@media (max-width: 1199px) {
			margin-left: 0px;
		}
	}
}
.list-behavior-2 {
	@include media-breakpoint-down(xl) {
		display: none;
	}
	.flex-grow-1 {
		margin-right: 16px;
	}
}
.main-lists-content {
	@media (max-width: 576px) {
		small {
			display: none;
		}
	}
	.list-wrapper {
		@media (max-width: 414px) {
			flex-wrap: unset;
			word-break: break-word;
		}
	}
	.list-group-item {
		&.active {
			.list-wrapper {
				.list-content {
					p {
						color: var(--white) !important;
					}
				}
			}
			p {
				color: var(--white) !important;
			}
		}
	}
}
.list-wrapper {
	display: flex;
	align-items: center;
	gap: 12px;
	flex-wrap: wrap;
	@media (max-width: 366px) {
		padding-bottom: 8px;
	}
	.list-img {
		width: calc(40px + (55 - 40) * ((100vw - 320px) / (1920 - 320)));
		height: calc(40px + (55 - 40) * ((100vw - 320px) / (1920 - 320)));
		margin-bottom: 10px;
		border-radius: 50%;
	}
	.list-content {
		h6 {
			font-weight: 700;
		}
		p {
			margin-bottom: 0;
		}
	}
}
.scrollbar-wrapper {
	max-height: 270px;
	overflow: auto;
}
@include media-breakpoint-down(sm) {
	.nav-pills {
		text-align: center;
	}
	.nav-pills {
		li {
			width: 100%;
		}
	}
	.border-tab {
		&.nav-tabs {
			display: block;
		}
	}
	.nav {
		display: inherit;
		text-align: center;
		border-bottom: none;
	}
}
.list-light-dark {
	background-color: rgba($body-font-color, 0.1);
	@media (max-width: 1199px) {
		background-color: unset;
		&:hover {
			background-color: unset;
		}
	}
}
.javascript-list {
	.flex-space {
		gap: 11px;
	}
}
.list-group {
	.list-group-item {
		p {
			color: var(--body-font-color);
		}
	}
}
$alert-name: primary,
secondary,
success,
danger,
warning,
info,
light,
dark;
$alert-color : var(--theme-default),
var(--theme-secondary),
var(--success-color),
var(--danger-color),
var(--warning-color),
var(--info-color),
var(--light-color),
var(--body-font-color);
@each $var in $alert-name {
	$i: index($alert-name, $var);
	.list-group-horizontal {
		&-sm,
		&-md,
		&-lg,
		&-xl,
		&-xxl {
			.list-group-item {
				&.border-left-#{$var} {
					border-left: 4px solid nth($alert-color, $i);
				}
			}
		}
	}
	.toast-#{$var} {
		color: nth($alert-color, $i) !important;
	}
}
.product-box {
	.product-img {
		position: relative;
		.product-hover {
			position: absolute;
			height: 100%;
			width: 100%;
			top: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: rgba($black, 0.3);
			opacity: 0;
			border-radius: 100%;
			transform: scale(0);
			transition: all 0.3s ease;
			ul {
				li {
					display: inline-block;
					box-shadow: 1px 1px 2px 1px rgba($black, 0.08);
					padding: 10px 13px;
					background-color: var(--white);
					font-size: 18px;
					border-radius: 10px;
					height: 45px;
					width: 45px;
					margin: 0 3px;
					cursor: pointer;
					svg {
						height: 18px;
						width: 18px;
					}
				}
			}
		}
	}
	&:hover {
		.product-hover {
			opacity: 1;
			border-radius: 0%;
			-webkit-transform: scale(1);
			transform: scale(1);
			-webkit-transition: all 0.3s ease;
			transition: all 0.3s ease;
		}
	}
}
.md-sidebar {
	.md-sidebar-aside.job-left-aside {
		@include media-breakpoint-down(xl) {
			top: 100%;
			position: absolute;
			left: 0;
			opacity: 0;
			visibility: hidden;
			z-index: 3;
			width: 280px;
			box-shadow: 0px 4px 34px rgba(10, 75, 85, 0.05);
		}
	}
}
.list-page{
	.list-group{
		i {
			margin-right: 10px;
		}
		.list-group-item {
			padding: 11px 16px;
			div {
				margin-right: auto;
				margin-left: 8px;
			}
		}
		img {
			height: 40px;
			width: 40px;
			margin-right: 10px;
		}
	}
	.main-lists-contents{
		.bg-primary{
			&.active{
				p{
					color: $white;
				}
			}
		}
	}
}
/**=====================
    List CSS End
==========================**/