.child-attendance {
    background-color: #fff;
    min-height: 100vh;
}

.attendance-header {
    background-color: #5E2B9C;
    color: white;
    padding: 1rem 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.back-arrow {
    cursor: pointer;
    font-size: 1.2rem;
}

.header-right {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.search-icon {
    color: white;
    font-size: 1.2rem;
    cursor: pointer;
}

.profile-icon img {
    width: 40px;
    height: 40px;
    border: 2px solid white;
}

.attendance-card {
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    margin: 1rem;
}

.attendance-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #eee;
}

.left-controls {
    display: flex;
    align-items: center;
    gap: 2rem;
}

.collapse-btn {
    color: #333;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0;
}

.collapse-btn:hover {
    color: #5E2B9C;
}

.right-controls {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.week-selector {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.date-nav {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    background-color: white;
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
    border: 1px solid #ddd;
}

.nav-btn {
    border: none;
    background: none;
    color: #666;
    cursor: pointer;
    padding: 0 0.5rem;
}

.selected-date {
    font-weight: 500;
    padding: 0 0.5rem;
}

.export-btn {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    background-color: white !important;
    color: #333 !important;
    border: 1px solid #ddd !important;
}

.attendance-table {
    margin: 0;
    border-collapse: separate;
    border-spacing: 0;
    width: 100%;
}

.attendance-table th,
.attendance-table td {
    border: 1px solid #e0e0e0;
    padding: 12px;
}

.attendance-table th {
    background-color: #f2f2f2;
    font-weight: bold;
    color: #308e87;
    text-align: center;
    border-top: none;
}

.attendance-table th:first-child {
    border-left: none;
}

.attendance-table th:last-child {
    border-right: none;
}

.attendance-table td {
    vertical-align: middle;
    background-color: white;
}

.attendance-table td:first-child {
    border-left: none;
    min-width: 200px;
}

.attendance-table td:last-child {
    border-right: none;
}

.attendance-table tbody tr:last-child td {
    border-bottom: none;
}

.attendance-table tbody tr:nth-child(odd) td {
    background-color: #ffffff;
}

.attendance-table tbody tr:nth-child(even) td {
    background-color: #f2f2f2;
}

/* Override for child rows */
.attendance-table .child-row td {
    background-color: inherit !important;
}

/* Override for total row */
.attendance-table .total-row td {
    background-color: #f2f2f2 !important;
    border-top: 2px solid #dee2e6 !important;
}

/* Room row hover effect */
.attendance-table .room-row:hover td {
    background-color: rgba(48, 142, 135, 0.08) !important;
}

/* Child row hover effect */
.attendance-table .child-row:hover td {
    background-color: rgba(48, 142, 135, 0.08) !important;
}

/* Room row styles */
.attendance-table .room-row {
    background-color: #ffffff;
}

.attendance-table .room-row td {
    font-weight: 500;
}

/* Child row styles */
.attendance-table .child-row {
    background-color: #f8f9fa;
}

.attendance-table .child-row td {
    padding: 12px;
    text-align: center;
}

.attendance-table .child-row td:first-child {
    text-align: left;
    padding-left: 2rem;
}

.child-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
}

.child-info .child-name {
    font-weight: 500;
    margin-bottom: 2px;
    font-size: 0.9rem;
}

.child-info .child-age {
    color: #666;
    font-size: 0.8rem;
}

.attendance-time {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    font-size: 0.9rem;
}

.expected-time {
    color: #666;
    font-size: 0.85rem;
}

.actual-time {
    color: #28a745;
    font-size: 0.85rem;
}

.temperature-indicator {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2px;
    color: #dc3545;
    font-size: 0.85rem;
    margin-top: 2px;
}

/* Total hours column */
.total-hours {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
}

.total-hours .expected {
    color: #666;
}

.total-hours .completed {
    color: #28a745;
}

/* Room info styles */
.room-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 1rem;
}

.room-name {
    display: flex;
    align-items: center;
    gap: 8px;
}

.info-icon {
    color: #666;
    font-size: 1rem;
    cursor: help;
}

/* Temperature indicator styles */
.text-danger {
    color: #dc3545;
}

.text-success {
    color: #28a745;
}

.bg-light-dark {
    background-color: #f8f9fa;
}

.bg-light-success {
    background-color: rgba(40, 167, 69, 0.1);
    color: #28a745;
}

.bg-light-danger {
    background-color: rgba(220, 53, 69, 0.1);
    color: #dc3545;
}

.bg-light-warning {
    background-color: rgba(255, 193, 7, 0.1);
    color: #ffc107;
}

/* Warning icon specific styles */
.count-item .fa-exclamation-triangle {
    color: #ffc107;
    animation: pulse 2s infinite;
}

@keyframes pulse {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.6;
    }

    100% {
        opacity: 1;
    }
}

/* Update hover effect for warning state */
.count-item.bg-light-warning:hover {
    background-color: rgba(255, 193, 7, 0.2);
}

/* Update the collapse transition */
.collapse {
    transition: height 0.3s ease-in-out;
}

/* Add hover effect to expandable rows */
.room-row:hover {
    background-color: #f8f9fa;
}

/* Adjust spacing for expanded content */
.attendance-table tr.child-row {
    background-color: #fafafa;
}

.attendance-table tr.child-row:hover {
    background-color: #f5f5f5;
}

.attendance-counts {
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 4px 0;
    width: 6rem;
}

.count-item {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 0.85rem;
    min-width: 50px;
    white-space: nowrap;
}

.count-item .icon {
    font-size: 0.8rem;
}

/* Child row specific styles */
.child-row .count-item {
    font-size: 0.8rem;
    padding: 3px 8px;
    margin: auto;
}

.child-row .attendance-counts {
    gap: 4px;
}

/* Animation for expanding/collapsing */
.attendance-counts {
    transition: all 0.2s ease-in-out;
}

/* Hover effects */
.count-item:hover {
    filter: brightness(0.95);
}

.total-row {
    background-color: #f8f9fa;
    font-weight: 500;
}

.total-row td {
    border-top: 2px solid #dee2e6 !important;
}

.total-row .room-name {
    font-weight: 600;
    color: #333;
}

.total-row .count-item {
    font-weight: 500;
}

.total-row .total-hours {
    font-weight: 500;
}

.total-row .total-hours .expected {
    color: #495057;
}

.total-row .total-hours .completed {
    color: #28a745;
    font-weight: 600;
}

.attendance-counts.children {
    flex-direction: column;
}