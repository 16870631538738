.project-list {
	ul {
		margin-bottom: 0 !important;
		border-bottom: 0;
		li {
			svg {
				height: 18px;
				vertical-align: middle;
				margin-right: 5px;
			}
		}
	}
	.border-tab.nav-tabs {
		.nav-item .nav-link {
			border: 1px solid transparent;
			padding: 5px 30px 5px 0;
			border-radius: 5px;
			display: flex;
			align-items: center;
		}
	}
	.row {
		margin: 15px;
	}
	button:focus {
		outline: none !important;
	}
	.theme-form .form-group {
		margin-bottom: 15px;
	}
	.btn {
		margin-right: 5px;
		vertical-align: -12px;
		float: right;
		svg {
			vertical-align: middle;
			height: 16px;
		}
	}
	.border-tab {
		&.nav-tabs {
			.nav-item {
				.nav-link {
					&.active {
						color: var(--theme-default);
					}
				}
			}
		}
	}
}
.project-box {
	position: relative;
	border: 1px dashed var(--gray-60);
	border-radius: 5px;
	padding: 30px;
	margin-bottom: 30px;
	.badge {
		position: absolute;
		right: 30px;
		top: 15px;
		line-height: 13px;
		@media (max-width: 360px) {
			right: 18px;
			top: 9px;
		}
	}
	.img-20 {
		width: 20px !important;
	}
	.font-light {
		color: var(--font-color) !important;
	}
	.details {
		div {
			margin-bottom: 5px;
			opacity: 0.7;
		}
	}
	p {
		font-size: 14px;
		margin-bottom: 0;
		letter-spacing: 0.3px;
	}
	.font-dark {
		color: var(--body-font-color) !important;
	}
}