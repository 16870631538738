/**=====================
    3.41 User-profile CSS start
==========================**/
.my-gallery {
  figcaption {
    display: none;
  }
}
.user-profile {
  .social-media {
    .list-inline {
      justify-content: center;
    }
  }
  .list-inline {
    justify-content: unset;
  }
  .ttl-info {
    h6 {
      font-size: 12px;
      color: var(--body-font-color);
      font-weight: 700;
    }
    span {
      color: var(--gray-700);
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box !important;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      white-space: normal;
    }
  }
  .border-right {
    border-right: 1px solid var(--border-color) !important;
  }
  hr {
    margin: 22px 0;
  }
  .hovercard {
    @include media-breakpoint-down(sm) {
      margin-bottom: 20px;
    }
    @include media-breakpoint-down(lg) {
      margin-bottom: 30px;
    }
    .cardheader {
      background: url(../../images/other-images/bg-profile.png);
      background-size: cover;
      background-position: 10%;
      height: 470px;
      @include media-breakpoint-down(sm) {
        height: 300px;
      }
      @include media-breakpoint-down(md) {
        height: 350px;
      }
    }
    .user-image {
      .avatar {
        margin-top: -80px;
        img {
          width: 100px;
          height: 100px;
          max-width: 155px;
          max-height: 155px;
          border-radius: 50%;
          border: 7px solid var(--white);
        }
      }
      .icon-wrapper {
        position: absolute;
        bottom: -20px;
        left: 51%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        width: 40px;
        border-radius: 50%;
        background-color: var(--white);
        cursor: pointer;
        overflow: hidden;
        margin: 0 auto;
        font-size: 17px;
        box-shadow: 0 0 6px 3px rgba(68, 102, 242, 0.1);
      }
    }
    .info {
      padding: 30px;
      .title {
        margin-bottom: 4px;
        font-size: 24px;
        line-height: 1;
        color: var(--dark);
        vertical-align: middle;
        a {
          color: var(--body-font-color);
          font-size: 20px;
          text-transform: uppercase;
          font-weight: 800;
        }
      }
      .desc {
        overflow: hidden;
        font-size: 14px;
        line-height: 20px;
        color: var(--gray-700);
        text-overflow: ellipsis;
        text-transform: uppercase;
      }
      .ttl-xs-mt {
        @include media-breakpoint-down(sm) {
          margin-top: 20px;
        }
      }
      .icon-wrapper {
        position: absolute;
        bottom: -20px;
        left: 51%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        width: 40px;
        border-radius: 50%;
        background-color: rgba(var(--white), 1);
        cursor: pointer;
        overflow: hidden;
        margin: 0 auto;
        font-size: 17px;
        box-shadow: 0 0 6px 3px rgba(68, 102, 242, 0.1);
      }
    }
  }
  .follow {
    margin-top: 20px;
    .follow-num {
      font-size: 20px;
      color: var(--body-font-color);
      font-weight: 700;
      display: block;
    }
    span {
      color: var(--gray-700);
    }
  }
  .profile-img-style {
    padding: 30px;
    .img-container {
      margin-top: 30px;
    }
    .user-name {
      font-size: 14px;
      color: var(--body-font-color);
      margin: 0;
      font-weight: 800;
    }
    p {
      line-height: 30px;
      font-size: 14px;
      color: var(--gray-700);
      margin-bottom: 0;
    }
    .d-flex {
      img {
        width: 50px;
      }
    }
  }
  .like-comment {
    li {
      a {
        color: var(--gray-700);
      }
      &:first-child {
        a {
          &:hover,
          &:active {
            color: var(--danger-color);
          }
        }
      }
    }
  }
  .social-media {
    a {
      padding: 0 15px;
      color: var(--gray-700);
      font-size: 24px;
      cursor: pointer;
      @include media-breakpoint-down(sm) {
        margin-right: 0px;
        font-size: 16px;
      }
      @media only screen and (max-width: 360px) {
        padding: 0 6px;
      }
      &:hover {
        color: var(--theme-default);
      }
    }
  }
}
.edit-profile {
  .profile-title {
    .d-flex {
      align-items: center;
      margin-bottom: 30px;
      .d-flex-body {
        margin-left: 14px;
      }
    }
  }
}
.user-profile {
  .hovercard {
    .info {
      .ttl-info {
        @include media-breakpoint-down(sm) {
          text-align: center !important;
        }
        @include media-breakpoint-down(md) {
          margin-bottom: 20px
        }
      }
      .user-designation {
        @include media-breakpoint-down(sm) {
          border-top: 1px solid rgba($gray-60, 0.5);
          border-bottom: 1px solid rgba($gray-60, 0.5);
          margin-bottom: 15px;
          padding: 15px 0px;
        }
      }
    }
  }
  .order-sm-0 {
    @include media-breakpoint-down(sm) {
      order: -1;
    }
  }
}
/**=====================
      3.41 User-profile CSS Ends
  ==========================**/