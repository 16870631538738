:root {
    --white: #ffffff;
    --black: #000000;
    --body-font-color: #292929;
    --font-color: #aaa3a0;
    --shape-border: #e5e5e5;
    --scrollbar-color: #aeaeae;
    --light-border: #dceee6;
    //color css
    --theme-default: #308e87; 
    --theme-secondary: #f39159;
    --body-color: #f9f7f6;
    --primary-color: #308e87;
    --secondary-color: #f39159;
    --success-color: #3eb95f;
    --info-color: #02a2b9;
    --warning-color: #ea9200;
    --danger-color: #e74b2b;
    --light-color: #f5f5f5;   
    --footer-icon: #f40000;
    --active-bg-color: #d9e8e5;
    --font-color: #aaa3a0;
    --border-color: #ebebeb;
    --light-gray: #e5e5e5;
    --light-semi-gray : #efefef;
    --bg-light: #f4f4f4;
    --light-fill: #f4f4f4;
    --dark:#292929;
    --light-background:#f2f3f7;
    --theme-body-font-color : #2f2f3b;
    --light-text: #999999;
    --light-chartist-grid-color: #f6f9fd;
    --gray-60 : #cccccc;
    --span-color: #f2f3f7;
    --light-gray-color:#aaa3a0;
    --gray-200: #e9ecef;
    --gray-700: #495057;
    --gray2: #d3d3d3;
    --table-border:#e4e4e4;
    --alert-light-light: #f1efee;
    --border-light-color:#f3f3f3;
    --form-select:#ced4da;
    --timline-dot-line:#52526c;
    --light2: #1d1e26;
    --pitch-color: #fe8a7d;
    --categorie-hover: #f4f5f8;
    --color-fiord: #455a64;
    // dropdown color
    --dropdown-menu: #2B2B2B;
    --dropdown-bg-color: #F9F9F9;
    --dropdown-content-color:#F2F2F2;
    --dropdown-toggle: #308e87;
    // table color 
    --table-hover-color: #F6F6F6;
    --table-double-color: #DEE2E6;
    //ping color 
    --ping-success: #369B36;
    --ping-light-success: #70C770;
    // social 
    --fb: #50598E;
    --twitter: #6FA2D8;
    --google-plus: #C64E40;
    --linkedin: #0077B5;
    // slider rating
    --face-hue1: 60;
	--face-hue2: 30;
    --delay-left: 0s;
    --delay-right: 0s;
    --delay-mouth-lower: 0s;
    --delay-mouth-upper: 0s;
    --input-hue: 60;
	--percent: 50%;
    --hue: 223;
	--face: hsl(var(--hue),10%,100%);
	--lt-gray: hsl(var(--hue),10%,95%);
	--gray1: hsl(var(--hue),10%,90%);
	--gray2: hsl(var(--hue),10%,80%);
	--gray7: hsl(var(--hue),10%,30%);
	--gray9: hsl(var(--hue),10%,10%);
	--primary: hsl(var(--hue),90%,55%);
	--trans-dur: 0.3s;
    // Rating
    --normal: #ECEAF3;
    --normal-shadow: #D9D8E3;
    --normal-mouth: #9795A4;
    --normal-eye: #595861;
    --active: #F8DA69;
    --active-shadow: #F4B555;
    --active-mouth: #F05136;
    --active-eye: #313036;
    --active-tear: #76b5e7;
    --active-shadow-angry: #e94f1d;
    // Landing
    --landing-footer-bg: #11312E;
}
// theme color
$primary-color: #308e87;
$secondary-color: #f39159;
$success-color: #3eb95f;
$info-color: #02a2b9;
$warning-color: #ea9200;
$danger-color: #e74b2b;
$light-color: #f5f5f5; 
$body-font-color: #292929;
$white: #ffffff;
$black: #000000;
$gray-700: #495057;
$gray-60 : #cccccc;
$dark:#292929;
.dark-only , .dark-sidebar .logo-wrapper,.dark-sidebar .page-sidebar {
    --black: #ffffff;
    --white: #181919;
    --font-color: #7c7a7a;
    --dark: #9d9999;
    --body-font-color: #e2e2e2;
    --light-gray-color: #f1f1f1;
    --gray-700: #a7a7a7;
    --gray2: #5f5f5f;
    --gray-60: #858585;
    --shape-border: #474545;
    --span-color: #1D1D1D;
    --scrollbar-color: #eee;
    --body-color: #0e0f0f;
    --light-border: #474545; 
    --light-gray: #474545;
    --sub-title: #B6B0B0;
    --border-color: #474545;
    --gray-200: #313131;
    --active-bg-color: #1D1D1D;
    --bg-light: #0e0f0f;
    --light-semi-gray:  #0e0f0f;
    --alert-light-light: #181919;
    --theme-body-font-color: #B6B0B0;
    --light-background: #0e0f0f;
    --light-color: #0e0f0f;
    --dropdown-menu: #ffffff;;
    --border-light-color: #474545;
    --timline-dot-line: #B6B0B0;
    
    // bootstrap color     
    --bs-tertiary-bg: #181919;
    --bs-body-bg: #181919;
    --bs-heading-color: #c6c6c6;
    --bs-body-color: #B6B0B0;
    --bs-border-color: #474545;
    --bs-white-rgb: #0e0f0f;
    --bs-popover-header-bg: #0e0f0f;
    --bs-toast-header-bg: #0e0f0f;
    --bs-secondary-color: #B6B0B0;
    --bs-btn-color: #B6B0B0;
    --bs-btn-active-color: #B6B0B0;
    --bs-nav-tabs-link-hover-border-color: #e9ecef;
}
$body-font: 'Nunito Sans', sans-serif;
$heading_1: 30px;
$heading_2: 24px;
$heading_3: 20px;
$heading_4: 18px;
$heading_5: 16px;
$heading_6: 14px;
$common_letter_spacing: 0.5px;
$border_radius: 24px;
$spacing: 40px;

// header settings
$header-spacing: 15px 30px;

// sidebar settings
$logo-shadow: 0px 4px 15px rgba($black, 0.05);
$sidebar-width: 253px;
$mobile-sidebar-width: 240px;
$sidebar-shadow: 0px 4px 40px rgba($black, 0.1);

// input variables
$input-radius: 30px;
$input-font-size: 14px;
$input-padding: 14px 20px;

// button variables
$btn-hover-shadow: 3px 3px 3px #e5e5e5;

// card settings
$card-box-shadow: 0 0 50px rgba($black, 0.03);
$card-header-bg-color : #ffffff;
$card-padding :20px;
$card-border: 1px solid #e5e5e5;
// page title
$title-spacing: 30px 0;
$breadcrumb-icon-shadow: 1px 1px 0px #e5e5e5;
$breadcrumb-icon-radius: 5px;
$page-body-padding : 0 15px 0 15px;
$header-size: 136px;


// Tables settings
$table-b-margin: 0px;
$table-heading-color: #2f2f3b;
$table-row-color: #2f2f3b;
$table-padding: 0.75rem;

//According setting
$according-card-top-margin : 10px;
$according-btn-weight : 500;
$according-btn-color : #292929;
$according-card-header-padding : 0.75rem 1.25rem;
$according-open-icon : "\eb73";
$according-close-icon : "\eb7a";

// tabs
$nav-tabs-border-color:             var(--table-double-color) !default;
$nav-tabs-border-width:             $border-width !default;
$nav-tabs-border-radius:            $border-radius !default;
$nav-tabs-link-hover-border-color:  var(--gray-200) var(--gray-200) $nav-tabs-border-color !default;
$nav-tabs-link-active-color:        var(--gray-700) !default;
$nav-tabs-link-active-bg:           var(--white) !default;
$nav-tabs-link-active-border-color: var(--table-double-color) var(--table-double-color) $nav-tabs-link-active-bg !default;