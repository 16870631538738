/**=====================
     form css start
==========================**/
.form-control {
    &:focus {
        box-shadow: none;
    }
    @include media-breakpoint-down(sm) {
        padding: 12px;
    }
    &.form-control-sm {
        min-height: calc(1.5em + 0.5rem + 2px) !important;
        padding: 0.25rem 0.5rem !important;
        font-size: 0.875rem !important;
        border-radius: 0.25rem !important;
    }
    &.form-control-lg {
        min-height: calc(1.5em + 1rem + 2px) !important;
        padding: 0.5rem 1rem !important;
        font-size: 1.25rem !important;
        border-radius: 0.5rem !important;
    }
    &::placeholder {
        color: rgba($body-font-color, 0.4);
    }
}
.input-group {
    border-radius: $input-radius;
    .form-control {
        border-radius: 8px;
    }
    &:focus {
        border: 1px solid var(--theme-default);
    }
}
.basic-form {
    .dz-message {
        i {
            position: unset;
            top: unset;
            right: unset;
            transform: unset;
        }
    }
}
.search-form {
    .input-group {
        .form-control {
            &::placeholder {
                color: var(--light-text);
            }
        }
    }
}
@mixin form-validation-state-selector($state) {
    @if ($state=="valid"or $state=="invalid") {
        .was-validated #{if(&, "&", "")}:#{$state},
        #{if(&, "&", "")}.is-#{$state} {
            @content;
        }
    }
    @else {
        #{if(&, "&", "")}.is-#{$state} {
            @content;
        }
    }
}
.form-select {
    &:focus {
        border-color: var(--form-select);
        outline: 0;
        box-shadow: unset;
    }
}
.has-validation,
.input-florting {
    .form-floating {
        flex: none;
        width: unset;
        min-width: unset;
    }
}
.basic-form {
    position: relative;
    i {
        position: absolute;
        top: 50%;
        right: 12px;
        transform: translateY(-50%);
    }
    hr {
        color: var(--gray-60);
    }
}
.default-layout {
    .checkbox {
        input[type=checkbox] {
            &:checked {
                + {
                    label {
                        &::before {
                            font-family: "Font Awesome 5 Free";
                            font-weight: 900;
                            content: "\f00c";
                            text-align: center;
                            line-height: 1.2;
                            display: inline-block;
                            position: absolute;
                            width: 19px;
                            height: 19px;
                            left: 18px;
                            margin-left: -16px;
                            border: 1px solid #efefef;
                            border-radius: 3px;
                            background-color: var(--white);
                        }
                    }
                }
            }
        }
        input {
            display: none;
        }
        label {
            display: inline-block;
            position: relative;
            cursor: pointer;
            margin-top: 10px;
            padding-left: 30px;
            &::before {
                content: "";
                display: inline-block;
                position: absolute;
                width: 19px;
                height: 19px;
                left: 18px;
                margin-left: -16px;
                border: 1px solid #efefef;
                border-radius: 3px;
                background-color: var(--white);
            }
        }
    }
}
.Horizontal-layout {
    .form-control {
        padding: 10px 12px;
    }
}
.edges-form * {
    border-radius: 20px !important;
    .form-select {
        overflow: hidden;
    }
}
.flat-form * {
    border-radius: 0;
}
/**=====================
     form css end
==========================**/