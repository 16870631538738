/**=====================
    img-crop css start
==========================**/
.docs-toggles {
    .dropdown {
        .dropdown-toggle {
            width: auto;
        }
    }
}
.docs-buttons,
.docs-toggles {
    @media screen and (max-width: 1580px) and (min-width: 1200px) {
        flex: 0 0 100%;
        max-width: 100%;
    }
}
.img-cropper {
    #putData {
        @include media-breakpoint-down(xl) {
            margin-bottom: 10px;
        }
    }
    .docs-toggles {
        >.dropdown {
            @include media-breakpoint-down(xl) {
                margin-bottom: 0;
            }
        }
    }
    .docs-toggles {
        @media screen and (max-width: 1366px) {
            margin-top: 8px;
        }
    }
    .dropup {
        .dropdown-toggle {
            &::after {
                content: '';
                display: none;
            }
        }
    }
    .img-container {
        min-height: auto;
        margin-bottom: 0;
    }
    .docs-data {
        >.input-group {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .docs-preview {
        margin-top: 20px;
        margin-bottom: 10px;
    }
    .docs-tooltip {
        @media screen and (max-width: 360px) {
            font-size: 11px;
        }
    }
    .docs-toggles {
        .btn-group {
            .btn {
                @media screen and (max-width: 360px) {
                    padding: 0.375rem 0.2rem;
                }
            }
        }
    }
}
/**=====================
    img-crop css End
==========================**/