/**=====================
    2.12 Datatable CSS Start
==========================**/
div {
  &.dt-button-background {
    display: none !important;
  }
  &.DTS {
    div {
      &.dataTables_scrollBody {
        table {
          z-index: 1;
        }
      }
    }
  }
  .table-avtar {
    height: 32px;
    margin-right: 10px;
  }
  .action {
    display: flex;
    i {
      font-size: 16px;
    }
    .pdf {
      i {
        font-size: 20px;
        color: var(--danger-color);
      }
    }
    .edit {
      margin-right: 5px;
      i {
        color: var(--success-color);
      }
    }
    .delete {
      i {
        color: var(--danger-color);
      }
    }
  }
}
#auto-generate-content_wrapper {
  &.dataTables_wrapper {
    button {
      background-color: var(--white);
      border: 1px solid var(--theme-default);
      color: var(--theme-default);
    }
  }
}
.dataTables_wrapper {
  width: calc(100% - 1px);
  &.no-footer {
    .dataTables_scrollBody {
      border-bottom: 1px solid var(--light-gray);
    }
  }
  .dt-buttons {
    .btn {
      border-radius: 0.25rem !important;
    }
  }
  padding: 0;
  .btn-group {
    button {
      margin-right: 5px;
    }
  }
  button {
    font-weight: 400;
    padding: 0.375rem 0.75rem;
    font-size: 14px;
    border-radius: 0.25rem;
    color: var(--white);
  }
  &+.dataTables_wrapper {
    margin-top: 20px;
  }
  .dataTables_paginate {
    margin-left: 15px !important;
    border: 1px solid var(--light-color);
    border-radius: 0.25rem;
    padding-top: 0;
    .paginate_button {
      margin: 0;
      &.current,
      &:active {
        background: var(--theme-default);
        color: $white !important;
        box-shadow: none;
      }
      &:hover {
        border: 1px solid var(--theme-default);
        color: var(--dark-color) !important;
        background: transparent !important;
      }
    }
  }
  .dataTables_length {
    margin-bottom: 30px;
    select {
      border-color: var(--light-color);
      color: var(--dark-color);
      padding: 0 10px;
      margin: 0 10px;
      height: 2.7142em;
      background-color: var(--white);
    }
  }
  table.dataTable {
    border: 1px solid var(--gray-200);
    tbody {
      td,
      th {
        &.select-checkbox {
          padding-right: 40px !important;
          &:before {
            right: 20px;
            top: 22px;
            left: unset;
          }
          &:after {
            margin-top: -5px;
            margin-left: 21px;
          }
        }
      }
    }
    thead {
      th,
      td {
        border-bottom: 1px solid var(--border-color);
      }
    }
    th,
    td {
      padding: 0.75rem;
      padding-bottom: 0.75rem !important;
      @include media-breakpoint-down(sm) {
        padding: 0.5rem;
      }
    }
  }
  .dataTables_filter {
    margin-bottom: 25px;
    margin-left: 15px;
    input[type="search"] {
      border: 1px solid var(--gray-200);
      background-color: var(--white);
      padding: 0 10px;
      margin-left: 10px;
      height: 37px;
      border-radius: 4px;
      color: var(--font-color);
      &:focus-visible {
        outline: none;
      }
    }
    &:focus-visible {
      outline: none;
    }
  }
}
.dataTables_scrollHeadInner {
  width: 100% !important;
}
table {
  .fixedHeader-floating {
    position: fixed !important;
    background-color: var(--white);
  }
  .box {
    >div {
      padding: 5px 15px;
      background-color: var(--theme-default);
      display: inline-block;
      color: var(--white);
      border-radius: 5px;
      margin-bottom: 15px;
      margin-right: 15px;
    }
  }
  &.dataTable {
    &.fixedHeader-locked,
    &.fixedHeader-floating {
      width: calc(100vw - 250px) !important;
      max-width: calc(100vw - 250px) !important;
      overflow: hidden !important;
      right: 0 !important;
      z-index: 99;
      left: 260px !important;
    }
    &:not(.fixedHeader-locked) {
      &:not(.fixedHeader-floating) {
        width: 100% !important;
      }
    }
  }
  &.dataTable {
    border-collapse: collapse !important;
    margin-top: 0 !important;
    thead {
      .sorting,
      .sorting_asc,
      .sorting_desc,
      .sorting_asc_disabled,
      .sorting_desc_disabled {
        &:before,
        &:after {
          bottom: 12px !important;
        }
      }
    }
    input,
    select {
      border: 1px solid var(--gray-700);
      height: 37px;
    }
  }
}
.user-datatable {
  tr {
    td,
    th {
      &:first-child {
        min-width: 150px;
      }
    }
  }
}
.jsgrid {
  .jsgrid-button {
    width: 20px;
    height: 20px;
  }
  .jsgrid-delete-button {
    background-position: 0 -78px;
    width: 22px;
    height: 22px;
  }
  .jsgrid-edit-button {
    background-position: -2px -122px;
    width: 22px;
    height: 22px;
  }
  .jsgrid-insert-mode-button {
    background-position: -2px -160px;
    width: 25px;
    height: 25px;
  }
  .jsgrid-insert-button {
    background-position: -3px -202px;
    width: 16px;
    height: 16px;
  }
}
table.dataTable.display tbody tr.even>.sorting_1 {
  background-color: rgba($light-color, 0.5);
}
.toolbar {
  padding: 5px 15px;
  background-color: var(--theme-default);
  display: inline-block;
  color: var(--white);
  border-radius: 5px;
  margin-bottom: 15px;
  margin-right: 15px;
}
code {
  &.option {
    color: var(--danger-color) !important;
  }
  &.api {
    color: var(--success-color) !important;
  }
}
.dt-ext {
  .dataTables_wrapper {
    .page-item {
      &.active {
        .page-link {
          background-color: var(--theme-default) !important;
          border-color: var(--theme-default) !important;
          color: var(--white);
        }
      }
    }
    .dataTables_paginate {
      border: none;
      @include media-breakpoint-down(md) {
        float: none;
        margin-top: 20px !important;
        margin-left: 0 !important;
      }
      @include media-breakpoint-down(sm) {
        margin-top: 15px !important;
      }
      .paginate_button {
        padding: 0;
        border: none;
        &.next {
          &:before {
            @include media-breakpoint-down(sm) {
              content: "\e649";
              font-family: themify;
              font-size: 11px;
              padding: 0 5px;
            }
          }
          @include media-breakpoint-down(sm) {
            font-size: 0;
            padding: 0;
          }
        }
        &.previous {
          &:before {
            @include media-breakpoint-down(sm) {
              content: "\e64a";
              font-family: themify;
              font-size: 11px;
              padding: 0 5px;
            }
          }
          @include media-breakpoint-down(sm) {
            font-size: 0;
            padding: 0;
          }
        }
        &.disabled,
        &.hover,
        &.active {
          border: none !important;
        }
      }
      .page-link {
        margin-left: 0px;
        color: var(--theme-default);
      }
    }
    button.dt-button,
    div.dt-button,
    a.dt-button,
    button.dt-button:focus:not(.disabled),
    div.dt-button:focus:not(.disabled),
    a.dt-button:focus:not(.disabled),
    button.dt-button:active:not(.disabled),
    button.dt-button.active:not(.disabled),
    div.dt-button:active:not(.disabled),
    div.dt-button.active:not(.disabled),
    a.dt-button:active:not(.disabled),
    a.dt-button.active:not(.disabled) {
      background-color: var(--theme-default);
      border-color: var(--theme-default);
      border-radius: 2px;
      color: var(--white);
      background-image: none;
      font-size: 14px;
    }
    button.dt-button.btn-success,
    div.dt-button.btn-success,
    a.dt-button.btn-success {
      background-color: var(--success-color);
      border-color: var(--success-color);
    }
    button.dt-button.btn-primary,
    div.dt-button.btn-primary,
    a.dt-button.btn-primary {
      background-color: var(--theme-default);
      border-color: var(--theme-default);
    }
    button.dt-button.btn-danger,
    div.dt-button.btn-danger,
    a.dt-button.btn-danger {
      background-color: var(--danger-color);
      border-color: var(--danger-color);
    }
    table.dataTable th.focus,
    table.dataTable td.focus {
      outline: 3px solid var(--theme-default);
    }
    table.dataTable.display tbody>tr.odd.selected>.sorting_1,
    table.dataTable.order-column.stripe tbody>tr.odd.selected>.sorting_1 {
      background-color: rgba($primary-color, 0.38);
    }
    table.dataTable.stripe tbody>tr.odd.selected,
    table.dataTable.stripe tbody>tr.odd>.selected,
    table.dataTable.display tbody>tr.odd.selected,
    table.dataTable.display tbody>tr.odd>.selected {
      background-color: rgba($primary-color, 0.38);
    }
    table.dataTable.display tbody>tr.even.selected>.sorting_1,
    table.dataTable.order-column.stripe tbody>tr.even.selected>.sorting_1 {
      background-color: rgba($primary-color, 0.38);
    }
    table.dataTable tbody>tr.selected,
    table.dataTable tbody>tr>.selected {
      background-color: rgba($primary-color, 0.38);
    }
  }
}
td.highlight {
  background-color: var(--light-color) !important;
}
td.details-control {
  background: url('../../images/details_open.png') no-repeat center center;
  cursor: pointer;
}
tr.shown td.details-control {
  background: url('../../images/details_close.png') no-repeat center center;
}
.dataTables_scroll {
  &~.dataTables_paginate {
    margin-top: 20px !important;
  }
}
.product-table {
  th {
    &:last-child {
      min-width: 120px;
    }
  }
  h6 {
    font-weight: 600;
    color: var(--dark-color);
  }
}
table {
  &.dataTable {
    &.display {
      tbody {
        tr {
          &.odd {
            >.sorting_1 {
              background-color: var(--light-color);
            }
            .badge {
              line-height: 15px;
            }
          }
          &.even {
            .badge {
              line-height: 15px;
            }
          }
        }
      }
    }
  }
}
.jsgrid-row,
.jsgrid-alt-row {
  .jsgrid-cell {
    .badge {
      line-height: 15px !important;
    }
  }
}
.table-responsive {
  &.custom-scroll {
    overflow: auto;
    .datatable-wrapper {
      .datatable-top {
        position: absolute;
        z-index: 1;
        top: 63px;
        right: 4px;
      }
      .datatable-container {
        .datatable-table {
          margin-top: 70px;
        }
        button {
          &.bg-light-primary {
            border: 1px solid var(--light-color);
            width: 120px;
          }
        }
      }
    }
  }
}
#simple {
  &.datatable-table {
    thead {
      tr {
        th {
          &:nth-child(3) {
            min-width: 130px;
          }
          &:nth-child(5) {
            min-width: 130px;
          }
          &:nth-child(2) {
            min-width: 110px;
          }
          &:nth-child(6) {
            min-width: 110px;
          }
          &:first-child {
            min-width: 50px;
          }
        }
      }
    }
  }
}
.paginate_button {
  .page-link {
    @include media-breakpoint-down(sm) {
      font-size: 12px;
    }
    @media (max-width:360px) {
      font-size: 11px;
    }
  }
}
.autofill-datatable {
  div.dataTables_wrapper {
    div.dataTables_paginate {
      .paginate_button.previous {
        &:before {
          @include media-breakpoint-down(sm) {
            content: "\e64a";
            font-family: themify;
            font-size: 11px;
            padding: 0 5px;
            line-height: 3.2;
          }
        }
      }
      .paginate_button.next {
        &:before {
          @include media-breakpoint-down(sm) {
            content: "\e649";
            font-family: themify;
            font-size: 11px;
            padding: 0 5px;
            line-height: 3.2;
          }
        }
      }
    }
  }
}
.autofill-datatable {
  table {
    &.table {
      thead {
        th {
          &::after {
            @media (max-width: 1500px) {
              top: 33px !important;
            }
          }
          &:nth-child(2) {
            @media (max-width: 1500px) {
              min-width: 100px;
            }
          }
          &:nth-child(5) {
            @media (max-width: 1500px) {
              min-width: 100px;
            }
          }
          &:nth-child(7) {
            @media (max-width: 1500px) {
              min-width: 100px;
            }
          }
        }
      }
    }
  }
  .table-light {
    [data-theme="dark"] & {
      --bs-table-bg: var(--card-color);
      --bs-table-striped-bg: var(--body-color);
      --bs-table-hover-bg: var(--body-color);
    }
  }
}
.complex-table {
  table {
    thead {
      tr {
        th {
          &:first-child {
            @media (max-width: 1630px) {
              min-width: 115px;
            }
          }
        }
        +tr {
          th {
            &:first-child {
              @media (max-width: 1630px) {
                min-width: 190px;
              }
            }
            &:nth-child(3) {
              @media (max-width: 1630px) {
                min-width: 100px;
              }
            }
          }
        }
      }
    }
  }
}
div.dataTables_wrapper {
  div.dataTables_paginate {
    ul.pagination {
      align-items: center;
    }
  }
}
/**=====================
     2.12 Datatable CSS Ends
==========================**/