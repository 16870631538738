/**=====================
    09. Error-page CSS Start
==========================**/
.error-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  text-align: center;
  background-color: var(--white);
  &.maintenance-bg {
    background-image: url(../../images/other-images/maintenance-bg.jpg);
    background-color: rgba($white, 0.6);
    background-blend-mode: overlay;
    .btn {
      padding: 12px 20px;
    }
  }
  svg {
    width: 100%;
    height: 380px;
  }
  .maintenance-heading {
    margin-top: 20px;
    .headline {
      font-size: 99px;
      font-weight: 900;
      letter-spacing: 10px;
      color: var(--theme-default);
      z-index: 2;
      position: relative;
    }
  }
  .error-heading {
    margin-top: 115px;
    .headline {
      font-size: 285px;
      font-weight: 700;
      letter-spacing: 1px;
      margin-top: -130px;
    }
  }
  .error {
    margin: 70px 0 100px;
    color: var(--black);
    .error-title {
      font-size: 9.5rem;
      color: var(--white);
      text-shadow: 6px 6px 7px rgba($black, 0.32);
    }
    .line-bottom {
      width: 10%;
      height: 5px;
      background-color: var(--warning-color);
      border: none;
    }
    .c-white {
      color: var(--white);
    }
    .error-btn {
      text-align: center;
    }
  }
  .svg-wrraper {
    margin-bottom: 35px;
    svg {
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      height: 440px;
    }
  }
  h3 {
    font-weight: 700;
    margin-bottom: 20px;
    font-size: 31px;
  }
  .sub-content {
    font-size: 18px;
    color: var(--body-font-color);
    letter-spacing: 1px;
    line-height: 35px;
    font-weight: normal;
    z-index: 3;
    margin-bottom: 35px;
  }
}
@media (max-width: 1199px) {
  .error-wrapper {
    .maintenance-heading {
      .headline {
        font-size: 60px;
        margin-bottom: 15px;
      }
    }
    svg {
      height: 320px;
    }
  }
}
@media (max-width: 991px) {
  .error-wrapper {
    svg {
      height: 280px;
    }
    .svg-wrraper {
      svg {
        height: 450px;
      }
    }
    .sub-content {
      line-height: 25px;
    }
  }
}
@media (max-width: 767px) {
  .error-wrapper {
    .maintenance-heading {
      .headline {
        font-size: 35px;
        margin-bottom: 10px;
      }
    }
    h3 {
      margin-bottom: 18px;
    }
    .sub-content {
      margin-bottom: 30px;
      font-size: 16px;
    }
  }
}
@media (max-width: 575px) {
  .error-wrapper {
    .maintenance-heading {
      .headline {
        font-size: 25px;
        letter-spacing: 2px;
      }
    }
    .svg-wrraper {
      margin-bottom: 25px;
      svg {
        height: 380px;
      }
    }
    .sub-content {
      font-size: 14px;
    }
  }
}
@media (max-width: 480px) {
  .error-wrapper {
    .svg-wrraper {
      svg {
        height: auto;
      }
    }
  }
}

// Style for Error1 Pages
.error1shp0 {
  fill: #308e87;
}
.error1shp1 {
  fill: #ebebeb;
}
.error1shp2 {
  fill: #263238;
}
.error1shp3 {
  fill: #e6e6e6;
}
.error1shp4 {
  fill: #ffffff;
}
.error1shp5 {
  fill: #ffffff;
  opacity: 0.6;
}
.error1shp6 {
  fill: #ffffff;
  opacity: 0.2;
}
.error1shp7 {
  fill: #f5f5f5;
}
.error1shp8 {
  opacity: 0.1;
}

// Style for Error2 Pages
.error2shp0 {
  fill: #308e87;
}
.error2shp1 {
  fill: #ffffff;
  opacity: 0.7;
}
.error2shp2 {
  fill: #263238;
}
.error2shp3 {
  opacity: 0.2;
}
.error2shp4 {
  fill: #ffffff;
}
.error2shp5 {
  opacity: 0.3;
  fill: #308e87;
}
.error2shp6 {
  opacity: 0.2;
  fill: #308e87;
}
.error2shp7 {
  fill: #ffc3bd;
}
.error2shp8 {
  opacity: 0.4;
}
.error2shp9 {
  fill: #ed847e;
}
.error2shp10 {
  fill: #e0e0e0;
}
.error2shp11 {
  fill: #ff8b7b;
}
.error2shp12 {
  opacity: 0.3;
}
.error2shp13 {
  fill: #f0f0f0;
}
.error2shp14 {
  fill: #e6e6e6;
}
.error2shp15 {
  fill: #f5f5f5;
}
.error2shp16 {
  fill: #ff5652;
}

// Style for Error3 Pages
.error3shp0 {
  fill: #307ef3;
}
.error3shp1 {
  fill: #ebebeb;
}
.error3shp2 {
  fill: #263238;
}
.error3shp3 {
  fill: #ffffff;
}
.error3shp4 {
  opacity: 0.3;
}
.error3shp5 {
  fill: #ffffff;
  opacity: 0.3;
}
.error3shp6 {
  fill: #f0ad4e;
}
.error3shp7 {
  opacity: 0.1;
}
.error3shp8 {
  fill: #c7c7c7;
}
.error3shp9 {
  opacity: 0.2;
}
.error3shp10 {
  fill: #a6a6a6;
}
.error3shp11 {
  fill: #ffffff;
  opacity: 0.5;
}
// Style for Error4 Pages
.error4shp0 {
  fill: #e0e0e0;
}
.error4shp1 {
  fill: #46476c;
}
.error4shp2 {
  fill: #308e87;
}
.error4shp3 {
  opacity: 0.4;
  mix-blend-mode: soft-light;
  fill: #ffffff;
}
.error4shp4 {
  opacity: 0.051;
  mix-blend-mode: multiply;
  fill: #000000;
}
.error4shp5 {
  opacity: 0.4;
  fill: #ffffff;
}
.error4shp6 {
  opacity: 0.2;
  fill: #000000;
}
.error4shp7 {
  opacity: 0.102;
  fill: #000000;
}
.error4shp8 {
  fill: #ffffff;
}
.error4shp9 {
  opacity: 0.2;
  fill: #ffffff;
}
.error4shp10 {
  fill: #000000;
}
.error4shp11 {
  opacity: 0.149;
  fill: #000000;
}
.error4shp12 {
  fill: #37474f;
}
.error4shp13 {
  fill: #263238;
}
.error4shp14 {
  opacity: 0.302;
  fill: #000000;
}
.error4shp15 {
  opacity: 0.502;
  fill: #000000;
}
.error4shp16 {
  opacity: 0.702;
  fill: #000000;
}
.error4shp17 {
  fill: #fafafa;
}
.error4shp18 {
  fill: #ffa8a7;
}
.error4shp19 {
  fill: #f28f8f;
}


// Style for Error5 Pages
.error5shp0 {
  fill: #f5f5f5;
}
.error5shp1 {
  fill: #ffffff;
}
.error5shp2 {
  fill: #263238;
}
.error5shp3 {
  fill: #308e87;
}
.error5shp4 {
  fill: #308e87;
  opacity: 0.5;
}
.error5shp5 {
  fill: #ffffff;
  opacity: 0.2;
}
.error5shp6 {
  fill: #ffffff;
  opacity: 0.4;
}
.error5shp7 {
  opacity: 0.1;
}
.error5shp8 {
  opacity: 0.3;
}
.error5shp9 {
  fill: #f0f0f0;
}


// Style for Error6 Pages
.error6shp0 {
  fill: #f5f5f5;
}
.error6shp1 {
  fill: #e0e0e0;
}
.error6shp2 {
  fill: #ebebeb;
}
.error6shp3 {
  fill: #307ef3;
}
.error6shp4 {
  fill: #000000;
}
.error6shp5 {
  fill: #ffffff;
}
.error6shp6 {
  fill: #455a64;
}
.error6shp7 {
  opacity: 0.349;
  fill: #000000;
}
.error6shp8 {
  opacity: 0.2;
  fill: #000000;
}
.error6shp9 {
  opacity: 0.251;
  fill: #000000;
}
.error6shp10 {
  opacity: 0.502;
  fill: #000000;
}
.error6shp11 {
  fill: #fafafa;
}
.error6shp12 {
  opacity: 0.749;
  fill: #ffffff;
}
.error6shp13 {
  opacity: 0.102;
  fill: #000000;
}
.error6shp14 {
  fill: #f28f8f;
}

/**=====================
      09. Error-page CSS Ends
  ==========================**/
