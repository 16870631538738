.dashboard-2 {
    .analytics-card {
        .header-top {
            .nav {
                @media (max-width: 575px) {
                    display: none;
                }
            }
        }
        .sale-chart-box {
            .d-flex {
                li {
                    .statistic-content {
                        color: var(--font-color);
                        font-size: 14px;
                        font-weight: 600;
                        span {
                            width: 8px;
                            height: 8px;
                            border-radius: 50%;
                            margin-right: 5px;
                            &.primary-dot {
                                background-color: var(--theme-default);
                            }
                            &.secondary-dot {
                                background-color: var(--theme-secondary);
                            }
                        }
                    }
                    h5 {
                        color: var(--body-font-color);
                        font-size: calc(16px + (26 - 18) * ((100vw - 300px) / (1920 - 300)));
                        font-weight: 800;
                    }
                    .statistic-num {
                        span {
                            font-size: 12px;
                            font-weight: 600;
                            margin-left: 15px;
                        }
                    }
                    + {
                        li {
                            padding-left: 25px;
                            margin-left: 25px;
                            @media (max-width: 360px) {
                                padding-left: 20px;
                                margin-left: 0;
                            }
                        }
                    }
                }
            }
        }
    }
    .user-status {
        table {
            thead {
                tr {
                    th {
                        color: var(--font-color);
                        font-size: 12px;
                        font-weight: 700;
                        letter-spacing: 0.5px;
                        text-transform: uppercase;
                        border-bottom: 1px solid var(--light-gray);
                        &:first-child {
                            padding-left: 0;
                        }
                        &:nth-child(2) {
                            @media (max-width: 370px) {
                                display: none;
                            }
                        }
                    }
                }
            }
            tbody {
                tr {
                    vertical-align: middle;
                    td {
                        padding: 9px 0;
                        border: 0;
                        .gap-3 {
                            @media (max-width: 1430px) and (min-width: 1400px) {
                                gap: 6px !important;
                            }
                        }
                        &:first-child {
                            padding-left: 0;
                        }
                        &:nth-child(2) {
                            @media (max-width: 370px) {
                                display: none;
                            }
                        }
                        &:last-child {
                            min-width: 70px;
                        }
                        .d-flex {
                            .flex-grow-1 {
                                h6 {
                                    color: var(--body-font-color);
                                    font-size: 14px;
                                    font-style: normal;
                                    font-weight: 600;
                                    span {
                                        color: var(--font-color);
                                        font-size: 14px;
                                        font-style: normal;
                                        font-weight: 600;
                                    }
                                }
                            }
                        }
                        h3 {
                            color: var(--body-font-color);
                            font-size: 14px;
                            font-weight: 600;
                        }
                        .progress {
                            height: 6px;
                        }
                    }
                }
            }
        }
    }
    .sale-card {
        .card-header {
            .header-top {
                align-items: flex-start;
                h3 {
                    color: var(--body-font-color);
                    font-size: calc(16px + (26 - 18) * ((100vw - 300px) / (1920 - 300)));
                    font-weight: 800;
                }
                span {
                    color: var(--font-color);
                    font-size: 16px;
                    font-weight: 600;
                }
            }
        }
        .card-body {
            .current-sale-container {
                .overview-wrapper {
                    position: relative;
                    z-index: 1;
                }
                .back-bar-container {
                    position: absolute;
                    width: calc(100% - -24px);
                    bottom: -30px;
                    margin: 0 auto !important;
                    left: -19px;
                    @media (max-width: 1640px) and (min-width: 1400px) {
                        bottom: -55px;
                    }
                    @media (max-width: 1300px) and (min-width: 1200px) {
                        bottom: -55px;
                    }
                    @media (max-width: 1090px) and (min-width: 992px) {
                        bottom: -55px;
                    }
                    @media (max-width: 820px) {
                        bottom: -55px;
                    }
                }
            }
        }
    }
    .selling-product {
        .card-header {
            .dropdown {
                @media (max-width: 1530px) {
                    display: none;
                }
            }
        }
        .card-body {
            ul {
                li {
                    padding: 8px 0;
                    &:last-child {
                        padding-bottom: 0px;
                    }
                    .flex-shrink-0 {
                        height: 35px;
                        width: 35px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 50%;
                        border-width: 1px !important;
                        border-style: solid;
                        svg {
                            width: 21px;
                            height: 21px;
                        }
                    }
                    .flex-grow-1 {
                        h6 {
                            color: var(--body-font-color);
                            font-weight: 600;
                            @media (max-width: 1760px) {
                                text-overflow: ellipsis;
                                overflow: hidden;
                                display: -webkit-box !important;
                                -webkit-line-clamp: 1;
                                -webkit-box-orient: vertical;
                                white-space: normal;
                            }
                        }
                        p {
                            color: var(--font-color);
                            font-weight: 600;
                        }
                    }
                    .recent-text {
                        h5 {
                            color: var(--body-font-color);
                            font-size: 14px;
                            font-weight: 600;
                        }
                        p {
                            color: var(--font-color);
                            font-size: 12px;
                            font-weight: 400;
                            @media (max-width: 1740px) {
                                text-overflow: ellipsis;
                                overflow: hidden;
                                display: -webkit-box !important;
                                -webkit-line-clamp: 1;
                                -webkit-box-orient: vertical;
                                white-space: normal;
                            }
                        }
                    }
                }
            }
        }
    }
    .best-sell {
        .card-body {
            thead {
                tr {
                    th {
                        text-transform: capitalize;
                        font-weight: 700;
                        color: var(--font-color);
                        padding: 10px;
                        @media (max-width: 1870px) and (min-width: 767px) {
                            padding: 10px 0;
                        }
                        &:first-child {
                            padding-left: 0;
                        }
                        &:nth-child(5) {
                            @media (max-width: 1600px) and (min-width: 1400px) {
                                display: none;
                            }
                            @media (max-width: 1300px) and (min-width: 1200px) {
                                display: none;
                            }
                        }
                        &:nth-child(3) {
                            @media (max-width: 1500px) and (min-width: 1200px) {
                                display: none;
                            }
                        }
                    }
                }
            }
            tbody {
                tr {
                    vertical-align: middle;
                    td {
                        color: var(--body-font-color);
                        font-weight: 600;
                        padding: 10px;
                        @media (max-width: 730px) {
                            min-width: 100px;
                        }
                        @media (max-width: 1870px) and (min-width: 767px) {
                            padding: 10px 0;
                        }
                        &:first-child {
                            padding-left: 0;
                            @media (max-width: 730px) {
                                min-width: 170px;
                            }
                        }
                        &:last-child {
                            padding-right: 0;
                        }
                        &:nth-child(3) {
                            @media (max-width: 730px) {
                                min-width: 170px;
                            }
                        }
                        &:nth-child(5) {
                            @media (max-width: 1600px) and (min-width: 1400px) {
                                display: none;
                            }
                            @media (max-width: 1300px) and (min-width: 1200px) {
                                display: none;
                            }
                        }
                        &:nth-child(3) {
                            @media (max-width: 1500px) and (min-width: 1200px) {
                                display: none;
                            }
                        }
                        border: 0;
                        .d-flex {
                            .flex-grow-1 {
                                h6 {
                                    font-weight: 600;
                                    color: var(--body-font-color);
                                }
                                p {
                                    color: var(--font-color);
                                    font-weight: 600;
                                }
                            }
                        }
                        .btn {
                            padding: 7px 18px;
                            min-width: 115px;
                        }
                    }
                }
            }
        }
    }
    .value-chart {
        .card-body {
            padding: 30px;
            @media (max-width: 767px) {
                padding: 10px 30px;
            }
        }
        .sales-chart,
        .visitor-chart {
            min-height: 150px !important;
        }
        .valuechart-detail {
            display: flex;
            align-items: center;
            height: 100%;
            justify-content: center;
            .badge {
                font-weight: 600;
                padding: 4px 9px;
                &.badge-primary {
                    box-shadow: 0px 0px 15px 0px rgba($primary-color, 0.40);
                }
                &.badge-secondary {
                    box-shadow: 0px 0px 15px 0px rgba($secondary-color, 0.40);
                }
            }
            h2 {
                color: var(--body-font-color);
                font-size: calc(14px + (25 - 19) * ((100vw - 300px) / (1920 - 300)));
                font-weight: 700;
                margin-top: 18px;
            }
            p {
                color: var(--font-color);
                font-size: 12px;
                font-weight: 600;
            }
        }
        &:hover {
            transform: translateY(-5px);
            transition: 0.5s;
        }
    }
    .recent-order {
        table {
            thead {
                tr {
                    th {
                        text-transform: capitalize;
                        font-weight: 700;
                        color: var(--font-color);
                        padding: 10px;
                        &:first-child {
                            padding-left: unset;
                        }
                        &:nth-child(2) {
                            @media (max-width: 1500px) and (min-width: 1200px) {
                                display: none;
                            }
                        }
                        &:nth-child(4) {
                            @media (max-width: 1730px) and (min-width: 1400px) {
                                display: none;
                            }
                            @media (max-width: 1300px) and (min-width: 1200px) {
                                display: none;
                            }
                        }
                    }
                }
            }
            tbody {
                tr {
                    vertical-align: middle;
                    td {
                        vertical-align: middle;
                        min-width: 100px;
                        padding: 10px;
                        color: var(--body-font-color);
                        @media (max-width: 730px) {
                            min-width: 120px;
                        }
                        @media (max-width: 1770px) {
                            padding: 10px 5px;
                            min-width: 82px;
                        }
                        &:first-child {
                            padding-left: unset;
                            @media (max-width: 730px) {
                                min-width: 170px;
                            }
                        }
                        &:nth-child(2) {
                            @media (max-width: 1500px) and (min-width: 1200px) {
                                display: none;
                            }
                            @media (max-width: 730px) {
                                min-width: 140px;
                            }
                        }
                        &:nth-child(4) {
                            @media (max-width: 1730px) and (min-width: 1400px) {
                                display: none;
                            }
                            @media (max-width: 1300px) and (min-width: 1200px) {
                                display: none;
                            }
                        }
                        &:last-child {
                            padding-right: 0;
                            h6 {
                                font-weight: 600;
                                span {
                                    color: var(--font-color);
                                    font-weight: 600;
                                }
                            }
                        }
                        .d-flex {
                            .flex-grow-1 {
                                h6 {
                                    font-weight: 600;
                                    color: var(--body-font-color);
                                }
                                p {
                                    color: var(--font-color);
                                    font-weight: 600;
                                }
                            }
                        }
                        .status-showcase {
                            text-align: right;
                            p {
                                margin-bottom: 0;
                                line-height: unset;
                                color: var(--font-color);
                                font-size: 12px;
                                font-weight: 600;
                            }
                            .progress {
                                height: 6px;
                            }
                        }
                    }
                    &:last-child{
                        td{
                            padding-bottom: 0 !important;
                        }
                    }
                }
            }
        }
    }
    .target-card {
        .card-header {
            ul {
                display: flex;
                align-items: center;
                justify-content: space-between;
                @media (max-width: 1600px) and (min-width: 1400px) {
                    flex-direction: column;
                }
                @media (max-width: 680px) and (min-width: 576px) {
                    flex-direction: column;
                }
                @media (max-width: 575px) {
                    justify-content: space-evenly;
                }
                li {
                    min-width: 155px;
                    padding: 22px 12px;
                    border-radius: 20px;
                    @media (max-width: 1800px) and (min-width: 1600px) {
                        min-width: 145px;
                    }
                    @media (max-width: 1740px) and (min-width: 1600px) {
                        min-width: 115px;
                    }
                    @media (max-width: 1600px) and (min-width: 1400px) {
                        min-width: 155px;
                        padding: 12px;
                    }
                    @media (max-width: 1040px) and (min-width: 576px) {
                        min-width: 140px;
                    }
                    @media (max-width: 690px) and (min-width: 576px) {
                        min-width: 120px;
                    }
                    @media (max-width: 680px) and (min-width: 576px) {
                        padding: 12px;
                        min-width: 140px;
                    }
                    @media (max-width: 360px) {
                        min-width: 110px;
                    }
                }
                .bg-light-primary {
                    box-shadow: 0px 0px 50px 0px rgba($black, 0.03);
                    h3 {
                        color: var(--body-font-color);
                        font-weight: 800;
                        font-size: calc(14px + (23 - 19) * ((100vw - 300px) / (1920 - 300)));
                    }
                    p {
                        color: var(--font-color);
                        font-size: 12px;
                        font-weight: 400;
                        margin-top: 3px;
                    }
                    h6 {
                        font-size: 16px;
                        font-weight: 800;
                        margin-top: 6px;
                    }
                }
                .bg-primary {
                    filter: drop-shadow(0px 0px 50px rgba($black, 0.03));
                    @media (max-width: 1600px) and (min-width: 1400px) {
                        margin-top: 10px;
                    }
                    @media (max-width: 1530px) and (min-width: 1400px) {
                        margin-top: 22px;
                    }
                    @media (max-width: 680px) and (min-width: 576px) {
                        margin-top: 10px;
                    }
                    h3 {
                        color: var(--white);
                        font-size: calc(14px + (23 - 19) * ((100vw - 300px) / (1920 - 300)));
                        font-weight: 800;
                    }
                    p {
                        color: var(--white);
                        font-size: 12px;
                        font-weight: 400;
                        margin-top: 3px;
                    }
                    h6 {
                        color: var(--white);
                        font-size: 16px;
                        font-weight: 700;
                        margin-top: 6px;
                    }
                }
            }
        }
        .card-body {
            margin-top: 30px;
            @media (max-width: 1870px) and (min-width: 1200px) {
                margin-top: 38px;
            }
            @media (max-width: 1830px) and (min-width: 1200px) {
                margin-top: 48px;
            }
            @media (max-width: 1780px) and (min-width: 1200px) {
                margin-top: 54px;
            }
            @media (max-width: 1730px) and (min-width: 1200px) {
                margin-top: 60px;
            }
            @media (max-width: 1700px) and (min-width: 1200px) {
                margin-top: 68px;
            }
            @media (max-width: 1630px) and (min-width: 1200px) {
                margin-top: 81px;
            }
            @media (max-width: 1600px) and (min-width: 1200px) {
                margin-top: 8px;
            }
            @media (max-width: 1350px) and (min-width: 1200px) {
                margin-top: 20px;
            }
            @media (max-width: 1270px) and (min-width: 1200px) {
                margin-top: 42px;
            }
            @media (max-width: 1230px) and (min-width: 1200px) {
                margin-top: 60px;
            }
            @include media-breakpoint-up(lg) {
                @include media-breakpoint-down(xl) {
                    margin-top: 8px;
                }
            }
            @media (max-width: 1120px) and (min-width: 992px) {
                margin-top: 28px;
            }
            @media (max-width: 1060px) and (min-width: 992px) {
                margin-top: 45px;
            }
            @media (max-width: 1020px) and (min-width: 992px) {
                margin-top: 63px;
            }
            @media (max-width: 991px) and (min-width: 768px) {
                margin-top: 9px;
            }
            @media (max-width: 820px) and (min-width: 768px) {
                margin-top: 40px;
            }
            @media (max-width: 767px) and (min-width: 576px) {
                margin-top: 45px;
            }
            @media (max-width: 750px) and (min-width: 576px) {
                margin-top: 55px;
            }
            @media (max-width: 720px) and (min-width: 576px) {
                margin-top: 66px;
            }
            @media (max-width: 690px) and (min-width: 576px) {
                margin-top: 66px;
            }
            @media (max-width: 680px) and (min-width: 576px) {
                margin-top: 0;
            }
            @media (max-width: 630px) and (min-width: 576px) {
                margin-top: 20px;
            }
            @media (max-width: 575px) {
                margin-top: 0;
            }
            .main-img {
                position: relative;
                z-index: 1;
            }
            .animate-img {
                .right-1 {
                    left: 7%;
                    top: 26%;
                    position: absolute;
                    z-index: -1;
                    animation: move1 8s linear infinite;
                }
                .right-2 {
                    left: 26%;
                    top: 12%;
                    position: absolute;
                    z-index: -1;
                    animation: displayTransition 1.2s infinite;
                }
                .right-3 {
                    right: 17%;
                    top: 1%;
                    position: absolute;
                    z-index: -1;
                    animation: move1 8s linear infinite;
                }
                .right-4 {
                    right: 1%;
                    top: 16%;
                    position: absolute;
                    z-index: -1;
                    animation: displayTransition 1.2s infinite;
                }
                .right-5 {
                    position: absolute;
                    z-index: -1;
                    top: 68px;
                    right: 48px;
                    animation: displayTransition 1.5s infinite;
                    @media (max-width: 1870px) and (min-width: 1200px) {
                        right: 46px;
                        top: 66px;
                    }
                    @media (max-width: 1820px) and (min-width: 1200px) {
                        right: 42px;
                        top: 63px;
                    }
                    @media (max-width: 1770px) and (min-width: 1200px) {
                        top: 60px;
                    }
                    @media (max-width: 1720px) and (min-width: 1200px) {
                        right: 38px;
                        top: 56px;
                    }
                    @media (max-width: 1630px) and (min-width: 1200px) {
                        top: 53px;
                    }
                    @media (max-width: 1590px) and (min-width: 1200px) {
                        right: 33px;
                        top: 52px;
                    }
                    @media (max-width: 1550px) and (min-width: 1200px) {
                        top: 48px;
                    }
                    @media (max-width: 1470px) and (min-width: 1200px) {
                        top: 44px;
                        right: 29px;
                    }
                    @media (max-width: 1430px) and (min-width: 1200px) {
                        top: 41px;
                    }
                    @include media-breakpoint-up(xl) {
                        @include media-breakpoint-down(xxl) {
                            top: 75px;
                            right: 73px;
                        }
                    }
                    @media (max-width: 1380px) and (min-width: 1200px) {
                        right: 60px;
                    }
                    @media (max-width: 1360px) and (min-width: 1200px) {
                        right: 57px;
                    }
                    @media (max-width: 1340px) and (min-width: 1200px) {
                        right: 48px;
                    }
                    @media (max-width: 1320px) and (min-width: 1200px) {
                        top: 71px;
                    }
                    @media (max-width: 1260px) and (min-width: 1200px) {
                        top: 68px;
                    }
                    @media (max-width: 1230px) and (min-width: 1200px) {
                        top: 66px;
                        right: 43px;
                    }
                    @media (max-width: 1210px) and (min-width: 1200px) {
                        top: 63px;
                    }
                    @media (max-width: 1199px) {
                        top: 74px;
                        right: 61px;
                    }
                    @media (max-width: 1180px) {
                        top: 75px;
                        right: 56px;
                    }
                    @media (max-width: 1160px) {
                        right: 48px;
                    }
                    @media (max-width: 1130px) {
                        right: 47px;
                        top: 71px;
                    }
                    @media (max-width: 1100px) {
                        top: 69px;
                    }
                    @media (max-width: 1080px) {
                        top: 65px;
                        right: 43px;
                    }
                    @media (max-width: 1030px) {
                        top: 61px;
                        right: 38px;
                    }
                    @media (max-width: 1000px) {
                        top: 56px;
                    }
                    @media (max-width: 991px) {
                        top: 75px;
                        right: 102px;
                    }
                    @media (max-width: 980px) {
                        right: 93px;
                    }
                    @media (max-width: 960px) {
                        right: 86px;
                    }
                    @media (max-width: 940px) {
                        right: 73px;
                    }
                    @media (max-width: 915px) {
                        right: 62px;
                    }
                    @media (max-width: 890px) {
                        right: 49px;
                    }
                    @media (max-width: 820px) {
                        right: 44px;
                        top: 68px;
                    }
                    @media (max-width: 790px) {
                        right: 44px;
                        top: 65px;
                    }
                    @media (max-width: 750px) {
                        right: 40px;
                        top: 62px;
                    }
                    @media (max-width: 720px) {
                        right: 37px;
                        top: 57px;
                    }
                    @media (max-width: 660px) {
                        right: 33px;
                        top: 52px;
                    }
                    @media (max-width: 600px) {
                        right: 29px;
                        top: 45px;
                    }
                    @media (max-width: 575px) {
                        right: 177px;
                        top: 76px;
                    }
                    @media (max-width: 560px) {
                        right: 155px;
                    }
                    @media (max-width: 530px) {
                        right: 137px;
                    }
                    @media (max-width: 510px) {
                        right: 108px;
                    }
                    @media (max-width: 490px) {
                        right: 87px;
                    }
                    @media (max-width: 360px) {
                        right: 34px;
                        top: 50px;
                    }
                }
            }
        }
    }
    .timeline-card {
        .activity-timeline {
            .d-flex {
                .activity-line {
                    position: absolute;
                    top: 74px;
                    height: 66%;
                    width: 4px;
                    border-left: 1px dashed var(--font-color);
                    margin: 0 auto;
                    left: 30px;
                    z-index: 1;
                    @media (max-width: 1600px) and (min-width: 1200px) {
                        left: 27px;
                    }
                    @media (max-width: 575px) {
                        left: 22px;
                    }
                }
                .activity-dot-secondary {
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    min-height: 20px;
                    min-width: 20px;
                    background-color: var(--white);
                    border: 5px solid var(--theme-secondary);
                    position: relative;
                    z-index: 2;
                    outline: unset;
                    box-shadow: 0px 0px 50px 0px rgba($black, 0.03);
                    @media (max-width: 1600px) and (min-width: 1200px) {
                        width: 15px;
                        height: 15px;
                        min-height: 15px;
                        min-width: 15px;
                    }
                }
                .activity-dot-primary {
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    min-height: 20px;
                    min-width: 20px;
                    background-color: var(--white);
                    border: 5px solid var(--theme-default);
                    position: relative;
                    z-index: 2;
                    outline: unset;
                    box-shadow: 0px 0px 50px 0px rgba($black, 0.03);
                    @media (max-width: 1600px) and (min-width: 1200px) {
                        width: 15px;
                        height: 15px;
                        min-height: 15px;
                        min-width: 15px;
                    }
                }
                .flex-grow-1 {
                    display: block;
                    margin-left: 8px;
                    p {
                        color: var(--body-font-color);
                        font-size: 14px;
                        font-weight: 600;
                        margin-bottom: 0;
                    }
                    span {
                        color: var(--body-font-color);
                        font-size: 14px;
                        font-weight: 600;
                        &.f-w-700 {
                            @media (max-width: 1590px) {
                                text-overflow: ellipsis;
                                overflow: hidden;
                                display: -webkit-box !important;
                                -webkit-line-clamp: 1;
                                -webkit-box-orient: vertical;
                                white-space: normal;
                            }
                        }
                    }
                    .activity-text {
                        color: var(--font-color);
                        font-size: 12px;
                        font-weight: 600;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        display: -webkit-box !important;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        white-space: normal;
                    }
                    .img-wrapper {
                        display: flex;
                        align-items: center;
                        margin-top: 10px;
                        li {
                            width: 100px;
                            height: 65px;
                            border-radius: 5px;
                            background-color: rgba($primary-color, 0.1);
                            display: flex;
                            @media (max-width: 1450px) and (min-width: 1200px) {
                                width: 80px;
                                height: 55px;
                            }
                            @media (max-width: 600px) {
                                width: 85px;
                                height: 50px;
                            }
                            img {
                                margin: auto;
                            }
                            + {
                                li {
                                    margin-left: 20px;
                                }
                            }
                        }
                    }
                    .badge-primary {
                        color: var(--white) !important;
                        font-size: 10px;
                    }
                }
                .circle-dot-secondary {
                    background-color: rgba($secondary-color, 0.25);
                    border-radius: 100%;
                    padding: 3px;
                    font-size: 10px;
                    color: var(--theme-secondary);
                    animation: round 1.3s ease-in-out infinite;
                    @media (max-width: 1580px) and (min-width: 1200px) {
                        font-size: 7px;
                    }
                    @media (max-width: 1550px) and (min-width: 1400px) {
                        display: none;
                    }
                    @media (max-width: 1020px) {
                        font-size: 7px;
                    }
                    @media (max-width: 630px) and (min-width: 576px) {
                        display: none;
                    }
                }
                .circle-dot-primary {
                    background-color: rgba($primary-color, 0.25);
                    border-radius: 100%;
                    padding: 3px;
                    font-size: 10px;
                    color: var(--theme-default);
                    animation: round 1.3s ease-in-out infinite;
                    @media (max-width: 1580px) and (min-width: 1200px) {
                        font-size: 7px;
                    }
                    @media (max-width: 1550px) and (min-width: 1400px) {
                        display: none;
                    }
                    @media (max-width: 1020px) {
                        font-size: 7px;
                    }
                    @media (max-width: 630px) and (min-width: 576px) {
                        display: none;
                    }
                }
                + {
                    .d-flex {
                        margin-top: 12px;
                    }
                }
            }
        }
    }
    .radial-card {
        @media (max-width: 1320px) and (min-width: 1200px) {
            height: 350px;
        }
        .radial-chart {
            position: relative;
            @media (min-width: 1200px) and (max-width: 1500px) {
                width: 100%;
            }
        }
        .radial-text {
            @media (min-width: 1200px) and (max-width: 1500px) {
                display: none;
            }
        }
        .text-center {
            min-width: 358px;
            @media (max-width: 1820px) and (min-width: 1200px) {
                min-width: 290px;
            }
            @media (max-width: 1580px) and (min-width: 1200px) {
                min-width: 100px;
            }
            @media (max-width: 1020px) and (min-width: 992px) {
                min-width: 290px;
            }
            @media (max-width: 767px) and (min-width: 576px) {
                min-width: 270px;
            }
            @media (max-width: 420px) {
                min-width: 280px;
            }
        }
        .content-label {
            text-align: center;
            position: absolute;
            left: 50%;
            transform: translate(-50%, -50%);
            bottom: -20px;
            @media (max-width: 575px) {
                display: none;
            }
            p {
                font-size: 14px;
                font-weight: 600;
            }
            h4 {
                color: var(--body-font-color);
                font-weight: 700;
                font-size: calc(14px + (25 - 19) * ((100vw - 300px) / (1920 - 300)));
            }
        }
        .radial-content {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            h4 {
                color: var(--body-font-color);
                font-size: 16px;
                font-weight: 600;
            }
            h3 {
                color: var(--body-font-color);
                font-weight: 700;
                font-size: calc(14px + (25 - 19) * ((100vw - 300px) / (1920 - 300)));
            }
            hr {
                border-color: #888;
                margin-top: 7px;
                margin-bottom: 0;
            }
            .progress-widget {
                color: var(--theme-default);
                font-size: calc(14px + (25 - 19) * ((100vw - 300px) / (1920 - 300)));
                font-weight: 600;
                margin-top: 30px;
                span {
                    color: var(--body-font-color);
                    font-size: calc(14px + (25 - 19) * ((100vw - 300px) / (1920 - 300)));
                    font-weight: 700;
                }
            }
            .progress-animate {
                overflow: inherit;
                margin: 0;
                border-radius: 0px;
                height: 7px;
                background-color: rgba($primary-color, 0.1);
                .progress-gradient-primary {
                    background-image: linear-gradient(to right, transparent, var(--theme-default));
                    position: relative;
                }
            }
            .btn-primary {
                margin-top: 40px;
                font-weight: 700 !important;
                @media (max-width: 575px) {
                    margin-top: 25px;
                }
            }
        }
    }
    .items-slider {
        .swiper {
            .swiper-wrapper {
                .swiper-slide {
                    .slider-box {
                        padding: 20px;
                        border-radius: 24px;
                        box-shadow: 0px 0px 50px 0px rgba($black, 0.03);
                        .header-top {
                            display: flex;
                            align-items: flex-start;
                            justify-content: space-between;
                            @media (min-width: 1400px) and (max-width: 1470px) {
                                justify-content: center;
                            }
                            @media (max-width: 600px) and (min-width: 576px) {
                                justify-content: center;
                            }
                            .i {
                                font-size: 22px;
                                @media (min-width: 1200px) and (max-width: 1600px) {
                                    font-size: 16px;
                                }
                                @media (min-width: 1400px) and (max-width: 1470px) {
                                    display: none;
                                }
                                @media (max-width: 740px) {
                                    font-size: 16px;
                                }
                                @media (max-width: 600px) and (min-width: 576px) {
                                    display: none;
                                }
                            }
                            .badge {
                                font-weight: 600;
                                font-size: 10px;
                                border-radius: 200px;
                                padding: 6px 15px;
                                @media (min-width: 1200px) and (max-width: 1600px) {
                                    padding: 5px;
                                }
                                @media (min-width: 1400px) and (max-width: 1470px) {
                                    display: none;
                                }
                                @media (max-width: 740px) {
                                    padding: 5px;
                                }
                                @media (max-width: 600px) and (min-width: 576px) {
                                    display: none;
                                }
                                &.badge-primary {
                                    box-shadow: 0px 0px 15px 0px rgba($primary-color, 0.40);
                                }
                                &.badge-secondary {
                                    box-shadow: 0px 0px 15px 0px rgba($secondary-color, 0.40);
                                }
                            }
                        }
                    }
                    .slider-content {
                        h4 {
                            font-size: 16px;
                            font-weight: 600;
                            margin-top: 16px;
                        }
                        p {
                            color: var(--black);
                            font-size: 16px;
                            font-weight: 500;
                            @media (max-width: 1702px) {
                                text-overflow: ellipsis;
                                overflow: hidden;
                                display: -webkit-box !important;
                                -webkit-line-clamp: 1;
                                -webkit-box-orient: vertical;
                                white-space: normal;
                            }
                        }
                        h6 {
                            color: var(--black);
                            font-size: calc(14px + (23 - 19) * ((100vw - 300px) / (1920 - 300)));
                            font-weight: 600;
                            margin: 10px 0;
                            span {
                                color: #888;
                                font-size: calc(14px + (23 - 19) * ((100vw - 300px) / (1920 - 300)));
                                font-weight: 600;
                                text-decoration: line-through;
                            }
                        }
                        .input-group {
                            border: 1px dashed var(--black);
                            border-radius: 3px;
                            width: 80px;
                            margin: 0 auto;
                            .input-group-text {
                                border: 0;
                                padding: 7px;
                                background-color: var(--white);
                                cursor: pointer;
                                .svg-color {
                                    width: 10px;
                                    height: 10px;
                                }
                            }
                            input {
                                padding: 0;
                                text-align: center;
                                border-radius: 0;
                                color: var(--black) !important;
                                border-right: 1px dashed var(--black);
                                border-left: 1px dashed var(--black);
                            }
                        }
                    }
                }
            }
            .swiper-button-next {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 20px;
                height: 20px;
                opacity: 0;
                transition: all 0.5s ease;
                background-color: var(--theme-default);
                border-radius: 50%;
                margin-top: -10px;
                z-index: 2;
                &::after {
                    font-size: 9px;
                    color: var(--white);
                }
            }
            .swiper-button-prev {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 20px;
                height: 20px;
                opacity: 0;
                transition: all 0.5s ease;
                background-color: var(--theme-default);
                border-radius: 50%;
                margin-top: -10px;
                z-index: 2;
                &::after {
                    font-size: 9px;
                    color: var(--white);
                }
            }
            &:hover {
                .swiper-button-next {
                    position: absolute;
                    right: 20px;
                    opacity: 1;
                }
                .swiper-button-prev {
                    position: absolute;
                    left: 20px;
                    opacity: 1;
                }
            }
        }
    }
    @keyframes round {
        0% {
            transform: scale(1);
        }
        50% {
            transform: scale(1.2);
        }
        100% {
            transform: scale(1);
        }
    }
    @keyframes displayTransition {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
    @keyframes move1 {
        0% {
            transform: rotate(0deg) translateX(15px) rotate(0deg);
        }
        100% {
            transform: rotate(360deg) translateX(15px) rotate(-360deg);
        }
    }
}