.react-datepicker-wrapper {
  width: 100% !important;
}

.ql-container.ql-snow {
  height: 200px;
}

.css-t3ipsp-control:hover {
  border-color: var(--theme-default) !important;
}

div .action .edit {
  margin-right: 20px;
}

.blGEez {
  display: flex !important;
  flex-direction: row-reverse !important;
}

// .dropdown-form,
// .status-form,
// .plan-form {
//   width: 250px;
// }

.custom-card {
  border-radius: 8px;
  color: #000 !important;
}

.calendar-container {
  max-width: 600px;
  margin: auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.custom-calendar {
  border: none;
}

.react-calendar {
  width: 100%;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
}

.react-calendar__tile {
  transition: background 0.3s;
}

.react-calendar__tile:hover {
  background: #e0f7fa; /* Light blue on hover */
}

.react-calendar__tile--active {
  background: #00796b; /* Dark teal for active date */
  color: white;
}

.event-marker {
  display: inline-block;
  margin-top: 5px;
  font-size: 1.5em;
  color: #ff5722; /* Marker color */
}

.react-calendar__month-view__weekdays {
  border: 2px solid #eee;
}

.react-calendar__month-view__weekdays__weekday {
  border-right: 2px solid #eee;
  background-color: #fafafa;
  padding: 1.8rem;
  color: #687588;
  font-size: 16px;
  font-weight: 600;
}

.react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--neighboringMonth,
.react-calendar__tile.react-calendar__month-view__days__day {
  border: 2px solid #eee;
  padding: 2rem;
  font-size: 14px;
  font-weight: 600;
}

/* Media Queries for responsiveness */
@media (max-width: 600px) {
  .calendar-container {
    padding: 10px;
  }

  .event-marker {
    font-size: 1.2em;
  }
}

.filepond--panel-center {
  height: 60px !important;
}

.filepond--panel-bottom.filepond--panel-root,
.filepond--panel-top.filepond--panel-root,
.filepond--credits {
  display: none;
}

.filepond--drop-label {
  display: inline-block;
  min-height: 40px !important;
}
.filepond--panel .filepond--panel-root {
  height: 40px !important;
}
.filepond--drop-label.filepond--drop-label label {
  background: #fff !important;
  border: 1px solid var(--light-gray) !important;
  text-align: start;
}

.filepond--root {
  margin-bottom: 0px !important;
}

.filepond--wrapper {
  width: 100%;
}

.custom-opacity {
  opacity: 0.2;
}

.show-nursery-icon-close {
  display: none;
}

.show-nursery-icon-open {
  display: inline;
}
.hide-nursery-section {
  display: none;
}

.custom-radio {
  .checked-radio {
    + {
      label {
        &::before {
          border-color: var(--info-color);
        }
        &::after {
          background-color: var(--info-color);
          transform: scale(1, 1);
        }
      }
    }
  }
}

/* Tooltip styling */
.error-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  display: inline-block;
  width: 18px;
  height: 16px;
}

.error-icon[data-tooltip]::before {
  content: attr(data-tooltip);
  position: absolute;
  bottom: 185%; /* Positions the tooltip above the icon */
  right: 50%;
  transform: translateX(50%);
  background-color: #dc3545; /* Red background for error */
  color: #fff;
  padding: 5px 8px;
  border-radius: 4px;
  font-size: 0.875em;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease, visibility 0.2s ease;
  z-index: 1;
}

.error-icon[data-tooltip]::after {
  content: "";
  position: absolute;
  bottom: 115%; /* Position the arrow slightly above the tooltip */
  right: 50%;
  transform: translateX(50%);
  border-width: 5px;
  border-style: solid;
  border-color: #dc3545 transparent transparent transparent; /* Arrow pointing down */
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease, visibility 0.2s ease;
  z-index: 1;
}

/* Show tooltip on hover */
.error-icon:hover::before,
.error-icon:hover::after {
  opacity: 1;
  visibility: visible;
}

.event-marker {
  margin-left: 30px;
  width: 40px;
  height: 40px;
  background-color: #3498db;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
}

.event-marker:hover {
  transform: scale(1.1);
}

.event-icon {
  font-size: 1.2rem;
  color: white;
}

.booking-modal h2 {
  margin-top: 0;
  font-size: 1.3em;
  color: #333;
}

.booking-modal p {
  font-size: 0.95em;
  color: #666;
  margin: 10px 0;
}

.booking-details {
  margin: 15px 0;
  text-align: left;
  font-size: 0.95em;
}

.booking-details p {
  margin: 5px 0;
}

.booking-details span {
  font-weight: bold;
  color: #333;
}

.booking-modal .message {
  background-color: #f0f4ff;
  padding: 10px;
  border-radius: 5px;
  margin-top: 15px;
  font-size: 0.9em;
  color: #3a3a3a;
  text-align: left;
}

.css-1u9des2-indicatorSeparator {
  display: none !important;
}

.nursery-dropdown-form {
  .css-13cymwt-control {
    border: none;
    background-color: #f9f7f6;
  }
}
/* Custom styles for the header */
.custom-card-header {
  background-color: #292929; /* Vibrant blue background */
  color: #fff; /* White text color */
  padding: 20px;
  // border-radius: 10px 10px 0 0; /* Rounded corners at the top */
}

/* Custom styles for the Card Body */
.custom-card-body {
  // background-color: #f1f1f1; /* Light background for the body */
  background-color: #fff; /* Light background for the body */
  padding: 20px;
  // border-radius: 0 0 10px 10px; /* Rounded corners at the bottom */
  box-shadow: inset 0 0 15px rgba(0, 0, 0, 0.1); /* Light shadow effect inside the body */
}

.general {
  display: list-item; /* Makes it behave like an li element */
  list-style-type: disc; /* Adds the bullet point */
  margin-left: 35px; /* Optional: adjust for indentation */
}
.like-button i {
  cursor: pointer;
  transition: color 0.3s ease, transform 0.2s ease; /* Smooth transition for color and scaling */
}

.like-button i.liked {
  color: red; /* Liked state color */
  animation: heart-pulse 0.6s ease-in-out; /* Trigger the pulse animation */
}

.like-button i:hover {
  transform: scale(1.2); /* Slight scaling on hover */
}

/* Keyframes for the heart pulse animation */
@keyframes heart-pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 rgba(255, 0, 0, 0); /* No shadow at the start */
  }
  50% {
    transform: scale(1.4);
    box-shadow: 0 0 15px rgba(255, 0, 0, 0.6); /* Bright shadow at the peak */
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 rgba(255, 0, 0, 0); /* Reset shadow */
  }
}
.news-feed-image {
  width: 100%;
  height: auto;
  max-width: 600px;
}
.custom-header-card {
  border-bottom: 1px solid #eee !important;
  padding: 0;
  padding-bottom: 1rem;
}
.table th.action-column {
  display: flex;
  justify-content: flex-end;
}

.cygFkb {
  padding-left: 6px !important;
}

.dKzMcW {
  padding-left: 6px !important;
}

.dropdown-container {
  position: relative;
  width: 150px;

  .dropdown-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 5px 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: white;
  }

  .dropdown-header span {
    font-size: 12px;
  }

  .dropdown-menues {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    margin-top: 5px;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }

  .dropdown-item {
    padding: 10px;
    text-align: center;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    transition: background-color 0.2s ease;
  }

  .dropdown-item:hover {
    opacity: 0.9;
  }

  .action-button {
    margin: 0 5px;
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: #eee;
  }

  .action-button:hover {
    background-color: #ddd;
  }

  .card {
    border-radius: 0.5rem !important;
  }

  .email-wrap .email-app-sidebar .main-menu > li a:hover {
    background-color: #ffffff !important;
  }
}

.react-calendar__tile {
  position: relative;
}

.tile-badges {
  position: absolute;
  bottom: 5px;
  left: 5px;
  right: 5px;
  font-size: 0.75rem;
  z-index: 1;
}

.badge {
  margin-bottom: 5px;  /* Spacing between badges */
  display: block;
  word-wrap: break-word;  /* Ensure long text doesn't overflow */
  max-width: 100%;
}

.icon-size-small {
  width: 20px;
  height: 20px;
}

input[type=checkbox] {
  background-color: var(--theme-default);
  border-color: var(--theme-default);
}

.rdt_TableCol:checked {
  background-color: var(--theme-default);
  border-color: var(--theme-default);
}


.badge svg {
  width: 12px !important;
  height: 12px !important;
  padding-top: 0px !important;
  margin-top: -1px;
  margin-right: 5px;
}

.badge + .badge {
  margin-left:  0px !important;
}

.sidebar-menu .sidebar-list .sidebar-link .iconly-Arrow-Down-2 {
  margin-left: auto;
}
.dropzone {
  border: 2px dashed var(--theme-default);
  border-radius: 8px;
  background-color: #308e871a;
  margin: 20px;
  cursor: pointer;
  padding-left: 20px;
  padding-right: 20px;
}

.dropzone-icon {
  font-size: 50px;
  font-weight: 900;
  color: var(--theme-default);
}

.general-widget .widget-1 {
  background-image: none !important;
}

.general-widget .widget-1 .card-body .widget-content .widget-round .bg-round .half-circle {
  background: #37242400 !important;
}

.setting-menu {
  li {
    button {
      &:hover {
        background-color: rgba(73, 80, 87, 0.07) !important;
      }
    }
  }
}

.custom-calender-icon {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  position: absolute;
  right: 1px;
  padding: 12px 12px;
}

.tags-input {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 5px;
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 5px;
}
.tag {
  display: inline-flex;
  align-items: center;
  color: #fff;
  padding: 5px 10px;
  border-radius: 15px;
  margin: 2px;
}
.remove-tag {
  margin-left: 5px;
  cursor: pointer;
  font-weight: bold;
}
.tags-input input {
  flex: 1;
  border: none;
  outline: none;
}

.custom-dropdown-menu {
  padding: 10px;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 200px;
  transform: translate3d(-19.8px, 34.4px, 0px);
}

.custom-dropdown-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 15px;
  margin-bottom: 8px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.custom-dropdown-item:hover {
  transform: scale(1.02);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
}

.custom-dropdown-item span {
  flex-grow: 1;
  text-align: left;
}

.custom-dropdown-item i {
  font-size: 18px;
}

/* Target checkboxes within the data table */
.data-table-wrapper .rdt_Table .rdt_TableHead input[type="checkbox"],
.data-table-wrapper .rdt_Table .rdt_TableBody input[type="checkbox"] {

  --bs-form-check-bg: var(--bs-body-bg);
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  appearance: none;
  background-color: var(--bs-form-check-bg);
  background-image: var(--bs-form-check-bg-image);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: var(--bs-border-width) solid var(--bs-border-color);
  -webkit-print-color-adjust: exact;

  background-color: var(--theme-default);
  border-color: var(--theme-default);
  border-radius: 4px;

}

.data-table-wrapper .rdt_Table input[type="checkbox"]:checked {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 20 20%27%3e%3cpath fill=%27none%27 stroke=%27%23fff%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%273%27 d=%27m6 10 3 3 6-6%27/%3e%3c/svg%3e");
}

.dot-mark::before {
  content: '';
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 50%;
  display: block;
}

.range-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 250px; /* Adjust for container size */
  width: 50px;
  margin: 0 auto;
}

.range-track {
  display: flex;
  flex-direction: column-reverse; /* Ensures upward orientation */
  justify-content: center;
  align-items: center;
}

.range-track-bar {
  width: 10px;
  border-radius: 5px;
}

.range-thumb {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: grab;
}

.range-thumb-bar {
  height: 10px;
  width: 10px;
}

.range-thumb-bar-dragged {
  background-color: #ff758c; /* Color when dragging */
}

.range-output-container {
  margin-top: 10px;
  font-size: 14px;
}

.range-output {
  font-weight: bold;
}


.table-btn {
  width: 90px !important;
  border-radius: 8px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 10px !important;
  border: 0 !important;

  &:hover {
    cursor: pointer; // Add a pointer cursor for better UX
  }
}

.table-dropdown-menu {
  padding: 12px 30px;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 124px !important;
  transform: translate3d(-19.8px, 34.4px, 0px);
  margin-top: 10px !important;
}
.table-dropdown-item {
  text-align: center;
  padding: 5px 14px !important;
  margin-bottom: 8px;
  font-size: 8px;
  font-weight: 700;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.statusBox {
  &.inactive {
    background-color: rgb(248, 248, 248) !important;
    color: rgb(137, 157, 183) !important;
  }
  &.active {
    background-color: rgba(52, 166, 177, 0.20) !important;
    color: rgb(12, 175, 96) !important;
  }
  &.published {
    background-color: rgba(52, 166, 177, 0.20) !important;
    color: rgb(12, 175, 96) !important;
  }
  &.onboarding {
    background-color: rgb(170, 133, 0) !important;
    color: rgb(255, 222, 101) !important;
  }
  &.probation {
    background-color: rgb(194, 110, 187) !important;
    color: rgb(244, 240, 255) !important;
  }
  &.onleave {
    background-color: rgb(255, 163, 102) !important;
    color:  rgb(112, 48, 0) !important;
  }
  &.suspended {
    background-color:rgb(255, 237, 236) !important;
    color: rgb(224, 49, 55) !important;
  }
  &.draft {
    background-color:rgb(255, 237, 236) !important;
    color: rgb(224, 49, 55) !important;
  }
}

.invalid-border-color {
  border-color: #dc3545 !important;
}

.react-datepicker-popper {
  &.react-datepicker__triangle {
    fill: var(--theme-default) !important;
    color: var(--theme-default) !important;
  }
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
  fill: var(--theme-default) !important;
  color: var(--theme-default) !important;
}

.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle {
  fill: var(--theme-default) !important;
  color: var(--theme-default) !important;
}

.react-datepicker__header {
  background-color: var(--theme-default) !important;
}

.react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker__navigation-icon::before{
  border-color: #fff !important;
}

.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header{
  color: #fff !important;
  font-family: "Nunito Sans", "sans- serif" !important;
}

.react-datepicker__day-name, .react-datepicker__time-name{
  color: #fff !important;
  font-family: "Nunito Sans", "sans- serif" !important;
}

.react-datepicker__day{
  font-family: "Nunito Sans", "sans- serif" !important;
}

.react-datepicker__month{
  background-color: #308e8717 !important;
  margin: 0 !important;
  padding: 0.4rem !important;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
 background-color: #308e8717 !important; 
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item{
  font-family: "Nunito Sans", "sans- serif" !important;
}