.main-inline-calender {
  &.input-group {
    .flatpickr-calendar {
      background-color: var(--white);
      &.inline {
        width: 100%;
      }
    }
    .flatpickr-rContainer {
      display: block;
      .flatpickr-days {
        width: 100%;
        .dayContainer {
          width: 100%;
          max-width: none;
          min-width: unset;
        }
        .dayContainer {
          .flatpickr-day {
            border-radius: 5px;
            width: 100%;
            max-width: unset;
          }
        }
        .flatpickr-day {
          &.selected {
            background-color: var(--theme-default);
            border-color: var(--theme-default);
          }
          &.today {
            border-color: var(--theme-default);
            &:hover {
              border-color: var(--theme-default);
              background: var(--theme-default);
              color: var(--white);
            }
          }
          &:hover {
            background: var(--theme-default);
            border-color: var(--theme-default);
            color: var(--white);
          }
        }
      }
    }
    .flatpickr-weekdays {
      width: 100%;
    }
  }
  .flatpickr-innerContainer {
    display: block;
  }
  .form-control {
    width: 100%;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .form-control[readonly] {
    background-color: var(--bg-light);
    opacity: 1;
  }
}
.flatpicker-calender {
  &.input-group {
    .form-control {
      border: 1px solid var(--light-gray);
      padding: 8px 10px;
    }
  }
}
.timepicker-wrapper {
  .row {
    margin-bottom: 16px;
  }
  .input-group {
    .form-control {
      border: 1px solid var(--light-gray);
      padding: 8px 10px;
    }
  }
  .row {
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.bootstarp-calender {
  .row {
    &:last-child {
      margin-bottom: 0;
    }
  }
}
/**=====================
    2.13 Datapicker CSS Start
==========================**/
.clockpicker-canvas {
  line {
    stroke: var(--theme-default);
  }
}
.clockpicker-canvas-fg,
.clockpicker-canvas-bearing {
  fill: var(--theme-default);
}
.datepicker {
  z-index: 99;
}
.datepicker--day-name {
  color: var(--theme-default);
  font-weight: 700;
}
.datepicker--cell {
  &.-current- {
    color: $black;
    border-radius: 5px;
    font-weight: bold;
    border: 2px solid var(--theme-default);
  }
  &.-focus- {
    background: var(--theme-default);
    color: var(--white);
  }
  &.-selected- {
    background: var(--theme-default) !important;
  }
  &-day {
    width: 15.2857142857%;
  }
}
.datepickers-container,
.bootstrap-datetimepicker-widget {
  z-index: 7;
}
.datetime-picker {
  .bootstrap-datetimepicker-widget.dropdown-menu {
    width: auto;
  }
  .input-group-text i {
    line-height: 1.3;
  }
}
.clockpicker-popover {
  border: none;
  box-shadow: rgba($black, 0.6);
  z-index: 7;
  .popover-content {
    background-color: rgba($primary-color, 0.05);
  }
}
.clockpicker-canvas-bg {
  fill: rgba($primary-color, 0.38);
}
.clockpicker-tick {
  &:hover {
    color: inherit;
    background-color: rgba($primary-color, 0.38);
  }
}
.calendar-basic {
  .md-sidebar-aside {
    background-color: var(--white);
  }
  #external-events {
    padding: 20px;
    border: 1px solid var(--border-color);
    h4 {
      margin-bottom: 14px;
    }
    p {
      margin-top: 12px;
    }
    .fc-h-event {
      padding: 12px;
      background-color: var(--theme-default);
      border: 1px solid var(--theme-default);
      +.fc-h-event {
        margin-top: 10px;
      }
      .fc-event-main {
        font-size: 13px;
      }
    }
  }
  .calendar-default {
    .fc-scrollgrid {
      border: 1px solid var(--border-color) !important;
    }
    .fc-daygrid-dot-event {
      .fc-event-title {
        text-overflow: ellipsis;
      }
    }
    .fc {
      td[role="presentation"] {
        border: 0;
      }
      .fc-daygrid-day-number,
      .fc-col-header-cell-cushion {
        color: var(--body-font-color);
      }
      .fc-button {
        .fc-icon {
          height: 23px;
        }
      }
    }
    .fc-h-event {
      padding: 2px;
      background-color: var(--theme-default);
      border: 1px solid var(--theme-default);
    }
    .fc-toolbar-chunk {
      .fc-button-group {
        ~.fc-today-button {
          margin: 0 8px;
        }
      }
    }
  }
}
.fc-event-dragging {
  padding: 10px;
  background-color: var(--theme-default);
}
.fc {
  .fc-toolbar {
    flex-wrap: wrap;
    gap: 10px;
  }
  .fc-button-primary {
    &:focus {
      box-shadow: 0 0 0 0.2rem rgba($primary-color, 0.5);
    }
  }
}
.main-inline-calender {
  &.input-group {
    flex-direction: column;
    .form-control {
      width: 100%;
    }
    .flatpickr-calendar {
      &.inline {
        width: 100%;
      }
    }
    .flatpickr-innerContainer {
      display: block;
    }
    .flatpickr-rContainer {
      display: block;
      .flatpickr-weekdays {
        width: 100%;
      }
      .flatpickr-days {
        width: 100%;
        .dayContainer {
          width: 100%;
          max-width: none;
          min-width: unset;
          .flatpickr-day {
            border-radius: 5px;
            width: 100%;
            max-width: unset;
            &:hover {
              color: var(--body-font-color);
            }
          }
        }
      }
    }
  }
}
.flatpickr-day {
  &.selected,
  .endRange {
    background-color: var(--theme-default);
    border-color: var(--theme-default);
    &:hover {
      background-color: var(--theme-default);
      border-color: var(--theme-default);
    }
  }
}
.main-flatpickr {
  .form-control {
    &:read-only {
      background-color: unset;
    }
  }
}
.flatpickr-months {
  font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (1920 - 320)));
}
.flatpickr-current-month {
  font-size: calc(14px + (19 - 14) * ((100vw - 320px) / (1920 - 320)));
}
.flatpickr-calendar {
  &.animate {
    @media (max-width:400px) {
      width: 77%;
      left: 50% !important;
      transform: translateX(-50%);
    }
  }
  .flatpickr-days {
    width: unset;
    .dayContainer {
      width: unset;
      min-width: unset;
      max-width: unset;
    }
  }
}
.flatpicker-calender {
  .form-control {
    @media (max-width:768px) {
      font-size: 14px;
    }
  }
}
.input-group> :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.timepicker-wrapper {
  .row {
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
// flatpickr
.flatpickr-day {
  &.selected,
  .endRange {
    background-color: var(--theme-default);
    border-color: var(--theme-default);
    &:hover {
      background-color: var(--theme-default);
      border-color: var(--theme-default);
    }
  }
}
.main-flatpickr {
  .form-control {
    &:read-only {
      background-color: unset;
    }
  }
}
.flatpickr-months {
  font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (1920 - 320)));
}
.flatpickr-current-month {
  font-size: calc(14px + (19 - 14) * ((100vw - 320px) / (1920 - 320)));
}
.flatpickr-calendar {
  &.animate {
    @media (max-width:400px) {
      width: 77%;
      left: 50% !important;
      transform: translateX(-50%);
    }
  }
  .flatpickr-days {
    width: unset;
    .dayContainer {
      width: unset;
      min-width: unset;
      max-width: unset;
    }
  }
}
.flatpicker-calender {
  .form-control {
    @media (max-width:768px) {
      font-size: 14px;
    }
  }
}
.accordion-page {
  .input-group> :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
}
.input-group> :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.timepicker-wrapper {
  .row {
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.col-form-label {
  @media (max-width: 1650px) and (min-width: 1400px) {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}
.flatpickr-day {
  &:hover {
    background-color: rgba($primary-color, 0.1);
    border-color: var(--body-color);
  }
}
.flatpickr-day {
  &.today {
    &:hover {
      background: var(--theme-default);
      border-color: var(--theme-default);
    }
    &:focus {
      background: var(--theme-default);
      border-color: var(--theme-default);
    }
    background-color: rgba($primary-color, 0.1);
    border-color: var(--solid-border);
  }
}
.flatpickr-current-month {
  .flatpickr-monthDropdown-months {
    color: var(--body-font-color) !important;
  }
  input.cur-year {
    color: var(--body-font-color) !important;
  }
}
.flatpickr-months {
  .flatpickr-prev-month {
    color: var(--body-font-color);
    fill: var(--body-font-color);
  }
  .flatpickr-next-month {
    color: var(--body-font-color);
    fill: var(--body-font-color);
  }
}
.react-calendar {
  box-shadow: 1px 0 0 #e6e6e6, -1px 0 0 #e6e6e6, 0 1px 0 #e6e6e6, 0 -1px 0 #e6e6e6, 0 3px 13px rgba(0, 0, 0, 0.08);
  border: none;
  .react-calendar__tile--active {
    background-color: var(--theme-default);
  }
}
.react-calendar__tile--active {
  &:enabled {
    &:hover {
      background: var(--theme-default);
    }
    &:focus {
      background: var(--theme-default);
    }
  }
}
.react-calendar__tile--now{
  background: var(--theme-default);
  color: $white !important;
}
.react-calendar__month-view__days__day--weekend {
  color: var(--body-font-color);
}
.react-calendar__tile:enabled:hover, .react-calendar__tile:enabled:focus {
	background-color: var(--theme-default);
  color: $white;
}
.react-calendar__tile,
.react-calendar__navigation__label__labelText,
.react-calendar__navigation__arrow{
  color: var(--body-font-color);
}
.react-calendar__navigation button:enabled:hover, .react-calendar__navigation button:enabled:focus {
	background-color: var(--body-color);
}
// Date picker card
.react-datepicker{
  background-color: var(--white);
  border-color: var(--border-color);
}
.react-datepicker__header{
  background-color: var(--white);
  border-color: var(--border-color);
}
.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name,
.react-datepicker__current-month {
  color: var(--body-font-color);
}
.react-datepicker__day:hover, .react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover, .react-datepicker__year-text:hover {
	background-color:  var(--theme-default);
}
.react-datepicker__day--selected{
  background-color:  var(--theme-default);
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list{
  background-color: var(--white);
  color: var(--body-font-color);
}
.react-datepicker-time__header{
  color: var(--body-font-color);
}
.rmdp-wrapper{
  background-color: var(--white) !important;
}
.rmdp-week-day{
  color:  var(--theme-default) !important;
}
.rmdp-day, .rmdp-week-day ,.rmdp-header-values{
  color: var(--body-font-color) !important;
}
.rmdp-day.rmdp-selected span:not(.highlight) {
  background-color: var(--theme-default) !important;
  box-shadow: none;
}
.rmdp-range {
  background-color: unset !important;
  box-shadow: none !important;
}
.rmdp-day.rmdp-today span {
  background-color: var(--theme-default) !important;
}
.rmdp-arrow {
  border-color: var(--body-font-color) !important;
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle {
  fill:  var(--white) !important;
  color:  var(--white) !important;
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
  fill:  var(--white) !important;
  color:  var(--white) !important;
}
/**=====================
     2.13 Datapicker CSS End 
  ==========================**/