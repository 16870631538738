/**=====================
     Dropdown css start
==========================**/
.custom {
	&-dropdown {
		position: relative;
	}
	&-menu {
		margin-top: 20px;
		position: absolute;
		border-radius: 20px;
		background: var(--white);
		opacity: 0;
		visibility: hidden;
		transition: all 0.5s;
		right: 0;
		top: 28px;
		[dir="rtl"] & {
			right: unset;
			left: 0;
		}
		&.show {
			opacity: 1;
			visibility: visible;
			transition: all 0.5s;
		}
		&.active {
			opacity: 1;
			visibility: visible;
			transition: all 0.5s;
		}
	}
}
.dropdown,
.custom {
	&-menu {
		box-shadow: $card-box-shadow;
	}
}
.dropdown-divider {
	margin: 0;
}
.dropdown {
	.dropdown-toggle {
		background-color: rgba($primary-color, 0.08);
		color: var(--theme-default);
		border-radius: 10px;
		padding: 5px 10px;
		width: 100px;
		text-align: left;
		&:after {
			position: absolute;
			top: 50%;
			right: 10px;
			-webkit-transform: translateY(-50%);
			transform: translateY(-50%);
			content: "\f107";
			border: none;
			font-family: "FontAwesome";
			font-size: 18px;
			margin: 0;
		}
		&.btn-primary {
			color: var(--white);
		}
	}
}
.dropdown-menu {
	font-family: Rubik, sans-serif;
	border: none;
	box-shadow: 0 0 20px rgba(89, 102, 122, 0.1);
	.dropdown-item {
		color: var(--dropdown-menu);
		opacity: 0.6;
		font-size: 13px;
		padding: 6px 12px;
		border-top: 1px solid var(--border-color);
		background: var(--white);
		&:first-child{
			border-top: 0;
		}
	}
	&.show {
		z-index: 4;
	}
}
.dropdown-basic {
	.separated-btn {
		margin-left: -6px;
		.btn {
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
			padding: 10px;
		}
	}
	.dropdown {
		position: relative;
		display: inline-block;
		.dropdown-content {
			display: none;
			position: absolute;
			right: 0;
			background-color: var(--dropdown-bg-color);
			min-width: 175px;
			box-shadow: 0px 8px 16px 0px rgba($black, 0.2);
			z-index: 1;
			left: 0;
			top: 45px;
			a {
				display: block;
				padding: 6px 16px;
				color: var(--dropdown-menu);
				opacity: 0.6;
				font-size: 13px;
				border-top: 1px solid var(--light-semi-gray);
				background: var(--white);
			}
		}
	}
	.dropdown-content a:hover {
		background-color: var(--dropdown-content-color);
	}
	.dropdown:hover .dropdown-content {
		display: block;
	}
	.dropdown {
		.dropdown-content {
			a {
				&:hover {
					background-color: var(--gray-60) !important;
				}
			}
		}
	}
}
.dropdown-block {
	li {
		.dropdown-item {
			&:hover {
				background-color: var(--gray-60);
			}
		}
		.helper-truncate {
			&:hover {
				background-color: unset;
			}
		}
	}
}
.dropdown-menu {
	padding: 0;
	.input-group {
		background-color: unset;
		padding: 6px 12px;
		border-top: 1px solid var(--light-semi-gray);
		background-color: var(--white);
		opacity: 0.6;
		&:hover,
		&:active {
			background-color: var(--gray-60);
		}
		.input-group-text {
			background-color: unset;
			border: none;
			padding: 0;
			span{
				color: var(--body-font-color);
			}
		}
	}
	li {
		width: 100%;
		a {
			color: var(--dropdown-menu);
			opacity: 0.6;
			font-size: 13px;
			padding: 6px 12px;
			border-top: 1px solid var(--light-semi-gray);
			background: var(--white);
		}
	}
	.form-control {
		padding: 10px 20px;
	}
}
.dropdown-toggle {
	&::after {
		display: inline-block;
		margin-left: 8px;
		vertical-align: 4px;
		content: '';
		border-top: 4px solid;
		border-right: 4px solid transparent;
		border-bottom: 0;
		border-left: 4px solid transparent;
	}
}
.dropstart {
	.dropdown-toggle {
		&::before {
			display: inline-block;
			margin-right: 8px;
			content: '';
			border-top: 4px solid transparent;
			border-right: 4px solid;
			border-bottom: 4px solid transparent;
		}
	}
}
.dropup {
	.dropdown-toggle {
		&::after {
			content: '';
			display: inline-block;
			margin-left: 8px;
			vertical-align: 4px;
			border-top: 0;
			border-right: 4px solid transparent;
			border-bottom: 4px solid;
			border-left: 4px solid transparent;
		}
	}
}
.dropend {
	.dropdown-toggle {
		&::after {
			content: '';
			display: inline-block;
			content: '';
			border-top: 4px solid transparent;
			border-right: 0;
			border-bottom: 4px solid transparent;
			border-left: 4px solid;
			margin-left: 8px;
		}
	}
}
.form-wrapper {
	width: 260px;
}
.dropdown-menu {
	.helper-truncate {
		@media (max-width:400px) {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}
}
.dark-dropdown {
	.dropdown-block {
		li {
			.dropdown-item {
				&:hover,
				&.active {
					background-color: var(--dark);
					color: var(--white);
				}
			}
		}
	}
}
.btn-group {
	.dropdown-wrapper {
		z-index: 2;
	}
}
.common-flex {
	.btn {
		padding: 0.375rem 1.75rem;
		@include media-breakpoint-down(sm) {
			padding: 6px 16px;
		}
	}
	&.tooltip-effect {
		&:hover {
			border: unset;
			color: var(--white);
		}
	}
}
.btn {
	&.btn-outline-primary {
		&:hover {
			background-color: rgba($primary-color, 0.9);
		}
	}
	&.btn-outline-secondary {
		&:hover {
			background-color: rgba($secondary-color, 0.9);
		}
	}
	&.btn-outline-success {
		&:hover {
			background-color: rgba($success-color, 0.9);
		}
	}
	&.btn-outline-info {
		&:hover {
			background-color: rgba($info-color, 0.9);
		}
	}
	&.btn-outline-warning {
		&:hover {
			background-color: rgba($warning-color, 0.9);
		}
	}
	&.btn-outline-danger {
		&:hover {
			background-color: rgba($danger-color, 0.9);
		}
	}
}
.btn-group {
	.btn {
		&:hover {
			color: var(--white);
		}
	}
}
.btn {
	&:focus {
		box-shadow: unset;
	}
	&:active {
		color: var(--white);
		background-color: var(--theme-default);
		border-color: transparent !important;
		&:focus {
			box-shadow: unset;
		}
	}
	&.show {
		&:focus {
			box-shadow: unset;
		}
		&:active {
			color: var(--white);
			background-color: var(--theme-default);
			border-color: var(--theme-default);
		}
	}
}
/**=====================
     Dropdown css end
==========================**/