.general-widget {
    .growth-card {
        .header-top {
            .nav {
                @media screen and (max-width: 1330px) and (min-width: 1200px) {
                    display: none;
                }
                @media (max-width: 590px) {
                    display: none;
                }
            }
        }
    }
    .f-light {
        color: var(--font-color);
        opacity: 0.8;
    }
    .value-chart {
        .card-body {
            padding: 15px 30px;
        }
        .sales-chart,
        .visitor-chart {
            min-height: 130px !important;
            @media screen and (max-width: 1300px) and (min-width: 1200px) {
                min-height: 125px !important;
            }
            @media screen and (max-width: 1240px) and (min-width: 1200px) {
                min-height: 120px !important;
            }
            >div {
                svg {
                    @media screen and (max-width: 1660px) and (min-width: 1200px) {
                        transform: translate(-28px, 0px);
                    }
                    @media screen and (max-width: 1400px) and (min-width: 1200px) {
                        transform: translate(-49px, 0px);
                    }
                }
            }
        }
        .valuechart-detail {
            display: flex;
            align-items: center;
            height: 100%;
            justify-content: center;
            .badge {
                font-weight: 600;
                padding: 4px 9px;
                &.badge-primary {
                    box-shadow: 0px 0px 15px 0px rgba($primary-color, 0.40);
                }
                &.badge-secondary {
                    box-shadow: 0px 0px 15px 0px rgba($secondary-color, 0.40);
                }
            }
            h2 {
                color: var(--body-font-color);
                font-size: 16px;
                font-weight: 700;
                margin-top: 10px;
                @media screen and (max-width: 1365px) and (min-width: 992px) {
                    font-size: 14px;
                }
            }
            p {
                color: var(--font-color);
                font-size: 12px;
                font-weight: 600;
                @media screen and (max-width: 1400px) and (min-width: 1200px) {
                    display: none;
                }
            }
        }
        &:hover {
            transform: translateY(-5px);
            transition: 0.5s;
        }
    }
    .items-slider {
        .swiper {
            .swiper-wrapper {
                .swiper-slide {
                    .slider-box {
                        padding: 20px;
                        border-radius: 24px;
                        box-shadow: 0px 0px 50px 0px rgba($black, 0.03);
                        .header-top {
                            display: flex;
                            align-items: flex-start;
                            justify-content: space-between;
                            @media screen and (max-width: 1550px) and (min-width: 1200px) {
                                justify-content: center;
                            }
                            @media screen and (max-width: 700px) and (min-width: 575px) {
                                justify-content: center;
                            }
                            .i {
                                font-size: 22px;
                                @media screen and (max-width: 1650px) and (min-width: 1200px) {
                                    font-size: 16px;
                                }
                                @media screen and (max-width: 1550px) and (min-width: 1200px) {
                                    display: none;
                                }
                                @media screen and (max-width: 700px) and (min-width: 575px) {
                                    display: none;
                                }
                            }
                            .badge {
                                font-weight: 600;
                                font-size: 10px;
                                border-radius: 200px;
                                padding: 6px 15px;
                                @media screen and (max-width: 1650px) and (min-width: 1200px) {
                                    padding: 4px 6px;
                                }
                                @media screen and (max-width: 1550px) and (min-width: 1200px) {
                                    display: none;
                                }
                                @media screen and (max-width: 700px) and (min-width: 575px) {
                                    display: none;
                                }
                                &.badge-primary {
                                    box-shadow: 0px 0px 15px 0px rgba($primary-color, 0.40);
                                }
                                &.badge-secondary {
                                    box-shadow: 0px 0px 15px 0px rgba($secondary-color, 0.40);
                                }
                            }
                        }
                    }
                    .slider-content {
                        h4 {
                            font-size: 16px;
                            font-weight: 600;
                            margin-top: 16px;
                        }
                        p {
                            color: var(--black);
                            font-size: 16px;
                            font-weight: 500;
                            @media (max-width: 1702px) {
                                text-overflow: ellipsis;
                                overflow: hidden;
                                display: -webkit-box !important;
                                -webkit-line-clamp: 1;
                                -webkit-box-orient: vertical;
                                white-space: normal;
                            }
                        }
                        h6 {
                            color: var(--black);
                            font-size: 16px;
                            font-weight: 600;
                            margin: 10px 0;
                            span {
                                color: #888;
                                font-size: 16px;
                                font-weight: 600;
                                text-decoration: line-through;
                            }
                        }
                        .input-group {
                            border: 1px dashed var(--black);
                            border-radius: 3px;
                            width: 80px;
                            margin: 0 auto;
                            .input-group-text {
                                border: 0;
                                padding: 7px;
                                background-color: var(--white);
                                cursor: pointer;
                                .svg-color {
                                    width: 10px;
                                    height: 10px;
                                }
                            }
                            input {
                                padding: 0;
                                text-align: center;
                                border-radius: 0;
                                color: var(--black) !important;
                                border-right: 1px dashed var(--black);
                                border-left: 1px dashed var(--black);
                            }
                        }
                    }
                }
            }
            .swiper-button-next {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 20px;
                height: 20px;
                opacity: 0;
                transition: all 0.5s ease;
                background-color: var(--theme-default);
                border-radius: 50%;
                margin-top: -10px;
                z-index: 2;
                &::after {
                    font-size: 12px;
                    color: var(--white);
                }
            }
            .swiper-button-prev {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 20px;
                height: 20px;
                opacity: 0;
                transition: all 0.5s ease;
                background-color: var(--theme-default);
                border-radius: 50%;
                margin-top: -10px;
                z-index: 2;
                &::after {
                    font-size: 12px;
                    color: var(--white);
                }
            }
            &:hover {
                .swiper-button-next {
                    position: absolute;
                    right: 2px;
                    opacity: 1;
                }
                .swiper-button-prev {
                    position: absolute;
                    left: 2px;
                    opacity: 1;
                }
            }
        }
    }
    .widget-1 {
        background-image: url(../../images/widget/widget-bg.png);
        background-size: cover;
        margin-bottom: 25px;
        .card-body {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            padding: 18px 25px;
            .widget-content {
                display: flex;
                align-items: center;
                gap: 15px;
                @media (max-width: 1600px) {
                    gap: 10px;
                }
                .widget-round {
                    position: relative;
                    display: inline-block;
                    border-width: 1px;
                    border-style: solid;
                    border-radius: 100%;
                    &.danger {
                        border-color: var(--danger-color);
                        .svg-fill {
                            fill: var(--danger-color);
                            &:not(.half-circle) {
                                stroke: var(--danger-color);
                            }
                        }
                    }
                    &.secondary {
                        border-color: var(--theme-secondary);
                        .svg-fill {
                            fill: var(--theme-secondary);
                            &:not(.half-circle) {
                                stroke: var(--theme-secondary);
                            }
                        }
                    }
                    &.primary {
                        border-color: var(--theme-default);
                        .svg-fill {
                            fill: var(--theme-default);
                            &:not(.half-circle) {
                                stroke: var(--theme-default);
                            }
                        }
                    }
                    &.success {
                        border-color: var(--success-color);
                        .svg-fill {
                            fill: var(--success-color);
                            &:not(.half-circle) {
                                stroke: var(--success-color);
                            }
                        }
                    }
                    .bg-round {
                        width: 56px;
                        height: 56px;
                        box-shadow: 1px 2px 21px -2px rgba(214, 214, 227, 0.83);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 100%;
                        margin: 6px;
                        position: relative;
                        z-index: 1;
                        @media (max-width: 1600px) {
                            width: 40px;
                            height: 40px;
                        }
                        .svg-fill {
                            width: 24px;
                            height: 24px;
                            @media (max-width: 1600px) {
                                width: 22px;
                                height: 22px;
                            }
                        }
                        .half-circle {
                            height: 52px;
                            position: absolute;
                            left: -9px;
                            width: 35px;
                            bottom: -8px;
                            background: var(--white);
                            z-index: -1;
                            @media (max-width: 1600px) {
                                height: 40px;
                                left: -10px;
                                width: 30px;
                                bottom: -8px;
                            }
                        }
                    }
                }
            }
        }
        &:hover {
            transform: translateY(-5px);
            transition: 0.5s;
            .widget-round {
                .svg-fill {
                    &:not(.half-circle) {
                        animation: tada 1.5s ease infinite;
                    }
                }
            }
        }
        .f-w-500 {
            display: flex;
            align-items: center;
            @media (max-width: 1580px) and (min-width: 1200px) {
                display: none;
            }
        }
        .f-light {
            @media (max-width: 1580px) and (min-width: 1200px) {
                text-overflow: ellipsis;
                overflow: hidden;
                display: -webkit-box !important;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                white-space: normal;
            }
        }
        h4 {
            margin-bottom: 4px;
        }
        .icon-arrow-up {
            &.icon-rotate {
                transform: rotate(-45deg);
                display: inline-block;
            }
        }
        .icon-arrow-down {
            &.icon-rotate {
                transform: rotate(-45deg);
                display: inline-block;
            }
        }
    }
    @keyframes tada {
        0% {
            transform: scaleX(1);
        }
        10% {
            transform: scale3d(0.9, 0.9, 0.9) rotate(-3deg);
        }
        20% {
            transform: scale3d(0.9, 0.9, 0.9) rotate(-3deg);
        }
        30% {
            transform: scale3d(1.1, 1.1, 1.1) rotate(3deg);
        }
        50% {
            transform: scale3d(1.1, 1.1, 1.1) rotate(3deg);
        }
        70% {
            transform: scale3d(1.1, 1.1, 1.1) rotate(3deg);
        }
        90% {
            transform: scale3d(1.1, 1.1, 1.1) rotate(3deg);
        }
        40% {
            transform: scale3d(1.1, 1.1, 1.1) rotate(-3deg);
        }
        60% {
            transform: scale3d(1.1, 1.1, 1.1) rotate(-3deg);
        }
        80% {
            transform: scale3d(1.1, 1.1, 1.1) rotate(-3deg);
        }
        100% {
            transform: scaleX(1);
        }
    }
    .radial-card {
        .radial-chart {
            position: relative;
            @media (min-width: 1200px) and (max-width: 1500px) {
                width: 100%;
            }
        }
        .radial-text {
            @media (min-width: 1200px) and (max-width: 1500px) {
                display: none;
            }
        }
        .text-center {
            min-width: 358px;
            @media (max-width: 1820px) {
                min-width: 290px;
            }
            @include media-breakpoint-down(xl) {
                min-width: 370px;
            }
            @media (max-width: 1080px) {
                min-width: 300px;
            }
        }
        .content-label {
            text-align: center;
            position: absolute;
            left: 50%;
            transform: translate(-50%, -50%);
            bottom: -20px;
            @media (max-width: 767px) {
                display: none;
            }
            p {
                font-size: 14px;
                font-weight: 600;
            }
            h4 {
                color: var(--body-font-color);
                font-size: 20px;
                font-weight: 700;
            }
        }
        .radial-content {
            display: flex;
            align-items: center;
            height: 100%;
            justify-content: center;
            h4 {
                color: var(--body-font-color);
                font-size: 16px;
                font-weight: 600;
            }
            h3 {
                color: var(--body-font-color);
                font-size: 20px;
                font-weight: 700;
            }
            hr {
                border-color: #888;
                margin-top: 7px;
                margin-bottom: 0;
            }
            .progress-widget {
                color: var(--theme-default);
                font-size: 20px;
                font-weight: 600;
                margin-top: 30px;
                span {
                    color: var(--body-font-color);
                    font-size: 20px;
                    font-weight: 700;
                }
            }
            .progress-animate {
                overflow: inherit;
                margin: 0;
                border-radius: 0px;
                height: 7px;
                background-color: rgba($primary-color, 0.1);
                .progress-gradient-primary {
                    background-image: linear-gradient(to right, transparent, var(--theme-default));
                    position: relative;
                }
            }
            .btn-primary {
                margin-top: 40px;
                font-weight: 700 !important;
            }
        }
    }
    .growth-chart {
        .apexcharts-canvas {
            svg {
                @media (min-width: 1200px) and (max-width: 1400px){
                    transform: scale(1.1);
                }
                .apexcharts-inner {
                    .apexcharts-area-series {
                        transform: translate(5px, -40px);
                    }
                }
            }
        }
        @media (min-width: 1200px) and (max-width: 1400px) {
            height: 353px;
        }
    }
    .default-datepicker {
        .react-datepicker{
            width: 100%;
            border: none;
            background-color: var(--white);
            .react-datepicker__navigation-icon {
                font-size: 20px;
                position: relative;
                top: -1px;
                width: 0;
            }
            .react-datepicker__day-names{
                margin-top: 20px;
            }
            .react-datepicker__navigation--previous{
                left: 0;
                top: 25px;
            }
            .react-datepicker__navigation--next{
                right: 0;
                top: 25px;
            }
            .react-datepicker__month-container{
                width: 100%;
                .react-datepicker__header {
                    background-color: initial;
                    border: none;
                    .react-datepicker__current-month {
                        font-size: 20px;
                        margin-top: 20px;
                        color: var(--body-font-color);
                    }
                }
                .react-datepicker__month{
                    margin: .4rem;
                    text-align: center;
                    .react-datepicker__week{
                        margin-bottom: 10px;
                        .react-datepicker__day.react-datepicker__day--selected {
                            background-color: var(--theme-default);
                            color: $white;
                            box-shadow: 0px 4px 27px 0px rgba(48, 142, 135, 0.38);
                        }
                        .react-datepicker__day {
                            &:hover {
                                background-color: var(--theme-default);
                                color: $white;
                            }
                        }
                    }
                }
            }
            .react-datepicker__day-name {
                width: 12%;
                font-size: 14px !important;
                font-family: "Outfit", sans-serif !important;
                color: var(--theme-default) !important;
                font-weight: 700 !important;
            }
            .react-datepicker__day {
                width: 12%;
                padding: 5px 3px;
                color: var(--body-font-color);
                font-size: 14px;
                font-weight: 700;
                &.react-datepicker__day--outside-month{
                    color: var(--font-color);
                }
            }
            .react-datepicker__time-name {
                width: 12%;
            }
        }
    }
    .upcoming-class {
        .activity-day {
            margin-top: 19px;
            h6 {
                color: var(--font-color);
                font-size: 12px;
                font-weight: 500;
                text-transform: uppercase;
            }
        }
        ul {
            padding: 15px;
            border-radius: 10px;
            border: 1px solid var(--light-gray);
            margin-top: 7px;
            li {
                .flex-shrink-0 {
                    h5 {
                        color: var(--font-color);
                        font-size: 18px;
                        font-weight: 600;
                    }
                }
                .flex-grow-1 {
                    h6 {
                        color: var(--font-color);
                        font-size: 12px;
                        font-weight: 600;
                        margin-left: 5px;
                        @media (max-width: 1776px) {
                            text-overflow: ellipsis;
                            overflow: hidden;
                            display: -webkit-box !important;
                            -webkit-line-clamp: 1;
                            -webkit-box-orient: vertical;
                            white-space: normal;
                        }
                    }
                    p {
                        color: var(--body-font-color);
                        font-size: 14px;
                        font-weight: 600;
                        margin-left: 5px;
                        @media (max-width: 1776px) {
                            text-overflow: ellipsis;
                            overflow: hidden;
                            display: -webkit-box !important;
                            -webkit-line-clamp: 1;
                            -webkit-box-orient: vertical;
                            white-space: normal;
                        }
                    }
                }
                +li {
                    margin-top: 15px;
                }
            }
            .circle-dot-primary {
                background-color: rgba($primary-color, 0.25);
                border-radius: 100%;
                padding: 3px;
                font-size: 8px;
                color: var(--theme-default);
                animation: round 1.3s ease-in-out infinite;
            }
            .circle-dot-secondary {
                background-color: rgba($secondary-color, 0.25);
                border-radius: 100%;
                padding: 3px;
                font-size: 8px;
                color: var(--theme-secondary);
                animation: round 1.3s ease-in-out infinite;
            }
        }
    }
    .order-chart {
        >div {
            margin-top: -25px;
            margin-bottom: -43px;
        }
    }
    .profit-chart {
        >div {
            margin-top: -45px;
            margin-bottom: -48px;
        }
    }
    .small-widget {
        overflow: hidden;
        .card-body {
            padding: 24px 15px;
            &.primary {
                .bg-gradient {
                    background-image: linear-gradient(144.16deg, rgba($primary-color, 0.1) 19.06%, rgba($primary-color, 0) 79.03%) !important;
                }
                .svg-fill {
                    fill: var(--theme-default);
                    &:not(.half-circle) {
                        stroke: var(--theme-default);
                    }
                }
            }
            &.warning {
                .bg-gradient {
                    background-image: linear-gradient(144.16deg, rgba($warning-color, 0.1) 19.06%, rgba($warning-color, 0) 79.03%) !important;
                }
                .svg-fill {
                    fill: var(--warning-color);
                    &:not(.half-circle) {
                        stroke: var(--warning-color);
                    }
                }
            }
            &.secondary {
                .bg-gradient {
                    background-image: linear-gradient(144.16deg, rgba($secondary-color, 0.1) 19.06%, rgba($secondary-color, 0) 79.03%) !important;
                }
                .svg-fill {
                    fill: var(--theme-secondary);
                    &:not(.half-circle) {
                        stroke: var(--theme-secondary);
                    }
                }
            }
            &.success {
                .bg-gradient {
                    background-image: linear-gradient(144.16deg, rgba($success-color, 0.1) 19.06%, rgba($success-color, 0) 79.03%) !important;
                }
                .svg-fill {
                    fill: var(--success-color);
                    &:not(.half-circle) {
                        stroke: var(--success-color);
                    }
                }
            }
        }
        h4 {
            margin-bottom: -3px;
        }
        i {
            font-weight: 700;
            font-size: 11px;
        }
        .bg-gradient {
            width: 66px;
            height: 67px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 100px;
            right: -12px;
            top: 50%;
            position: absolute;
            transform: translateY(-50%);
            svg {
                width: 25px;
                height: 25px;
            }
        }
        &:hover {
            transform: translateY(-5px);
            transition: 0.5s;
            .bg-gradient {
                svg {
                    animation: tada 1.5s ease infinite;
                }
            }
        }
    }
    .widget-charts {
        .widget-1 {
            .card-body {
                padding: 30px 25px;
                @media (max-width: 1580px) {
                    padding: 17px 25px;
                }
            }
        }
    }
    .widget-with-chart {
        .card-body {
            align-items: center;
        }
    }
    .investment-card {
        @include media-breakpoint-up(xl) {
            @include media-breakpoint-down(xxl) {
                padding-top: 35px !important;
                padding-bottom: 35px;
            }
        }
        @media (min-width: 1200px) and (max-width: 1369px) {
            padding-bottom: 18px;
        }
        @media (min-width: 1200px) and (max-width: 1300px) {
            padding-top: 30px !important;
        }
        @media (min-width: 1200px) and (max-width: 1300px) {
            padding-top: 24px !important;
            height: 260px;
        }
        svg{
            @media (min-width: 1200px) and (max-width: 1300px) {
                transform: scale(1.7);
            }
        }
    }
}