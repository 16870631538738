.table-bordernone {
    td {
        border: none !important;
    }
}
.default-dashboard,
.dashboard-2,
.dashboard-3,
.general-widget {
    div.dataTables_wrapper {
        position: unset;
        .dataTables_info {
            padding-left: 24px;
            color: var(--font-color);
            font-family: $body-font;
            font-weight: 500;
            @media (max-width: 1230px) {
                display: none;
            }
        }
        .dataTables_paginate {
            margin-right: 24px;
            font-family: $body-font;
            color: var(--font-color);
            border: none;
            .paginate_button {
                border: none;
                padding: 2px 9px;
                border-radius: 3px;
                margin: 0 6px !important;
                font-weight: 500;
                .disabled {
                    &:hover {
                        border: none !important;
                    }
                }
                &:hover {
                    border: none;
                }
            }
        }
        .dataTables_length {
            margin-bottom: 26px;
            label {
                display: none !important;
            }
        }
        div.dataTables_filter {
            position: absolute;
            top: 17px;
            right: 30px;
            @media (max-width: 1170px) and (min-width: 576px) {
                display: none;
            }
            @media (max-width: 575px) {
                left: 18px;
                right: unset;
                margin-top: 34px;
            }
            [dir="rtl"] & {
                left: 30px;
                right: unset;
                @media (max-width: 575px) {
                    right: 18px;
                    left: unset;
                }
            }
            input[type=search] {
                width: 150px !important;
                height: 34px;
                border-radius: 6px;
                border-style: solid;
                @media (max-width: 575px) {
                    height: 27px;
                }
            }
        }
        table.dataTable {
            margin-top: 13px;
            border: none;
            margin-bottom: 0px !important;
            @media (max-width: 575px) {
                margin-top: 42px !important;
            }
            &.display {
                tbody {
                    tr.odd,
                    tr.even {
                        background-color: transparent !important;
                        >.sorting_1 {
                            background-color: transparent !important;
                        }
                    }
                }
            }
            thead {
                >tr {
                    >th {
                        border: none !important;
                        border-bottom: 1px solid var(--light-gray) !important;
                        text-transform: capitalize;
                        font-weight: 700;
                        color: var(--font-color);
                        &:before,
                        &:after {
                            display: none;
                        }
                    }
                }
            }
            tbody {
                >tr {
                    >td {
                        border-bottom: none;
                    }
                }
            }
        }
    }
    .card-header {
        .header-top {
            .nav {
                background-color: var(--body-color);
                border: 1px solid var(--light-gray);
                border-radius: 5px;
                .nav-item {
                    a {
                        padding: 6px 8px;
                        color: var(--body-font-color);
                        font-weight: 600;
                    }
                }
            }
            .nav-pills {
                .nav-link {
                    &.active {
                        background-color: var(--theme-default);
                        border-radius: 5px;
                        color: var(--white);
                    }
                }
            }
        }
    }
    .dropdown {
        .btn {
            padding: 4px 14px;
            background-color: var(--body-color);
            border: 1px solid var(--light-gray);
            font-family: $body-font;
            color: var(--body-font-color);
            border-radius: 5px;
            &:active {
                background-color: var(--body-color) !important;
                border: 1px solid var(--light-gray) !important;
            }
        }
    }
    .comman-round {
        height: 35px;
        width: 35px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba($primary-color, 0.1);
        border: 2px solid rgba($primary-color, 0.1);
    }
}
.default-dashboard {
    .welcome-banner {
        .welcome-card {
            background-color: var(--theme-default);
            border-radius: 24px 24px 0 0;
            overflow: hidden;
            height: 180px;
            display: flex;
            align-items: center;
            position: relative;
            .img-1 {
                right: 4px;
                animation: up-down 4s infinite;
                @media (max-width: 767px) {
                    display: none;
                }
                @media (min-width: 991px) and (max-width: 1360px) {
                    display: none;
                }
            }
            .img-2 {
                top: 0;
                animation: myOrbit 9s linear infinite;
            }
            .img-3 {
                animation: up-down 4s infinite;
                @media (max-width: 767px) {
                    width: 60px;
                }
                @media (min-width: 991px) and (max-width: 1360px) {
                    width: 60px;
                }
            }
            .img-4 {
                bottom: -25px;
                left: 27%;
                animation: 8s linear 0s infinite normal none running move1;
                @media (max-width: 767px) {
                    width: 60px;
                }
                @media (min-width: 991px) and (max-width: 1360px) {
                    width: 60px;
                }
            }
            .img-5 {
                right: 169px;
                top: -14px;
                animation: myOrbit 9s linear infinite;
                @media (max-width: 767px) {
                    width: 55px;
                    right: 104px;
                }
                @media (min-width: 991px) and (max-width: 1360px) {
                    width: 55px;
                    right: 104px;
                }
            }
        }
        p {
            color: var(--font-color);
            font-size: 16px;
            font-weight: 400;
            margin-bottom: 19px;
            @media (max-width: 1550px) {
                text-overflow: ellipsis;
                overflow: hidden;
                display: -webkit-box !important;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                white-space: normal;
            }
        }
        span {
            padding: 10px 8px;
            background-color: var(--body-color);
            border: 1px solid var(--light-gray);
            border-radius: 6px;
            font-weight: 700;
            svg {
                width: 18px;
                height: 18px;
            }
        }
        h1 {
            font-size: calc(18px + (30 - 18) * ((100vw - 300px) / (1920 - 300)));
            img {
                width: 20px;
                height: auto;
                cursor: pointer;
                animation-name: wave-animation;
                animation-duration: 2.5s;
                animation-iteration-count: infinite;
                transform-origin: 70% 70%;
            }
        }
    }
    .earning-card {
        .dropdown {
            @media (max-width: 1540px) {
                display: none;
            }
        }
        span {
            svg {
                height: 18px;
                width: 18px;
            }
        }
        #earnings-chart {
            [dir="rtl"] & {
                direction: ltr;
            }
        }
        .card-body {
            h2 {
                font-size: calc(16px + (26 - 17) * ((100vw - 300px) / (1920 - 300)));
            }
        }
    }
    .job-card {
        .card-header {
            p {
                color: var(--body-font-color);
                span {
                    color: var(--font-color);
                    font-weight: 700;
                }
            }
        }
        .card-body {
            li {
                padding: 11px 16px;
                background-color: var(--body-color);
                border: 1px solid var(--light-gray);
                border-radius: 10px;
                &:first-child {
                    @media (max-width: 1460px) and (min-width: 1399.98px) {
                        display: none;
                    }
                    @media (max-width: 1280px) and (min-width: 1200px) {
                        display: none;
                    }
                    @media (max-width: 480px) {
                        display: none;
                    }
                    @media (max-width: 380px) {
                        padding: 11px 10px;
                    }
                }
                .d-flex {
                    .flex-shrink-0 {
                        width: 45px;
                        height: 40px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 5px;
                        @media (max-width: 380px) {
                            width: 35px;
                            height: 32px;
                        }
                        &.bg-light-primary {
                            svg {
                                stroke: var(--theme-default);
                            }
                        }
                        &.bg-light-secondary {
                            svg {
                                stroke: var(--theme-secondary);
                            }
                        }
                        svg {
                            width: 20px;
                            height: 20px;
                            @media (max-width: 380px) {
                                width: 15px;
                                height: 15px;
                            }
                        }
                    }
                    .flex-grow-1 {
                        text-align: center;
                        h3 {
                            font-size: calc(16px + (25 - 19) * ((100vw - 300px) / (1920 - 300)));
                        }
                        p {
                            color: var(--font-color);
                            font-weight: 600;
                            @media (max-width: 1720px) {
                                text-overflow: ellipsis;
                                overflow: hidden;
                                display: -webkit-box !important;
                                -webkit-line-clamp: 1;
                                -webkit-box-orient: vertical;
                                white-space: normal;
                            }
                        }
                    }
                }
                &:hover {
                    transform: translateY(-5px);
                    transition: 0.5s;
                    .flex-shrink-0 {
                        svg {
                            animation: tada 1.5s ease infinite;
                        }
                    }
                }
            }
            table {
                thead {
                    tr {
                        th {
                            color: var(--font-color);
                            padding-left: 9px;
                            padding-right: 9px;
                        }
                    }
                }
                tbody {
                    tr {
                        &:last-child {
                            td {
                                padding-bottom: 0;
                                border: none;
                            }
                        }
                        &:hover {
                            td {
                                &:last-child {
                                    .d-flex {
                                        .flex-grow-1 {
                                            h6 {
                                                color: var(--theme-default);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        td {
                            font-weight: 600;
                            padding: 11px 9px;
                            @media (max-width: 1720px) {
                                padding: 11px 6px;
                            }
                            @media (max-width: 1665px) {
                                padding: 11px 4px;
                            }
                            .d-flex {
                                .flex-shrink-0 {
                                    img {
                                        border-radius: 50%;
                                        border: 1px solid var(--shape-border);
                                    }
                                }
                                .flex-grow-1 {
                                    h6 {
                                        transition: all .4s;
                                        font-weight: 600;
                                        color: var(--dark);
                                        @media (max-width: 1665px) {
                                            text-overflow: ellipsis;
                                            overflow: hidden;
                                            display: -webkit-box !important;
                                            -webkit-line-clamp: 1;
                                            -webkit-box-orient: vertical;
                                            white-space: normal;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .transaction-history {
        table {
            margin-top: 13px !important;
            thead {
                tr {
                    th {
                        @media (max-width: 1855px) and (min-width: 1200px) {
                            padding: 11px 9px;
                        }
                        @media (max-width: 1780px) and (min-width: 1200px) {
                            padding: 11px 7px;
                        }
                        @media (max-width: 1705px) and (min-width: 1200px) {
                            padding: 11px 3px;
                        }
                        @media (max-width: 1480px) and (min-width: 1399.98px) {
                            padding: 5px 3px;
                        }
                        &:nth-child(2) {
                            @media (max-width: 1630px) and (min-width: 1200px) {
                                display: none;
                            }
                        }
                    }
                }
            }
            tbody {
                tr {
                    td {
                        font-weight: 600;
                        color: var(--dark);
                        @media (max-width: 1855px) and (min-width: 1200px) {
                            padding: 12px 9px;
                        }
                        @media (max-width: 1780px) and (min-width: 1200px) {
                            padding: 12px 7px;
                        }
                        @media (max-width: 1480px) and (min-width: 1399.98px) {
                            padding: 5px 7px;
                        }
                        &:first-child {
                            @include media-breakpoint-down(xl) {
                                min-width: 192px;
                            }
                        }
                        &:nth-child(2) {
                            @media (max-width: 1630px) and (min-width: 1200px) {
                                display: none;
                            }
                            @include media-breakpoint-down(xl) {
                                min-width: 105px;
                            }
                        }
                        .d-flex {
                            .flex-shrink-0 {
                                height: 35px;
                                width: 35px;
                                background-color: var(--body-color);
                                border: 1px solid var(--light-gray);
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                border-radius: 50%;
                            }
                            .flex-grow-1 {
                                a {
                                    h6 {
                                        font-weight: 600;
                                        color: var(--body-font-color);
                                    }
                                }
                                h6 {
                                    font-weight: 600;
                                    color: var(--dark);
                                }
                                p {
                                    color: var(--font-color);
                                    font-weight: 600;
                                }
                            }
                        }
                        .btn {
                            padding: 7px 18px;
                            min-width: 115px;
                        }
                    }
                }
            }
        }
    }
    .audit-log {
        .d-flex {
            padding: 17px 0;
            @media (max-width: 1480px) and (min-width: 1399.98px) {
                padding: 13px 0;
            }
            &:last-child {
                .flex-shrink-0 {
                    &::before {
                        display: none;
                    }
                }
                padding-bottom: 0px;
            }
            .flex-shrink-0 {
                height: 30px;
                width: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                position: relative;
                &::before {
                    position: absolute;
                    content: "";
                    width: 2px;
                    height: calc(100% - 25%);
                    top: 55%;
                    left: 45%;
                    transform: translateY(100%);
                    background-color: var(--light-gray);
                    @media (max-width: 1480px) and (min-width: 1399.98px) {
                        top: 39%;
                    }
                }
            }
            .flex-grow-1 {
                a {
                    h6 {
                        font-weight: 600;
                        color: var(--body-font-color);
                        @media (max-width: 1860px) {
                            text-overflow: ellipsis;
                            overflow: hidden;
                            display: -webkit-box !important;
                            -webkit-line-clamp: 1;
                            -webkit-box-orient: vertical;
                            white-space: normal;
                        }
                    }
                }
                p {
                    font-weight: 600;
                    color: var(--font-color);
                }
            }
            span {
                padding: 6px 8px;
                border-radius: 5px;
                font-weight: 600;
                min-width: 70px;
                background-color: var(--body-color);
                border: 1px solid var(--light-gray);
                @media (min-width:1400px) and (max-width: 1480px) {
                    display: none;
                }
            }
        }
    }
    .investment-card {
        ul {
            margin-top: 36px;
            li {
                padding: 0 22px;
                border-right: 1px solid var(--light-gray);
                @media (min-width:1399.98px) and (max-width: 1710px) {
                    margin: 16px 0;
                }
                @media (min-width:1399.98px) and (max-width: 1590px) {
                    margin: 47px 0 15px 0;
                    padding: 0 12px;
                }
                @media (max-width: 1480px) and (min-width: 1399.98px) {
                    margin: 24px 0 15px 0;
                }
                [dir="rtl"] & {
                    border-left: 1px solid var(--light-gray);
                    border-right: unset;
                }
                &:last-child {
                    border-right: none;
                    [dir="rtl"] & {
                        border-left: none;
                    }
                }
                p {
                    color: var(--font-color);
                    font-weight: 600;
                }
                h6 {
                    color: var(--body-font-color);
                    font-weight: 600;
                }
            }
            @media (max-width: 1250px) and (min-width: 1200px) {
                margin-top: -11px;
            }
            @media (max-width: 1199px)  {
                margin-top: 0px;
            }
        }
    }
    .latest-activity {
        .d-flex {
            &:last-child {
                .flex-shrink-0 {
                    &::before {
                        display: none;
                    }
                }
                padding-bottom: 0px;
            }
            padding: 8px 0;
            .flex-shrink-0 {
                height: 35px;
                width: 35px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                border-width: 2px !important;
                border-style: solid;
                svg {
                    width: 21px;
                    height: 21px;
                }
            }
            .flex-grow-1 {
                a {
                    h6 {
                        font-weight: 600;
                        color: var(--body-font-color);
                        @media (max-width: 1750px) {
                            text-overflow: ellipsis;
                            overflow: hidden;
                            display: -webkit-box !important;
                            -webkit-line-clamp: 1;
                            -webkit-box-orient: vertical;
                            white-space: normal;
                        }
                    }
                }
                p {
                    font-weight: 600;
                    color: var(--font-color);
                    @media (max-width: 1750px) {
                        text-overflow: ellipsis;
                        overflow: hidden;
                        display: -webkit-box !important;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        white-space: normal;
                    }
                }
            }
            span {
                padding: 4px 8px;
                border-radius: 5px;
                font-weight: 600;
                min-width: 70px;
                background-color: var(--body-color);
                border: 1px solid var(--light-gray);
                color: var(--dark);
                @media (max-width: 1480px) and (min-width: 1399.98px) {
                    display: none;
                }
            }
        }
    }
    .growthcard {
        .header-top {
            .nav {
                @media (max-width: 1320px) and (min-width: 1200px) {
                    display: none;
                }
                @include media-breakpoint-up(lg) {
                    @include media-breakpoint-down(xl) {
                        display: none;
                    }
                }
                @include media-breakpoint-down(sm) {
                    display: none;
                }
            }
        }
        .growth-chart {
            .apexcharts-canvas {
                svg {
                    .apexcharts-inner {
                        .apexcharts-area-series {
                            transform: translate(5px, -40px);
                        }
                    }
                }
            }
        }
    }
    .top-user {
        li {
            padding: 8px 0;
            @include media-breakpoint-up(md) {
                @include media-breakpoint-down(xl) {
                    padding: 9px 0;
                }
            }
            div.d-flex {
                div {
                    a {
                        h6 {
                            font-weight: 600;
                            color: var(--body-font-color);
                            text-overflow: ellipsis;
                            overflow: hidden;
                            display: -webkit-box !important;
                            -webkit-line-clamp: 1;
                            -webkit-box-orient: vertical;
                            white-space: normal;
                        }
                    }
                    p {
                        color: var(--font-color);
                        font-weight: 600;
                        svg {
                            height: 13px;
                            width: 13px;
                            stroke: var(--font-color);
                        }
                    }
                }
            }
            span {
                color: var(--font-color);
                font-weight: 600;
                @media (min-width: 992px) and (max-width: 1060px) {
                    display: none;
                }
                @media (max-width: 360px) {
                    display: none;
                }
            }
            button {
                min-width: 95px;
                @media (min-width: 1200px) and (max-width: 1800px) {
                    padding: 6px 0;
                }
                @media (max-width: 1630px) and (min-width: 1399.98px) {
                    display: none;
                }
            }
        }
    }
    .news-update {
        .d-flex {
            padding: 9px 0;
            @media (min-width: 1200px) and (max-width: 1399.98px) {
                padding: 8px 0;
            }
            .flex-grow-1 {
                h6 {
                    font-weight: 600;
                    color: var(--body-font-color);
                    font-size: 14px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    display: -webkit-box !important;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    white-space: normal;
                }
                .text-truncate {
                    font-weight: 600;
                    color: var(--font-color);
                    font-size: 14px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    display: -webkit-box !important;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    white-space: normal;
                    line-height: 1.1;
                }
            }
            span {
                padding: 4px 8px;
                border-radius: 5px;
                font-weight: 600;
                min-width: 70px;
                color: var(--body-font-color);
                font-size: 13px;
                background-color: var(--body-color);
                border: 1px solid var(--light-gray);
                @media (max-width: 1655px) and (min-width: 1399.98px) {
                    display: none;
                }
                @media (max-width: 1350px) and (min-width: 1200px) {
                    display: none;
                }
            }
            &:last-child {
                padding-bottom: 0px;
            }
        }
    }
    .manage-invoice {
        table {
            thead {
                tr {
                    th {
                        color: var(--font-color);
                        font-weight: 700;
                        font-size: 14px;
                        padding: 10px;
                        text-transform: capitalize;
                        &:first-child {
                            padding-right: 0 !important;
                            padding-left: 0 !important;
                            padding-bottom: 0 !important;
                            .form-check {
                                padding-left: 17px;
                                min-height: 34px;
                                margin-bottom: 0 !important;
                            }
                        }
                        &:nth-child(2) {
                            padding-left: 0 !important;
                        }
                        &:nth-child(4) {
                            @media (min-width: 1200px) and (max-width: 1720px) {
                                display: none;
                            }
                        }
                        &:nth-child(5) {
                            @media(min-width: 1200px) and (max-width: 1399.98px) {
                                display: none;
                            }
                        }
                        @media (max-width: 1450px) and (min-width: 1399.98px) {
                            padding: 10px 5px;
                        }
                        @media (min-width: 1200px) and (max-width: 1399.98px) {
                            padding: 9px 10px;
                        }
                    }
                }
            }
            tbody {
                tr {
                    td {
                        padding: 4px 7px;
                        vertical-align: middle;
                        @media (max-width: 1450px) and (min-width: 1399.98px) {
                            padding: 1px 5px;
                        }
                        @media (min-width: 1200px) and (max-width: 1399.98px) {
                            padding: 1px 10px;
                        }
                        h6 {
                            color: var(--body-font-color);
                        }
                        &:first-child {
                            padding-right: 0 !important;
                            padding-left: 0 !important;
                            .form-check {
                                padding-left: 17px;
                                min-height: 34px;
                            }
                        }
                        &:nth-child(2) {
                            padding-left: 0 !important;
                            @include media-breakpoint-down(xl) {
                                min-width: 123px;
                            }
                        }
                        &:nth-child(3) {
                            @include media-breakpoint-down(xl) {
                                min-width: 70px;
                            }
                        }
                        &:nth-child(4) {
                            @media (max-width: 1720px) and (min-width: 1200px) {
                                display: none !important;
                            }
                            @include media-breakpoint-down(xl) {
                                min-width: 115px;
                            }
                        }
                        &:nth-child(5) {
                            @media (max-width: 1399.98px) and (min-width: 1200px) {
                                display: none;
                            }
                            @include media-breakpoint-down(xl) {
                                min-width: 70px;
                            }
                        }
                        p {
                            color: var(--font-color);
                        }
                        .btn {
                            padding: 7px 18px;
                            min-width: 115px;
                        }
                    }
                    &:last-child{
                        td{
                            padding-bottom: 0 !important;
                        }
                    }
                }
            }
        }
    }
    .total-invest {
        .income-chart {
            display: flex;
            justify-content: center;
            align-items: center;
            @media (max-width: 460px) {
                width: 100%;
            }
            .Incomechrt {
                @media (max-width: 767px) {
                    margin: 0 0 -60px 0;
                }
            }
            .apexcharts-canvas {
                min-height: 120px !important;
                svg {
                    min-height: 120px !important;
                }
            }
        }
        .invest-content {
            margin-top: -31px;
            @media (max-width: 1490px) and (min-width: 1399.98px) {
                margin-top: -26px;
            }
            @media (max-width: 767px) {
                margin-top: 0;
            }
            @media (max-width: 460px) {
                width: 100%;
            }
            .stroke-icon {
                width: 30px;
                height: 30px;
                display: block;
                margin: 0 auto;
            }
            .btn {
                box-shadow: 0px 0px 15px 0px rgba($primary-color, 0.40);
                padding: 0px 8px;
                margin-top: 5px;
                span {
                    background-color: var(--white);
                    height: 6px;
                    width: 6px;
                    border-radius: 50%;
                    vertical-align: middle;
                    margin-right: 3px;
                }
            }
            h3 {
                color: var(--body-font-color);
            }
            p {
                font-size: 16px;
                font-weight: 600;
                color: var(--font-color);
                &.des-title {
                    margin-top: 13px;
                }
                &.description-title {
                    margin-top: 19px;
                }
            }
        }
    }
    @keyframes tada {
        0% {
            transform: scaleX(1);
        }
        10% {
            transform: scale3d(0.9, 0.9, 0.9) rotate(-3deg);
        }
        20% {
            transform: scale3d(0.9, 0.9, 0.9) rotate(-3deg);
        }
        30% {
            transform: scale3d(1.1, 1.1, 1.1) rotate(3deg);
        }
        50% {
            transform: scale3d(1.1, 1.1, 1.1) rotate(3deg);
        }
        70% {
            transform: scale3d(1.1, 1.1, 1.1) rotate(3deg);
        }
        90% {
            transform: scale3d(1.1, 1.1, 1.1) rotate(3deg);
        }
        40% {
            transform: scale3d(1.1, 1.1, 1.1) rotate(-3deg);
        }
        60% {
            transform: scale3d(1.1, 1.1, 1.1) rotate(-3deg);
        }
        80% {
            transform: scale3d(1.1, 1.1, 1.1) rotate(-3deg);
        }
        100% {
            transform: scaleX(1);
        }
    }
    @keyframes wave-animation {
        0% {
            transform: rotate(0.0deg)
        }
        10% {
            transform: rotate(14.0deg)
        }
        20% {
            transform: rotate(-8.0deg)
        }
        30% {
            transform: rotate(14.0deg)
        }
        40% {
            transform: rotate(-4.0deg)
        }
        50% {
            transform: rotate(10.0deg)
        }
        60% {
            transform: rotate(0.0deg)
        }
        100% {
            transform: rotate(0.0deg)
        }
    }
    @keyframes up-down {
        0% {
            transform: translateY(0);
        }
        50% {
            transform: translateY(-10px);
        }
        100% {
            transform: translateY(0);
        }
    }
    @keyframes myOrbit {
        0% {
            transform: rotate(0deg) translateX(10px) rotate(0deg);
        }
        100% {
            transform: rotate(360deg) translateX(10px) rotate(-360deg);
        }
    }
    @keyframes move1 {
        0% {
            transform: rotate(0deg) translateX(15px) rotate(0deg);
        }
        100% {
            transform: rotate(360deg) translateX(15px) rotate(-360deg);
        }
    }
}
.text-title-gray {
    color: var(--light-gray-color);
    font-weight: 400;
    font-size: 12px;
}