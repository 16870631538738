/**=====================
    3.25 Knowledgebase CSS start
==========================**/
.knowledgebase-bg {
  height: 500px;
  border-radius: 15px;
  margin-bottom: 30px;
  @include media-breakpoint-down(xl) {
    height: 400px;
  }
  @include media-breakpoint-down(sm) {
    height: 250px;
  }
}
.knowledgebase-search {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  left: 5%;
  @include media-breakpoint-down(sm) {
    width: calc(100% - 30px);
    left: 15px;
    height: calc(100% - 30px);
    background-color: rgba($white, 0.7);
  }
  >div {
    @include media-breakpoint-down(sm) {
      width: 100%;
      text-align: center;
      padding: 15px;
      border-right: 10px;
    }
    h3 {
      @include media-breakpoint-down(sm) {
        font-size: 20px;
        margin-bottom: 0;
      }
    }
  }
  .form-inline {
    background-color: var(--white);
    width: 500px;
    padding: 12px 50px;
    border-radius: 10px;
    border: 1px solid #e6e6e6;
    margin-top: 10px;
    position: relative;
    ::placeholder {
      color: var(--font-color);
    }
    @include media-breakpoint-down(xl) {
      width: 400px;
      padding: 7px 45px;
    }
    @include media-breakpoint-down(sm) {
      width: 90%;
      padding: 3px 45px;
    }
    @include media-breakpoint-down(xl) {
      width: 400px;
      padding: 7px 45px;
    }
    @include media-breakpoint-down(sm) {
      width: 90%;
      padding: 3px 45px;
    }
    svg {
      position: absolute;
      left: 20px;
      width: 20px;
      top: 19px;
      stroke: var(--font-color);
      @include media-breakpoint-down(xl) {
        top: 14px;
      }
    }
    .mb-3{
      margin-bottom: 0 !important;
    }
  }
}
.features-faq {
  h5 {
    color: var(--body-font-color);
  }
  p {
    color: var(--font-color);
  }
}
.browse {
  .browse-articles {
    span {
      vertical-align: middle;
      &:nth-child(2) {
        padding-left: 20px;
      }
      svg {
        width: 14px;
        height: 14px;
        vertical-align: text-top;
        left: 0;
        top: 0;
      }
    }
    h6 {
      padding-bottom: 10px;
      margin-bottom: 0px;
      border-bottom: 1px solid var(--light-color);
      padding-left: 25px;
      font-weight: 700;
      span {
        svg {
          width: 16px;
          height: 16px;
          vertical-align: text-top;
          margin-left: -27px;
        }
      }
    }
    ul {
      li {
        padding: 8px;
        border-radius: 8px;
        a {
          color: var(--body-font-color);
          position: relative;
          display: flex;
          align-items: center;
          gap: 10px;
        }
        &:hover {
          background-color: var(--light-color);
        }
      }
    }
  }
}
@media only screen and (max-width: 1366px) {
  .browse {
    div[class^="col-"] {
      &:nth-child(3) {
        margin-top: 30px;
      }
    }
  }
}
@media only screen and (max-width: 360px) {
  .browse {
    .browse-articles {
      ul {
        li {
          a {
            span {
              &:nth-child(2) {
                width: 158px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                display: inline-block;
              }
            }
          }
        }
      }
    }
  }
}
.badge {
  &.pull-right {
    padding-top: 6px;
  }
}
.articles-icons {
  svg {
    min-width: 18px;
  }
  .flex-grow-1 {
    flex: 1 !important;
    h5 {
      color: var(--body-font-color);
    }
    p {
      color: var(--theme-body-font-color);
    }
  }
}
.d-flex {
  &.faq-widgets {
    svg {
      min-width: 18px;
    }
    .flex-grow-1 {
      flex: 1;
    }
  }
}
/**=====================
      3.25 Knowledgebase CSS End
  ==========================**/