/**=====================
     39. Table  CSS Start
==========================**/
thead,
tbody,
tfoot,
tr,
td,
th {
	border-color: var(--table-double-color);
}
.table {
	tbody {
		tr {
			th,
			td {
				border-bottom: 1px solid var(--light-gray);
			}
		}
	}
	margin-bottom: $table-b-margin;
	> :not(:last-child) {
		> :last-child {
			>* {
				border-bottom-color: var(--light-gray);
			}
		}
	}
	th,
	td {
		padding: $table-padding;
	}
	thead.bg-primary {
		tr {
			th {
				color: var(--white);
			}
		}
	}
	th {
		color: var(--dark);
		font-weight: 700;
		&.f-light {
			color: var(--chart-text-color);
			opacity: 0.8;
		}
	}
	td {
		color: var(--dark);
	}
	[class*='bg-'] {
		color: var(--white);
	}
	.bg-light {
		color: var(--theme-body-font-color);
	}
}
.table[class*='bg-'] {
	th,
	td {
		color: var(--white);
	}
}
.typography-table {
	tr {
		&:last-child {
			border-bottom: none;
			td {
				border-bottom: none;
			}
		}
		td {
			&:first-child {
				min-width: 100px;
			}
			&:last-child {
				min-width: 300px;
			}
		}
	}
}
.table-striped {
	thead {
		tr {
			th {
				p {
					margin-bottom: 0;
				}
			}
		}
	}
}
.table-bordered {
	thead,
	tbody,
	tfoot,
	tr,
	td,
	th {
		border-color: rgba($gray-60, 0.3);
	}
}
.user-content {
	td,
	th {
		border-color: var(--light-gray);
	}
}
.border-bottom-primary {
	th,
	td {
		border-bottom: 1px solid var(--theme-default) !important;
	}
}
.border-bottom-secondary {
	th,
	td {
		border-bottom: 1px solid var(--theme-secondary) !important;
	}
}
.border-bottom-success {
	th,
	td {
		border-bottom: 1px solid var(--success-color) !important;
	}
}
.border-bottom-danger {
	th,
	td {
		border-bottom: 1px solid var(--danger-color) !important;
	}
}
.border-bottom-info {
	th,
	td {
		border-bottom: 1px solid var(--info-color) !important;
	}
}
.border-bottom-warning {
	th,
	td {
		border-bottom: 1px solid var(--warning-color) !important;
	}
}
.border-bottom-light {
	th,
	td {
		border-bottom: 1px solid var(--white) !important;
	}
}
.basic-table {
	tr {
		vertical-align: middle;
		&:last-child {
			border-bottom: unset;
		}
	}
}
.table-inverse {
	color: var(--white);
	background-color: var(--dark);
	th,
	td {
		color: var(--white);
		background-color: var(--dark);
	}
}
.signal-table {
	.table-hover {
		tbody {
			tr {
				td {
					svg {
						width: 30px;
						height: 30px;
						padding: 6px;
						border-radius: 4px;
					}
				}
			}
		}
	}
}
.card-block {
	.table-responsive {
		.table {
			caption {
				padding-left: 10px;
			}
		}
	}
}
.table-dark {
	th {
		color: var(--white);
	}
}
.bootstrap-table {
	.table {
		tbody {
			tr {
				&:last-child {
					border-bottom: var(--white)
				}
			}
		}
	}
}
.ui-components {
	&.card-block {
		.table-responsive {
			.table-bordered {
				td {
					vertical-align: middle;
				}
			}
		}
	}
	.fa-spinner {
		animation: fa-spin 2s infinite linear;
	}
	p {
		margin-bottom: 0;
	}
	.alert-primary {
		.alert-link {
			color: var(--white);
		}
		.btn-close {
			filter: brightness(1) invert(1);
		}
	}
}
.checkbox-solid-primary {
	&.checkbox {
		label {
			display: inline-block;
			position: relative;
			padding-left: 16px;
			cursor: pointer;
			margin-top: 10px;
			margin-bottom: 10px;
			&::before {
				content: "";
				display: inline-block;
				position: absolute;
				width: 19px;
				height: 19px;
				left: -2px;
				margin-left: -16px;
				border: 1px solid #efefef;
				border-radius: 3px;
				transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
			}
		}
		input[type=checkbox] {
			&:checked {
				+ {
					label {
						&::before {
							background-color: var(--theme-default);
							border-color: var(--theme-default);
							color: var(--white);
							font-family: "Font Awesome 5 Free";
							font-weight: 900;
							content: "\f00c";
							text-align: center;
							line-height: 1.2;
						}
					}
				}
			}
			&:disabled {
				+ {
					label {
						&::before {
							background-color: var(--light-gray);
							cursor: not-allowed;
						}
					}
				}
			}
		}
		label {
			&:before {
				background-color: var(--theme-default);
				border-color: var(--theme-default);
				color: var(--white);
			}
		}
	}
}
.m-checkbox-inline {
	.checkbox {
		display: inline-block;
		label {
			display: inline-block;
			position: relative;
			padding-left: 10px;
			cursor: pointer;
			margin-top: 10px;
			margin-bottom: 10px;
			&::before {
				content: "";
				display: inline-block;
				position: absolute;
				width: 19px;
				height: 19px;
				left: -3px;
				margin-left: -16px;
				border: 1px solid var(--light-gray);
				border-radius: 3px;
				background-color: var(--white);
				transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
			}
		}
	}
	label {
		margin-right: 20px;
	}
	.checkbox-primary {
		input[type=checkbox] {
			&:checked {
				+ {
					label {
						&::before {
							border-color: var(--theme-default);
							color: var(--theme-default);
							font-family: "Font Awesome 5 Free";
							font-weight: 900;
							content: "\f00c";
							text-align: center;
							line-height: 1.2;
						}
					}
				}
			}
		}
		label {
			&::before {
				border-color: var(--theme-default);
			}
		}
	}
	.checkbox-primary {
		input[type=checkbox] {
			&:checked {
				+ {
					label {
						&::before {
							border-color: var(--theme-default);
							color: var(--theme-default);
							font-family: "Font Awesome 5 Free";
							font-weight: 900;
							content: "\f00c";
							text-align: center;
							line-height: 1.2;
						}
					}
				}
			}
		}
		label {
			&::before {
				border-color: var(--theme-default);
			}
		}
	}
	.checkbox-secondary {
		input[type=checkbox] {
			&:checked {
				+ {
					label {
						&::before {
							border-color: var(--theme-secondary);
							color: var(--theme-secondary);
							font-family: "Font Awesome 5 Free";
							font-weight: 900;
							content: "\f00c";
							text-align: center;
							line-height: 1.2;
						}
					}
				}
			}
		}
		label {
			&::before {
				border-color: var(--theme-secondary);
			}
		}
	}
	.checkbox-success {
		input[type=checkbox] {
			&:checked {
				+ {
					label {
						&::before {
							border-color: var(--success-color);
							color: var(--success-color);
							font-family: "Font Awesome 5 Free";
							font-weight: 900;
							content: "\f00c";
							text-align: center;
							line-height: 1.2;
						}
					}
				}
			}
		}
		label {
			&::before {
				border-color: var(--success-color);
			}
		}
	}
}
.checkbox-dark {
	&.checkbox {
		display: inline-block;
		label {
			display: inline-block;
			position: relative;
			padding-left: 16px;
			cursor: pointer;
			margin-top: 10px;
			margin-bottom: 10px;
			&::before {
				content: "";
				display: inline-block;
				position: absolute;
				width: 19px;
				height: 19px;
				left: -3px;
				margin-left: -16px;
				border: 1px solid var(--dark);
				border-radius: 3px;
				background-color: var(--white);
				transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
			}
		}
	}
	input[type=checkbox] {
		&:checked {
			+ {
				label {
					&::before {
						border-color: var(--dark);
						color: var(--dark);
						font-family: "Font Awesome 5 Free";
						font-weight: 900;
						content: "\f00c";
						text-align: center;
						line-height: 1.2;
					}
				}
			}
		}
	}
	label {
		&::before {
			border-color: var(--dark);
		}
	}
}
.m-squar {
	&.checkbox {
		label {
			display: inline-block;
			position: relative;
			padding-left: 16px;
			cursor: pointer;
			margin-top: 10px;
			margin-bottom: 10px;
			&::before {
				content: "";
				display: inline-block;
				position: absolute;
				width: 19px;
				height: 19px;
				left: 0;
				margin-left: -16px;
				border: 1px solid #efefef;
				background-color: var(--white);
				transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
			}
		}
		input[type=checkbox] {
			opacity: 0;
		}
	}
	input[type=checkbox] {
		&:checked {
			+ {
				label {
					&::before {
						color: var(--dark);
						font-family: "Font Awesome 5 Free";
						font-weight: 900;
						content: "\f00c";
						text-align: center;
						line-height: 1.2;
					}
				}
			}
		}
	}
	label {
		&::before {
			border-radius: 0;
		}
	}
}
.table-bordered {
	td,
	th,
	tr {
		border-color: var(--light-gray);
	}
}
.pills-component {
	width: 20%;
}
.badge-table {
	.badge {
		display: inline-block;
		padding: 0.44em 0.7em;
		font-size: 0.75em;
		font-weight: 700;
		line-height: 1;
		color: var(--white);
		text-align: center;
		white-space: nowrap;
		vertical-align: baseline;
		border-radius: 0.25rem;
		font-family: Roboto, sans-serif;
	}
}
.label-square {
	i {
		margin-right: 7px;
	}
	span {
		font-size: 14px;
	}
}
.card-no-border {
	&.card-header {
		border-bottom: none;
	}
}
.table-striped {
	&.table-bordered {
		>tbody,
		>thead {
			>tr {
				>* {
					&:nth-child(n+2) {
						border-left: unset;
					}
				}
			}
		}
	}
	>tbody {
		>tr {
			&:nth-of-type(odd) {
				>* {
					background-color: rgba($light-color, 0.7);
					color: var(--body-font-color);
					--bs-table-accent-bg: unset;
				}
			}
		}
	}
}
.table-striped {
	.alert-light-primary {
		background-color: rgba($primary-color, 0.3) !important;
	}
}
.table-dashed {
	border-left: 2px dashed var(--light-gray) !important;
	border-right: 2px dashed var(--light-gray) !important;
	thead,
	tbody,
	tfoot,
	tr,
	td,
	th {
		border-style: dashed !important;
		border-width: 2px;
		border-right: 0;
		border-left: 0;
	}
}
#row_create {
	td.highlight {
		font-weight: 600;
		color: var(--theme-default);
	}
	td.danger {
		font-weight: 600;
		color: var(--danger-color);
	}
}
.basic_table {
	.table-responsive {
		.table {
			tbody {
				tr {
					td {
						&:nth-child(n+2) {
							@include media-breakpoint-down(xl) {
								min-width: 180px;
							}
						}
					}
				}
			}
		}
	}
}
.card-block {
	.table-responsive {
		.radio-first-col-width {
			tbody {
				tr {
					td {
						@include media-breakpoint-down(md) {
							min-width: 200px !important;
						}
					}
				}
			}
		}
	}
}
.card-block {
	.table-responsive {
		.checkbox-td-width {
			tbody {
				tr {
					td {
						@include media-breakpoint-down(md) {
							min-width: 200px !important;
						}
					}
				}
			}
		}
		.table-bordered {
			tbody {
				tr {
					&:last-child {
						td {
							padding: 0.5rem 0.5rem !important;
							border-bottom: 0;
						}
					}
					td {
						span {
							@include media-breakpoint-down(md) {
								overflow: hidden;
								text-overflow: ellipsis;
								white-space: nowrap;
								max-width: 100px;
							}
						}
					}
				}
			}
			td {
				vertical-align: middle;
			}
		}
	}
}
.signal-table {
	.table {
		&.table-hover {
			tbody {
				tr {
					td {
						&:nth-child(n+2) {
							@include media-breakpoint-down(xl) {
								min-width: 240px !important;
							}
						}
					}
				}
			}
		}
	}
}
div.dataTables_wrapper {
	div.dataTables_paginate {
		.paginate_button {
			@include media-breakpoint-down(sm) {
				padding: 1px 7px;
			}
		}
		.paginate_button.previous {
			&:before {
				@include media-breakpoint-down(sm) {
					content: "\e64a";
					font-family: themify;
					font-size: 11px;
					padding: 0 5px;
				}
			}
			@include media-breakpoint-down(sm) {
				font-size: 0;
				padding: 0;
			}
		}
		.paginate_button.next {
			&:before {
				@include media-breakpoint-down(sm) {
					content: "\e649";
					font-family: themify;
					font-size: 11px;
					padding: 0 5px;
				}
			}
			@include media-breakpoint-down(sm) {
				font-size: 0;
				padding: 0;
			}
		}
		@include media-breakpoint-down(md) {
			float: none;
			margin-top: 20px !important;
			margin-left: 0 !important;
		}
		@include media-breakpoint-down(sm) {
			margin-top: 15px !important;
		}
	}
}
.react-basic-table{
	.table{
		tbody{
			tr{
				&:last-child{
					td{
						border-bottom: none !important;
					}
					th{
						border-bottom: none !important;
					}
				}
			}
		}
	}
}
/**=====================
     39.Table CSS Ends
==========================**/