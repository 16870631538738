/**=====================
  4.3 Update CSS Start
==========================**/
.animate-img {
  .card {
    box-shadow: 0px 4px 15px rgba($black, 0.05);
  }
  .animate-widget {
    img {
      width: 100%;
      height: 350px;
      margin: 0 auto;
      object-fit: cover;
    }
  }
}
.modal-popup {
  &.modal {
    top: 20%;
    left: 50%;
    transform: translate(-50%, 0);
    .modal-dialog {
      .theme-close {
        width: calc(25px + (40 - 25) * ((100vw - 320px) / (1920 - 320)));
        height: calc(25px + (40 - 25) * ((100vw - 320px) / (1920 - 320)));
        font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (1920 - 320)));
      }
    }
  }
}
.animated-modal {
  .form-select {
    @media (max-width: 442px) {
      width: 100% !important;
    }
  }
}
.theme-close {
  opacity: 1;
  position: absolute;
  z-index: 1;
  right: 0;
  background-color: var(--white) !important;
}
.code-box-copy {
  width: 100%;
  overflow: auto;
}
code[class*="language-"] {
  text-shadow: none !important;
}
pre[class*="language-"] {
  text-shadow: none !important;
}
.tooltip-inner {
  background-color: var(--theme-default);
  color: $white;
}
.tooltip.bs-tooltip-top {
  .tooltip-arrow {
    &:before {
      border-top-color: var(--theme-default);
    }
  }
}
.tooltip.bs-tooltip-bottom {
  .tooltip-arrow {
    &:before {
      border-bottom-color: var(--theme-default);
    }
  }
}
.tooltip.bs-tooltip-end {
  .tooltip-arrow {
    &:before {
      border-right-color: var(--theme-default);
    }
  }
}
.tooltip.bs-tooltip-start {
  .tooltip-arrow {
    &:before {
      border-left-color: var(--theme-default);
    }
  }
}
.swal2-popup {
  background: var(--white) !important;
}
.swal2-range {
  background: var(--white) !important;
}
.swal2-checkbox {
  background: var(--white) !important;
}
.popover {
  z-index: 9999;
}
.btn-info {
  &:hover {
    color: $white;
  }
}
#chart-widget1,
#chart-widget2,
#chart-widget3 {
  margin-bottom: -16px;
}
.dark-card {
  .card-header {
    border-bottom: 1px solid #474545 !important;
    padding-bottom: 20px !important;
  }
  .card-footer {
    border-top: 1px solid #474545;
  }
}
.typography-table {
  .display-h1 {
    font-size: 30px;
  }
}
.btn-info:hover {
  color: $white !important;
}
.theme-form input[type="text"],
.theme-form input[type="email"],
.theme-form input[type="search"],
.theme-form input[type="password"],
.theme-form input[type="number"],
.theme-form input[type="tel"],
.theme-form input[type="date"],
.theme-form input[type="datetime-local"],
.theme-form input[type="time"],
.theme-form input[type="datetime-local"],
.theme-form input[type="month"],
.theme-form input[type="week"],
.theme-form input[type="url"],
.theme-form input[type="file"],
.theme-form textarea,
.theme-form select {
  border-color: var(--border-color);
  background-color: var(--white);
  font-size: 14px;
  padding: 12px 10px;
  box-shadow: none;
}
.dzu-dropzone {
  background-color: rgba($primary-color, 0.1);
  border: 2px dashed var(--theme-default);
  border-radius: 15px;
  min-height: 250px;
  overflow-x: hidden;
  .dzu-inputLabel {
    text-align: center;
    .dz-message {
      i {
        color: var(--theme-default);
        @media (min-width: 768px) {
          font-size: 50px;
        }
      }
      h6 {
        color: var(--body-font-color);
        font-size: 16px;
        font-weight: 500;
      }
      span {
        color: var(--body-font-color);
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
}
.react-tagsinput {
  background-color: var(--white);
  border-color: var(--border-color);
}
.react-tagsinput-tag {
  background-color: var(--theme-default);
  border: 1px solid var(--theme-default);
  color: $white;
  .react-tagsinput-remove {
    color: $white;
  }
}
.modal-header,
.modal-body,
.modal-footer {
  width: 100%;
}
.btn-close:focus {
  box-shadow: none;
}
.modal-header {
  .modal-title {
    font-size: 1.25rem !important;
    font-weight: 700;
    letter-spacing: 0.5px;
  }
}
.pagination-primary {
  .page-item {
    border-bottom: none !important;
    .page-link {
      color: var(--theme-default);
    }
  }
}
.email-body {
  .mail-pagination {
    .page-item {
      &.active {
        a {
          background-color: var(--theme-default);
          border-color: var(--theme-default);
          color: $white;
        }
      }
    }
  }
}
.msger-inputarea {
  .epr-main,
  .EmojiPickerReact {
    bottom: 70px;
    position: absolute;
    right: 45px;
    .epr-header {
      padding: 0;
      .epr-search-container {
        padding: 0;
      }
      .epr-skin-tones {
        padding: 0;
      }
    }
    .epr-emoji-category,
    .epr-emoji-category-content {
      padding: 0;
    }
  }
}
.form-bookmark {
  .form-color {
    padding: 1px 2px;
    width: 50px;
  }
}
.fc .fc-button-primary:not(:disabled).fc-button-active,
.fc .fc-button-primary:not(:disabled):active {
  background-color: var(--theme-default);
  border-color: var(--theme-default);
}
.fc {
  .fc-button-primary {
    background-color: var(--theme-default);
    border-color: var(--theme-default);
    &:disabled {
      background-color: var(--theme-default);
      border-color: var(--theme-default);
    }
    &:hover {
      background-color: var(--theme-default);
      border-color: var(--theme-default);
    }
  }
}
.fc .fc-button-primary:not(:disabled).fc-button-active:focus,
.fc .fc-button-primary:not(:disabled):active:focus {
  box-shadow: none;
}
.icons-accordion button {
  background-color: rgba($secondary-color, 0.2);
}
.text-dark {
  color: var(--body-font-color) !important;
}
.modal {
  .modal-body {
    .modal-padding-space {
      font-size: 14px;
      color: var(--font-color);
    }
  }
}
.large-modal-body {
  p {
    font-size: 14px;
    color: var(--font-color);
  }
}
.web-content {
  p {
    font-size: 14px;
    color: var(--font-color);
  }
}
.ui-dropdown {
  .dropdown {
    .dropdown-toggle {
      border-radius: 10px;
      padding: 5px 10px;
      text-align: left;
      width: 100%;
      color: $white;
      &:after {
        border-bottom: 0;
        border-left: 4px solid #0000;
        border-right: 4px solid #0000;
        border-top: 4px solid;
        content: "";
        display: inline-block;
        margin-left: 8px;
        position: unset;
        vertical-align: 0px;
      }
    }
    &.dropstart {
      button {
        &::after {
          display: none !important;
        }
      }
    }
  }
}
.dropdown-header {
  columns: var(--body-font-color);
}
.scrollbar-page {
  .list-group {
    img {
      height: 40px;
      width: 40px;
      margin-right: 10px;
    }
    .list-group-item {
      padding: 11px 16px;
      div {
        margin-right: auto;
        margin-left: 8px;
      }
    }
  }
}
.checkbox-icon {
  color: var(--theme-default);
}
.toast-rtl {
  .toast {
    .toast-header {
      strong {
        align-items: center;
        display: flex;
      }
    }
  }
}
.toast-body {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.pagination-secondary {
  .pagination {
    .page-item {
      .page-link {
        color: var(--theme-secondary) !important;
      }
    }
  }
}
.card {
  .card-header {
    &.border-b-info {
      border-bottom: 1px solid var(--info-color) !important;
    }
  }
}
.state-card {
  .list-group {
    i {
      margin-right: 10px;
    }
    .list-group-item {
      padding: 11px 16px;
      div {
        margin-right: auto;
        margin-left: 8px;
      }
    }
    img {
      height: 40px;
      width: 40px;
      margin-right: 10px;
    }
  }
}
.card-absolute {
  &.card {
    .card-header {
      padding-bottom: 10px !important;
    }
  }
}
.custom-fill-radio {
  .form-check-input {
    height: unset;
  }
}
.radio-checkbox-page {
  .form-label {
    font-weight: 400;
    font-size: 14px;
    line-height: 1.5;
  }
}
.date-picker-page {
  .react-datepicker-wrapper {
    width: 100%;
  }
  .rmdp-container {
    width: 100%;
  }
}
.rbt-input-multi {
  &.form-control {
    padding: 15px 10px;
  }
}
.rtl-typeahead {
  direction: rtl;
}
.vertical-main-wizard {
  .tab-content {
    .accordion {
      i {
        margin-left: auto;
      }
    }
  }
}
.vertical-main-wizard {
  .tab-content {
    .accordion {
      i {
        margin-left: auto;
      }
    }
  }
}
.ginger-module-highlighterginger-module-highlighter {
  background-color: var(--white);
}
.email-verify {
  .basic-form {
    span {
      border-radius: 0 6px 6px 0;
      [dir="rtl"] & {
        border-radius: 6px 0 0 6px !important;
      }
    }
  }
}
.checkbox-td-width {
  tbody {
    tr {
      td {
        .form-check-input {
          width: unset;
          height: unset;
          margin-top: 0;
          vertical-align: middle;
          appearance: auto;
        }
      }
    }
  }
}
.ace_print-margin {
  display: none;
}
.dzu-previewContainer {
  @media (max-width: 480px) {
    display: block;
  }
}

.bookmark-dropdown {
  .flip-card {
    width: 300px;
    height: 260px;
    background-color: transparent;

    .flip-card-inner {
      position: relative;
      height: 100%;
      text-align: center;
      transition: transform 0.6s;
      transform-style: preserve-3d;

      .front,
      .back {
        position: absolute;
        backface-visibility: hidden;
        border-radius: 5px;
        overflow: hidden;
        box-shadow: 0 0 20px rgba(89, 102, 122, 0.1);
        width: 300px;
        height: 260px;
      }

      .front {
        background-color: $white;
        transition: 0.3s;

        .bookmark-dropdown {
          li {
            &:last-child {
              padding-bottom: 15px;
            }

            .bookmark-content {
              text-align: center;

              .bookmark-icon {
                width: 52px;
                height: 52px;
                border-radius: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0 auto;
                background-color: rgba($primary-color, 0.1);

                svg {
                  stroke: var(--theme-default);
                }
              }

              span {
                font-family: $body-font;
                font-size: 14px;
                font-weight: 600;
                letter-spacing: 0.5px;
                margin-top: 6px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                width: 100%;
                color: var(--theme-default);
              }
            }
            .flip-btn {
              color: $white;
            }
          }
          ul {
            li {
              &:nth-child(n + 2) {
                border-top: none;
                padding: 12px;
              }
            }
          }
        }
      }

      .back {
        background-color: $white;
        transform: rotateY(180deg);

        ul {
          li {
            &:first-child {
              margin-top: 20px;
              height: 140px !important;
            }

            &:last-child {
              margin-top: 15px;
            }

            .flip-back-content {
              input {
                background-color: var(--light-color);
                border: none;
                border-radius: 0;
              }
              .ProfileCard {
                display: flex;
                align-items: center;
                .ProfileCard-details {
                  float: left;
                  padding-left: 35px;
                  width: 100%;
                  [dir="rtl"] & {
                    float: right;
                    padding-right: 35px;
                    padding-left: unset;
                  }
                  .ProfileCard-realName {
                    display: flex;
                    justify-content: space-between;
                    .pull-right {
                      margin-left: auto;
                      [dir="rtl"] & {
                        margin-right: auto;
                        margin-left: unset;
                      }
                    }
                  }
                }
              }
            }

            .custom-scrollbar {
              padding: 0;
              top: 36px;
              box-shadow: none;
              height: 115px;
              overflow: auto;
              min-height: 0 !important;
            }
          }
        }
      }

      &.flipped {
        transform: rotateY(180deg);

        .front {
          opacity: 0;
        }
      }
    }
  }

  .custom-scrollbar {
    height: 115px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .flip-btn {
    justify-content: center;
    font-family: $body-font;
  }
}
.custom-bookmarks {
  position: relative;
  z-index: 8;
}
.mark-all-tasks {
  .btn-label {
    &.text-success {
      & ~ .action-box {
        &.completed {
          background-color: var(--success-color) !important;
          border-color: var(--success-color) !important;
        }
      }
    }
  }
}
.validation-forms {
  .has-validation {
    .input-group-text {
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }
    input {
      border-top-left-radius: 0px !important;
      border-bottom-left-radius: 0px !important;
    }
  }
}
.card-header {
  code {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  }
}

/**=====================
    4.3 Update CSS Ends
==========================**/
