.simple-list {
  .list-group-item {
    padding: 0;
    border: none;
    background-color: transparent;
  }
}

/* scrollable css */
.custom-container {
  width: 100% !important;
  height: 300px !important;
}

.scroll-content-width {
  width: 1600px;
}

.add-upload-media {
  height: "0px";
  width: "0px";
  overflow: "hidden";
}

//leaflet-map
.custom-map {
  height: 500px;
  width: 100%;
}
/* range css */
.range-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 1em;
}

.range-track {
  height: 36px;
  display: flex;
  width: 100%;
}

.price-range-output {
  margin-top: 30px;
}

.price-range-thumb {
  height: 16px;
  width: 16px;
  border-radius: 60px;
  background-color: #26695c;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 6px #aaa;
}

.range-output {
  margin-top: 12px;
}

.range-thumb {
  height: 35px;
  width: 35px;
  border-radius: 30px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 6px #aaa;
}

.range-thumb-bar {
  height: 16px;
  width: 5px;
  background-color: #ccc;
}

.range-thumb-bar-dragged {
  background-color: var(--theme-default);
}

.range-track-bar {
  height: 5px;
  width: 100%;
  border-radius: 4px;
}

//rating
.rating {
  display: flex;
  align-items: center;
  gap: 10px;

  > span {
    display: inline-flex !important;
    align-items: center;
    gap: 0px;
  }

  .br-current {
    background-color: $primary-color !important;
    color: $white !important;
  }

  .square-number {
    display: block;
    width: 30px;
    height: 30px;
    float: left;
    border: 2px solid rgb(143 162 227);
    background-color: var(--white);
    margin: 2px;
    text-decoration: none;
    font-size: 14px;
    line-height: 1.8;
    text-align: center;
    color: rgb(143 162 227);
    font-weight: 600;

    &.active {
      border: 2px solid var(--theme-default) !important;
      color: var(--theme-default) !important;
    }
  }

  &.reverse {
    .square-number {
      width: 22px;
      height: 22px;
    }
  }

  &.pill-rating-list {
    .pill-rating {
      padding: 7px 15px;
      background-color: rgba($primary-color, 0.2);
      color: var(--theme-default);
      text-decoration: none;
      font-size: 13px;
      text-align: center;
      font-weight: 400;
    }

    > span {
      > span {
        &:first-of-type {
          .pill-rating {
            border-top-left-radius: 999px;
            border-bottom-left-radius: 999px;
          }
        }

        &:last-of-type {
          .pill-rating {
            border-top-right-radius: 999px;
            border-bottom-right-radius: 999px;
          }
        }
      }
    }
  }

  .current-rating {
    font-size: 20px;
    line-height: 1;
    color: var(--theme-default);
    font-weight: 400;
  }
}

//unlock-user
.unlockshp0 {
  fill: #ebebeb;
}
.unlockshp1 {
  fill: #263238;
}
.unlockshp2 {
  fill: #ffffff;
  opacity: 0.5;
}
.unlockshp3 {
  fill: #ffffff;
}
.unlockshp4 {
  fill: #308e87;
}
.unlockshp5 {
  opacity: 0.2;
}
.unlockshp6 {
  opacity: 0.3;
}
.unlockshp7 {
  fill: #f5f5f5;
}
.unlockshp8 {
  fill: #fafafa;
}
.unlockshp9 {
  fill: #e6e6e6;
}
.unlockshp10 {
  fill: #f0f0f0;
}
.unlockshp11 {
  fill: #ffc3bd;
}
.unlockshp12 {
  fill: #ed847e;
}
