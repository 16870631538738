/**=====================
    3.37 Social-app CSS start
==========================**/
.user-profile {
  .hovercard {
    .socialheader {
      background: url(../../images/social-app/social-image.png);
    }
    .user-image {
      position: relative;
      height: 0;
      width: auto;
      .share-icons {
        position: absolute;
        right: 100px;
        top: -27px;
        @include media-breakpoint-down(xl) {
          position: absolute;
          right: 45px;
          top: -22px;
        }
        @include media-breakpoint-down(md) {
          right: 25px;
          top: -19px;
        }
        @include media-breakpoint-down(sm) {
          right: 15px;
        }
        @media only screen and (max-width: 360px) {
          right: 6px;
          top: -15px;
        }
        li {
          display: inline-block;
          margin-right: 10px;
          @include media-breakpoint-down(sm) {
            margin-right: 0px;
          }
          .social-icon {
            width: 50px;
            height: 50px;
            text-align: center;
            display: flex;
            align-items: center;
            vertical-align: middle;
            justify-content: center;
            border-radius: 100%;
            @include media-breakpoint-down(xl) {
              width: 40px;
              height: 40px;
            }
            @include media-breakpoint-down(md) {
              width: 35px;
              height: 35px;
            }
            @media only screen and (max-width: 360px) {
              width: 25px;
              height: 25px;
            }
            i {
              color: var(--white);
              font-size: 25px;
              @include media-breakpoint-down(xl) {
                font-size: 18px;
              }
              @media only screen and (max-width: 360px) {
                font-size: 14px;
              }
            }
          }
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
  .card-footer {
    >div {
      h3 {
        font-size: 16px;
      }
    }
  }
  .default-according {
    .filter-cards-view {
      .latest-photos.common-flex {
        justify-content: center;
        li {
          border: none;
          display: inline-block;
          margin: 0;
          padding: 0;
          width: 70px;
        }
      }
      .common-flex {
        flex-direction: row !important;
        flex-wrap: wrap;
        li{
          border: none;
          padding: 0;
        }
      }
    }
  }
}
.market-tabs {
  .border-tab.nav-tabs .nav-item .nav-link.active {
    border-bottom: none !important;
    color: var(--theme-default) !important;
    background-color: transparent !important;
  }
  .nav {
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    .nav-item {
      width: 100%;
      .nav-link {
        height: 81px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        padding-top: 30px;
        text-align: center;
        font-weight: 800;
        @include media-breakpoint-down(sm) {
          padding-top: 0;
          height: 30px;
        }
      }
    }
  }
}
.tabs-scoial {
  border-bottom: none;
  margin-bottom: 20px !important;
  margin-top: 20px;
  @include media-breakpoint-down(sm) {
    position: relative;
    padding-top: 65px;
  }
  li {
    a {
      font-weight: 600;
      svg {
        circle {
          color: var(--light-text);
        }
      }
    }
    &:nth-child(3) {
      @include media-breakpoint-down(sm) {
        position: absolute;
        top: -40px;
        left: 0;
        right: 0;
        margin: 0 auto;
      }
    }
  }
}
.social-network {
  span {
    margin-bottom: 30px;
    display: block;
  }
}
.social-list {
  .d-flex {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    &:last-child {
      margin-bottom: 0;
    }
    .flex-grow-1 {
      a {
        color: var(--light-text);
      }
      span {
        font-weight: 700;
      }
      &:hover {
        a {
          color: var(--theme-default);
        }
      }
    }
  }
}
.photos {
  ul {
    li {
      display: inline-block;
      margin-right: 10px;
      margin-bottom: 10px;
      width: 70px;
      &:nth-child(4n) {
        margin-right: 0;
      }
    }
  }
}
.new-users-social {
  margin-bottom: 30px;
  svg {
    cursor: pointer;
  }
  .d-flex {
    img {
      width: 58px;
    }
    .flex-grow-1 {
      margin-top: 10px;
      h6 {
        font-weight: 800;
      }
    }
  }
  svg {
    circle {
      color: var(--light-text);
    }
  }
}
.like-content {
  span {
    i {
      font-size: 14px;
    }
  }
}
.timeline-content {
  p {
    margin-top: 30px;
    color: var(--light-text);
  }
  .comment-number {
    margin-right: 7px;
    i {
      color: var(--light-text);
      margin: 0px 4px;
      &.me-0{
        [dir="rtl"] & {
          margin-right: 4px !important;
        }
      }
    }
    [dir="rtl"] & {
      margin-right: unset;
      margin-left: 7px;
    }
  }
  .comments-box {
    margin-top: 30px;
    .input-group {
      margin-top: 6px;
      .input-txt-bx {
        border: none;
        background-color: rgba($gray-60, 0.2);
        border-radius: 0px;
        &::placeholder {
          color: var(--light-text);
        }
      }
      .input-group-append {
        .btn {
          i {
            font-size: 20px;
            display: flex;
            color: var(--light-text);
          }
        }
      }
    }
  }
}
.social-chat {
  margin-top: 30px;
  .text-center {
    a {
      color: var(--theme-default);
    }
  }
  span {
    span {
      font-weight: normal;
      color: var(--light-text);
      font-size: 12px;
    }
  }
  .flex-grow-1 {
    padding: 30px;
    border: 1px solid var(--border-color);
    position: relative;
    p {
      margin-top: 5px;
    }
    &:before {
      content: '';
      position: absolute;
      top: 19px;
      right: 100%;
      border: 7px solid transparent;
      border-right: 7px solid var(--black);
    }
    &:after {
      content: '';
      position: absolute;
      top: 19px;
      right: 100%;
      border: 7px solid transparent;
      border-right: 7px solid var(--white);
    }
  }
  .your-msg,
  .other-msg {
    margin-bottom: 30px;
  }
  .other-msg {
    margin-left: 40px;
  }
}
.socialprofile {
  span {
    color: var(--light-text);
  }
  .social-btngroup {
    margin: 30px 0;
  }
  .likes-profile {
    h5 {
      span {
        color: var(--body-font-color);
        font-weight: 800;
      }
    }
  }
  .social-group {
    margin-top: 30px;
    ul {
      li {
        &:nth-child(4) {
          margin-left: -22px;
        }
      }
    }
  }
}
.default-according {
  &.style-1 {
    .filter-cards-view {
      .social-network {
        .btn {
          i {
            margin: 0 8px;
          }
        }
      }
    }
  }
}
.social-status {
  form {
    .form-group {
      .form-control-social {
        border: 1px solid var(--light-color);
        padding-left: 15px;
        margin-bottom: 30px;
      }
    }
  }
  .d-flex {
    position: relative;
    margin-bottom: 30px;
    &:last-child {
      margin-bottom: 0;
    }
    .social-status {
      content: '';
      position: absolute;
      width: 12px;
      height: 12px;
      background-color: var(--white);
      bottom: 0;
      left: 35px;
      border-radius: 100%;
    }
    .social-online {
      border: 3px solid var(--success-color);
    }
    .social-busy {
      border: 3px solid var(--warning-color);
    }
    .social-offline {
      border: 3px solid rgba($gray-700, 0.8);
    }
    .flex-grow-1 {
      p {
        color: var(--light-text);
        margin-bottom: 5px;
      }
      span {
        +span {
          color: var(--light-text);
        }
      }
      .light-span {
        color: var(--light-text);
      }
    }
  }
}
.social-header {
  h5 {
    span {
      &:first-child {
        line-height: 1.48;
        font-size: 20px;
        font-weight: 700;
      }
      svg {
        circle {
          color: var(--light-text);
        }
      }
    }
  }
}
.details-about {
  +.details-about {
    margin-top: 30px;
  }
  .your-details {
    p {
      color: var(--light-text);
    }
  }
}
.social-app-profile {
  .tab-content {
    .tab-pane {
      .gallery-with-description {
        h4 {
          font-weight: 700;
          color: var(--body-font-color);
        }
        p {
          color: var(--font-color) !important;
        }
      }
    }
  }
}
.avatar-showcase {
  .friend-pic {
    margin-right: 8px;
    margin-bottom: 10px;
  }
  .pepole-knows {
    ul {
      li {
        display: inline-block;
        margin-right: 20px;
        margin-bottom: 10px;
        &:last-child {
          margin-right: 0;
        }
        .add-friend {
          h6 {
            margin-top: 3px;
          }
        }
      }
    }
  }
}
.activity-log {
  .my-activity {
    +.my-activity {
      margin-top: 30px;
    }
    p {
      margin-bottom: 5px;
      span {
        svg {
          width: 15px;
          height: 15px;
        }
      }
    }
  }
}
/**=====================
      3.37 Social-app CSS end
  ==========================**/