.blog-box {
    &.blog-shadow {
        &:before {
            box-shadow: inset 0px -200px 100px -13px rgba($black, 0.6);
            transition: all 0.3s ease;
            border-radius: 5px;
            content: "";
            position: absolute;
            height: 100%;
            top: 0;
            width: 100%;
            bottom: 0;
        }
        &:hover {
            &:before {
                transition: all 0.3s ease;
            }
        }
        .blog-details {
            position: absolute;
            bottom: 0;
            width: 100%;
            padding: 20px !important;
            h4 {
                color: $white;
                margin-bottom: 0;
            }
            p {
                font-size: 14px;
                color: $white;
                margin-bottom: 10px;
            }
            .blog-social {
                li {
                    color: $white;
                }
            }
        }
        .blog-details {
            @media (max-width : 576px) {
                padding: 15px;
            }
            @media (max-width : 576px) {
                h4 {
                    font-size: 14px;
                }
            }
            @media (max-width: 480px) {
                font-size: 14px;
            }
        }
    }
    .blog-details {
        padding-right: 20px;
        [dir="rtl"] & {
            padding-right: unset;
            padding-left: 20px;
        }
        .blog-social {
            margin-top: 30px;
            li {
                display: inline-block;
                font-size: 14px;
                color: var(--timline-dot-line);
                +li {
                    padding-left: 20px;
                    line-height: 1;
                    padding-right: 0;
                }
                svg {
                    padding-right: 5px;
                    font-weight: 300;
                }
                &:first-child {
                    border-right: 1px solid var(--border-color);
                    padding-right: 20px;
                    border-radius: 0;
                    [dir="rtl"] & {
                        border-right: unset;
                        border-left: 1px solid var(--border-color);
                        padding-right: unset;
                        padding-left: 20px;
                    }
                }
            }
            @media (max-width: 576px) {
                li {
                    &:first-child {
                        margin-right: 10px;
                        padding-right: 20px;
                    }
                }
            }
            @media (max-width: 360px) {
                li {
                    &:last-child {
                        padding-left: 0;
                    }
                }
            }
            svg {
                width: 19px;
            }
        }
        @media (max-width: 576px) {
            .blog-social {
                margin-top: 15px;
            }
        }
        h4 {
            font-size: 18px;
            font-weight: 600;
            line-height: 1.5;
            margin-top: 20px;
            @media (max-width: 576px) {
                margin-top: 15px;
            }
        }
        h3 {
            text-overflow: ellipsis;
            overflow: hidden;
            -webkit-line-clamp: 1;
            display: -webkit-box;
            -webkit-box-orient: vertical;
        }
        @media (max-width: 576px) {
            padding: 20px !important;
        }
        .single-blog-content-top {
            margin-top: 20px;
            border-top: 1px solid var(--light-semi-gray);
        }
        p {
            font-weight: 600;
            font-size: 14px;
            line-height: 1.8;
            letter-spacing: 0.7px;
            margin-top: 30px;
            margin-bottom: 0;
            color: var(--timline-dot-line);
            text-overflow: ellipsis;
            overflow: hidden;
            -webkit-line-clamp: 3;
            display: -webkit-box;
            -webkit-box-orient: vertical;
        }
    }
    .blog-date {
        color: var(--body-font-color);
        span {
            font-size: 36px;
            font-weight: 500;
            padding-right: 5px;
        }
    }
    h6 {
        margin: 15px 0;
    }
    img {
        border-radius: 0.25rem;
    }
    .blog-details-main {
        .blog-social {
            margin: 20px 0;
            justify-content: center;
            li {
                display: inline-block;
                font-size: 12px;
                color: var(--timline-dot-line);
                padding: 0 24px;
                border-right: 1px dotted;
                border-radius: 0;
                &:last-child {
                    border-right: 0;
                }
                @media (max-width: 360px) {
                    padding: 0 10px;
                }
            }
        }
    }
    .blog-bottom-details {
        margin: 20px;
        font-size: 14px;
        line-height: 1.714;
        font-weight: 600;
    }
    @media (max-width :575px) {
        &.blog-list {
            .blog-details {
                padding-top: 0;
            }
        }
    }
}
.blog-list {
    .blog-details {
        h3 {
            margin-top: 0;
            color: var(--gray-60);
            margin-bottom: 7px;
        }
        .blog-social {
            margin-top: 0;
            align-items: center;
        }
        hr {
            margin-top: 10px;
            margin-bottom: 10px;
        }
        p {
            font-size: 14px;
            line-height: 1.8;
            letter-spacing: 0.7px;
            margin-top: 30px;
            margin-bottom: 0;
            color: var(--timline-dot-line);
            text-overflow: ellipsis;
            overflow: hidden;
            -webkit-line-clamp: 3;
            display: -webkit-box;
            -webkit-box-orient: vertical;
        }
    }
    img {
        padding: 17px;
    }
}
.comment-box {
    padding-top: 30px;
    padding-bottom: 50px;
    .d-flex {
        img {
            margin-right: 30px;
            border-radius: 10px;
            height: 90px;
            padding: 7px;
            width: 90px;
            border: 1px solid var(--light-semi-gray);
        }
        h6 {
            font-weight: 600;
            margin-bottom: 5px;
        }
    }
    .comment-social {
        li {
            display: inline-block;
            color: var(--font-color);
            padding-left: 20px;
            margin-bottom: 0;
            text-transform: uppercase;
            @include media-breakpoint-down(lg) {
                padding-left: 15px;
            }
            &:first-child {
                border-right: 1px solid var(--border-color);
                padding-right: 20px;
                border-radius: 0;
                [dir="rtl"] & {
                    border-right: unset;
                    border-left: 1px solid var(--border-color);
                    padding-right: unset;
                    padding-left: 20px;
                }
                @include media-breakpoint-down(md) {
                    padding-left: 0;
                    padding-right: 15px;
                    [dir="rtl"] & {
                        padding-right: 0;
                        padding-left: 15px;
                    }
                }
            }
        }
        @include media-breakpoint-down(md) {
            margin-bottom: 5px;
        }
        @include media-breakpoint-down(xl) {
            margin-left: 0;
        }
        @include media-breakpoint-down(md) {
            margin-bottom: 5px;
        }
        @include media-breakpoint-down(xl) {
            margin-left: 0;
        }
    }
    ul {
        ul {
            margin-left: 135px;
        }
    }
    li {
        margin-bottom: 30px;
        &:last-child {
            margin-bottom: 0;
        }
        i {
            padding-right: 10px;
        }
    }
    p {
        font-size: 14px;
        line-height: 2;
        color: var(--gray-600);
        text-align: justify;
        margin-bottom: 0;
    }
}
.learning-details {
    .comment-box {
        .comment-social {
            li {
                svg {
                    padding-right: 10px;
                }
            }
        }
    }
}
.learning-card {
    .blog-box {
        position: relative;
        max-height: 100%;
        align-items: center;
        .blog-details {
            h5 {
                text-overflow: ellipsis;
                overflow: hidden;
                -webkit-line-clamp: 1;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                margin-top: 0;
                color: var(--font-color);
                font-weight: 500;
            }
            @media (max-width: 1570px) {
                p {
                    -webkit-line-clamp: 2;
                }
            }
        }
        .top-radius-blog {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
    }
    .filter-cards-view {
        .job-filter {
            &:first-child {
                margin-bottom: 30px;
            }
        }
    }
    .job-filter {
        .faq-form {
            .form-control {
                font-size: 14px;
            }
            .search-icon {
                width: 16px;
                height: 13px;
                top: 50%;
                transform: translate(0, -50%);
            }
        }
    }
    .accordion-button {
        &:not(.collapsed) {
            background-color: transparent;
            box-shadow: unset;
        }
    }
    .categories {
        padding: 0 0 30px 0;
        ul {
            li {
                padding: 10px 15px;
                &:hover {
                    background-color: var(--categorie-hover);
                }
            }
        }
        .learning-header {
            padding-left: 15px;
            span {
                font-weight: 700;
            }
        }
    }
    .card-header {
        border-radius: 1.5rem !important;
    }
    .blog-box {
        .blog-details-main {
            .blog-social {
                @media (max-width: 1676px) {
                    li {
                        padding: 0 10px;
                    }
                }
            }
        }
    }
    &.default-according {
        .card {
            .card-header {
                i {
                    position: unset;
                    left: unset;
                    font-size: unset;
                    top: unset;
                }
            }
        }
    }
}
@media screen and (min-width: 1200px) and (max-width: 1366px) {
    .learning-comment {
        margin-left: -14px !important;
        float: left !important;
    }
}
@media (max-width: 1199.98px) {
    .comment-box {
        .comment-social {
            margin-left: 0;
        }
    }
}
@media (max-width: 767.98px) {
    .comment-box {
        .comment-social {
            li {
                &:first-child {
                    padding-left: 0;
                    padding-right: 15px;
                }
            }
        }
        .d-flex {
            h5 {
                margin-bottom: 10px;
            }
        }
    }
    .blog-box {
        .blog-details {
            .blog-social {
                li {
                    + {
                        li {
                            padding-left: 25px;
                        }
                    }
                }
            }
        }
    }
}
@media (max-width: 575.98px) {
    .comment-box {
        .d-flex {
            display: block !important;
        }
        ul {
            ul {
                margin-left: 30px;
            }
        }
    }
    .comment-box {
        .d-flex {
            img {
                height: 50px;
                width: 50px;
                padding: 3px;
                margin-bottom: 10px;
            }
        }
    }
    .blog-single {
        .blog-box {
            .blog-details {
                padding: 0;
            }
        }
    }
    .blog-box {
        .blog-details {
            .blog-social {
                li {
                    +li {
                        padding-left: 20px;
                        >span {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 1660px) and (min-width: 1200px) {
    .xl-60 {
        max-width: 60%;
        flex: 0 0 60%;
    }
    .xl-50 {
        max-width: 50%;
        flex: 0 0 50%;
    }
    .xl-100 {
        max-width: 100%;
        flex: 0 0 100%;
    }
    .xl-23 {
        max-width: 23%;
        flex: 0 0 23%;
    }
    .xl-25 {
        max-width: 25%;
        flex: 0 0 25%;
    }
    .xl-40 {
        max-width: 40%;
        flex: 0 0 40%;
    }
    .xl-4 {
        max-width: 33.33%;
        flex: 0 0 33.33%;
    }
}
.learning-header {
    margin-bottom: 10px;
}
.upcoming-course {
    .d-flex {
        + {
            .d-flex {
                margin-top: 30px;
            }
        }
    }
}
.filter-cards-view {
    .form-check-input {
        &:focus {
            border-color: var(--theme-default) !important;
        }
        margin-right: 5px;
    }
}
.blog-box {
    .blog-details {
        .blog-social {
            li {
                i {
                    padding-right: 5px;
                    font-weight: 300;
                }
            }
        }
    }
}
.blog-box {
    position: relative;
    max-height: 100%;
    align-items: center;
    &.blog-list {
        .blog-bottom-content {
            @media screen and (max-width: 1366px) and (min-width: 1200px) {
                display: none;
            }
        }
    }
}
.blog-box {
    .blog-details {
        p {
            @media only screen and (max-width: 360px) {
                line-height: 1.5;
            }
            @media (max-width: 575.98px) {
                margin-top: 15px;
            }
            @media (max-width: 1570px) {
                -webkit-line-clamp: 2;
            }
        }
    }
}