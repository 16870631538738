/**=====================
  2.33 Tour CSS Start
==========================**/
.introjs-helperLayer {
    background-color: rgba($white, 0.9);
    border: 1px solid rgba($black, 0.5);
    box-shadow: 0 2px 15px rgba($black, 0.4);
}
.introjs-tooltipReferenceLayer {
    background-color: transparent;
}
.introjs-helperNumberLayer {
    color: var(--white);
    text-shadow: 1px 1px 1px rgba($black, 0.3);
    background: var(--danger-color);
    background: linear-gradient(to bottom, var(--theme-default) 0%, var(--theme-default) 100%);
    border: 3px solid var(--white);
    box-shadow: 0 2px 5px rgba($black, 0.4);
}
.introjs-arrow {
    border: 12px solid var(--white);
    &.top {
        border-top-color: transparent;
        border-right-color: transparent;
        border-bottom-color: var(--theme-default);
        border-left-color: transparent;
    }
    &.top-right {
        border-top-color: transparent;
        border-right-color: transparent;
        border-bottom-color: var(--theme-default);
        border-left-color: transparent;
    }
    &.top-middle {
        border-top-color: transparent;
        border-right-color: transparent;
        border-bottom-color: var(--theme-default);
        border-left-color: transparent;
    }
    &.right {
        border-top-color: transparent;
        border-right-color: transparent;
        border-bottom-color: transparent;
        border-left-color: var(--theme-default);
    }
    &.right-bottom {
        border-top-color: transparent;
        border-right-color: transparent;
        border-bottom-color: transparent;
        border-left-color: var(--theme-default);
    }
    &.bottom {
        border-top-color: var(--theme-default);
        border-right-color: transparent;
        border-bottom-color: transparent;
        border-left-color: transparent;
    }
    &.left {
        border-top-color: transparent;
        border-right-color: var(--theme-default);
        border-bottom-color: transparent;
        border-left-color: transparent;
    }
    &.left-bottom {
        border-top-color: transparent;
        border-right-color: var(--theme-default);
        border-bottom-color: transparent;
        border-left-color: transparent;
    }
}
.introjs-tooltip {
    background-color: var(--theme-default);
    box-shadow: 0 1px 10px rgba($black, 0.4);
    color: var(--white);
    border-color: var(--theme-default);
    min-width: calc(275px + (310 - 275) * ((100vw - 320px) / (1920 - 320)));
    max-width: 400px;
    min-height: 90px;
}
.introjs-button {
    border: 1px solid var(--light-semi-gray);
    text-shadow: 1px 1px 0 var(--white);
    color: var(--body-font-color) !important;
    background-color: var(--white);
    background-image: linear-gradient(var(--white), var(--white));
    font-size: 13px;
    &:hover {
        border-color: var(--light-semi-gray);
    }
    &:focus,
    &:active {
        background-image: linear-gradient(var(--light-gray), var(--light-semi-gray));
    }
}
.introjs-skipbutton {
    color: var(--theme-default);
}
.introjs-prevbutton {
    &.introjs-fullbutton {
        border: 1px solid var(--light-semi-gray);
    }
}
.introjs-disabled {
    color: var(--theme-default);
    border-color: var(--light-semi-gray);
    &:hover,
    &:focus {
        color: var(--theme-default);
        border-color: var(--light-semi-gray);
    }
}
.introjs-bullets {
    ul {
        li {
            a {
                background: var(--light-gray);
                &:hover {
                    background: var(--white);
                }
                &.active {
                    background: var(--white);
                }
            }
        }
    }
}
.introjs-progress {
    background-color: var(--light-color);
}
.introjs-progressbar {
    background-color: var(--theme-default);
}
.introjs-hint:hover>.introjs-hint-pulse {
    border: 5px solid var(--gray-60);
}
.introjs-hint-pulse {
    border: 5px solid var(--gray-60);
    background-color: var(--gray-60);
}
.introjs-hint-dot {
    border: 10px solid var(--gray-60);
}
.flex-grow-1 {
    .tour-wrapper {
        display: flex;
        align-items: center;
        gap: 8px;
        .tour-dot {
            font-size: 5px;
        }
    }
}
.tour-blog {
    height: 350px;
    @media (max-width:992px) {
        height: calc(200px + (350 - 200) * ((100vw - 320px) / (992 - 320)));
    }
    img {
        height: 100%;
        object-fit: cover;
    }
}
.info {
    h6 {
        font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
    }
    span {
        font-size: calc(13px + (14 - 13) * ((100vw - 320px) / (1920 - 320)));
    }
}
.tour-email {
    display: flex;
    flex-direction: column;
    @media (max-width:576px) {
        align-items: center;
    }
}
.block-ellipsis {
    display: block;
    display: -webkit-box;
    max-width: 100%;
    margin: 0 auto;
    font-size: 14px;
    line-height: 26px !important;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.profile-img-style {
    h5 {
        line-height: 25px;
    }
}
.list-inline {
    .list-inline-item {
        &.b-r-gray {
            border-right: 1px solid var(--gray-60);
            border-radius: 0;
        }
        i{
            margin-right: 5px;
        }
    }
}
.social-tour {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 26px;
    .list-inline {
        display: flex;
        justify-content: center;
        gap: calc(12px + (26 - 12) * ((100vw - 320px) / (1920 - 320)));
        .list-inline-item {
            margin-right: 0;
            a {
                padding: 0px;
                i {
                    font-size: 18px;
                }
            }
        }
        @media (max-width: 599px) {
            display: none;
        }
    }
    .float-sm-end {
        min-width: 81px;
    }
}
.tour-mb-space {
    margin-bottom: calc(2px + (8 - 2) * ((100vw - 320px) / (1920 - 320)));
}
.like-comment {
    .list-inline {
        justify-content: flex-start;
        @media (max-width:324px) {
            flex-wrap: wrap;
            justify-content: flex-start;
            gap: 8px;
        }
    }
}
.reactour__popover {
    background-color: var(--theme-default) !important;
    color: $white !important;
    span {
		background-color: var(--theme-default) !important;
		border: 1px solid $white !important;
		border-radius: 100%!important;
		height: auto!important;
		padding: 0 10px!important;
	}
    .reactour__close-button {
		color: $white !important;
		height: 12px;
		right: -18px!important;
		top: -18px!important;
		width: 12px;
	}
}
/**=====================
    2.33 Tour CSS Ends
  ==========================**/