/**=====================
    General css start
==========================**/
.data-basic-map {
    height: 340px;
    width: 100%;
    @include media-breakpoint-down(xl) {
        height: 320px;
    }
    @include media-breakpoint-down(md) {
        height: 300px;
    }
    @include media-breakpoint-down(sm) {
        height: 260px;
    }
}
.choropleth-map {
    height: 340px;
    width: 100%;
    @include media-breakpoint-down(xl) {
        height: 320px;
    }
    @include media-breakpoint-down(md) {
        height: 300px;
    }
    @include media-breakpoint-down(sm) {
        height: 260px;
    }
}
.data-map {
    height: 340px;
    width: 100%;
    @include media-breakpoint-down(xl) {
        height: 320px;
    }
    @include media-breakpoint-down(md) {
        height: 300px;
    }
    @include media-breakpoint-down(sm) {
        height: 260px;
    }
}
.data-map-glob {
    height: 580px;
    width: 100%;
    svg {
        margin-top: -3%;
        @include media-breakpoint-down(md) {
            margin-top: 0;
        }
    }
    @include media-breakpoint-down(xl) {
        height: 400px;
    }
    @include media-breakpoint-down(md) {
        height: 360px;
    }
    @include media-breakpoint-down(sm) {
        height: 280px;
    }
}
.datamap {
    path {
        &:not(.datamaps-arc) {
            stroke: var(--light-gray) !important;
        }
    }
}
/**=====================
    General css end
==========================**/