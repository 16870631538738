.maintanance {
  .shp0 {
    fill: #308e87;
  }
  .shp1 {
    opacity: 0.2;
  }
  .shp2 {
    fill: #263238;
  }
  .shp3 {
    fill: #ffffff;
  }
  .shp4 {
    fill: none;
  }
  .shp5 {
    fill: #e6e6e6;
  }
  .shp6 {
    fill: #455a64;
  }
  .shp7 {
    fill: #ffa8a7;
  }
  .shp8 {
    fill: #ffffff;
    opacity: 0.2;
  }
  .shp9 {
    fill: #37474f;
  }
  .shp10 {
    fill: #ffa8a7;
  }
  .shp11 {
    opacity: 0.3;
  }
  .shp12 {
    fill: #692b2b;
  }
  .shp13 {
    opacity: 0.1;
  }
  .shp14 {
    fill: #f28f8f;
  }
  .shp15 {
    fill: #f5f5f5;
  }
  .shp16 {
    fill: #ffffff;
  }
  .shp17 {
    fill: #fafafa;
  }
  .shp18 {
    fill: #ebebeb;
  }
  .shp19 {
    fill: #ffffff;
    opacity: 0.3;
  }
}
