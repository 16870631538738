footer {
    background-color: var(--white);
    padding: 19px 15px;
    bottom: 0;
    left: 0;
    p {
        font-size: 14px;
        font-weight: 600;
        .footer-icon {
            width: 19px;
            height: 19px;
            stroke: var(--footer-icon);
            fill: var(--footer-icon);
        }
    }
    @include media-breakpoint-down(md) {
        padding: 14px;
        .row {
            p {
                text-align: center;
                &.float-end {
                    float: unset !important;
                }
            }
        }
    }
    @include media-breakpoint-down(sm) {
        p {
            font-size: 12px;
        }
    }
}
.page {
    &-wrapper {
        &.sidebar-close {
            margin-left: 0;
            transition: 0.5s all;
            .footer {
                left: -($sidebar-width + 10px);
                transition: 0.5s all;
            }
        }
    }
}