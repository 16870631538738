.radio-wrapper {
    li {
        .form-check-input {
            &:checked[type=radio] {
                background-image: none;
                box-shadow: unset;
            }
        }
    }
}
.form-check-reverse {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    padding: 0;
    .form-check-input {
        float: left;
        margin-right: 0;
        margin-left: 10px;
    }
}
.custom-checkbox {
    .checkbox {
        label {
            display: inline-block;
            position: relative;
            padding-left: 16px;
            cursor: pointer;
            margin-top: 10px;
            margin-bottom: 10px;
            transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
            &::before {
                font-family: "Font Awesome 5 Free";
                font-weight: 900;
                content: "\f00c";
                display: inline-block;
                position: absolute;
                text-align: center;
                width: 19px;
                height: 19px;
                left: 0;
                margin-left: -16px;
                border: 1px solid var(--light-color);
                color: transparent;
                border-radius: 3px;
                transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
            }
        }
        .form-check-input {
            &:checked {
                &.primary {
                    ~label {
                        &::before {
                            border: 1px solid var(--theme-default);
                            color: var(--theme-default);
                        }
                    }
                }
                &.secondary {
                    ~label {
                        &::before {
                            border: 1px solid var(--theme-secondary);
                            color: var(--theme-secondary);
                        }
                    }
                }
                &.success {
                    ~label {
                        &::before {
                            border: 1px solid var(--success-color);
                            color: var(--success-color);
                        }
                    }
                }
                &.warning {
                    ~label {
                        &::before {
                            border: 1px solid var(--warning-color);
                            color: var(--warning-color);
                        }
                    }
                }
            }
        }
        input[type=checkbox] {
            opacity: 0;
        }
    }
    .checkbox-primary {
        label {
            &::before {
                border-color: var(--theme-default);
            }
        }
        input[type=checkbox] {
            &:checked {
                + {
                    label {
                        &::before {
                            border-color: var(--theme-default);
                            color: var(--theme-default);
                        }
                    }
                }
            }
        }
    }
    .checkbox-secondary {
        label {
            &::before {
                border-color: var(--theme-secondary);
            }
        }
    }
    .checkbox-success {
        label {
            &::before {
                border-color: var(--success-color);
            }
        }
    }
    .checkbox-warning {
        label {
            &::before {
                border-color: var(--warning-color);
            }
        }
    }
}
.main-icon-checkbox {
    &.checkbox-primary {
        .form-check-input {
            border: 1px solid var(--gray-60);
        }
        input[type=checkbox] {
            opacity: 1;
        }
    }
    .form-check-input {
        &:checked[type=checkbox] {
            background-image: unset;
        }
    }
    .checkbox-shadow {
        box-shadow: none;
    }
    &.checkbox {
        label {
            &::before {
                content: "";
                width: 100%;
                height: 100%;
                margin-left: 1px;
                background-color: transparent;
                top: 0;
                display: block;
            }
        }
    }
}
.checkbox-wrapper,
.radio-wrapper {
    display: flex;
    gap: calc(8px + 8 * (100vw - 320px) / 1600);
    flex-wrap: wrap;
    justify-content: center;
    li {
        position: relative;
        width: 100%;
        padding: 0;
        border: none;
        text-align: center;
        display: grid;
        place-items: center;
        width: 100px;
        height: 52px;
        .form-check-label {
            i {
                font-size: 18px;
                padding-right: 8px;
            }
        }
    }
    .form-check-input {
        position: absolute;
        width: 100%;
        height: 100%;
        float: none;
        margin: 0;
        top: 0;
        left: 0;
        border-radius: 0;
        background-color: transparent;
    }
    li {
        .form-check-label {
            position: unset;
            padding: 0;
            margin-bottom: 0;
        }
        .form-check-input {
            &:checked {
                border: 2px solid var(--theme-default);
                background-color: transparent;
            }
        }
    }
}
.filled-checkbox {
    .checkbox {
        label {
            display: inline-block;
            position: relative;
            padding-left: 16px;
            cursor: pointer;
            margin-top: 10px;
            margin-bottom: 10px;
            transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
            &::before {
                font-family: "Font Awesome 5 Free";
                font-weight: 900;
                content: "\f00c";
                display: inline-block;
                position: absolute;
                text-align: center;
                width: 19px;
                height: 19px;
                left: 0;
                margin-left: -16px;
                border: 1px solid #efefef;
                border-radius: 3px;
                background-color: var(--white);
                transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
            }
        }
        .form-check-input {
            opacity: 0;
        }
    }
    .checkbox-solid-warning {
        label {
            &:before {
                background-color: var(--warning-color);
                border-color: var(--warning-color);
                color: var(--white);
            }
        }
    }
    .checkbox-solid-danger {
        label {
            &:before {
                background-color: var(--danger-color);
                border-color: var(--danger-color);
                color: var(--white);
            }
        }
    }
    .checkbox-solid-info {
        label {
            &:before {
                background-color: var(--info-color);
                border-color: var(--info-color);
                color: var(--white);
            }
        }
    }
}
.main-img-checkbox {
    .img-checkbox {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            width: 100%;
            height: 220px;
            object-fit: cover;
        }
        .form-check-label {
            width: 100%;
        }
    }
    .main-img-cover {
        position: absolute;
        top: 15px;
        left: 15px;
        width: 24px;
        height: 24px;
    }
}
.custom-radio {
    .radio {
        label {
            &::before {
                content: "";
                display: inline-block;
                position: absolute;
                width: 19px;
                height: 19px;
                left: 0;
                margin-left: -20px;
                border: 1px solid #efefef;
                border-radius: 50%;
                background-color: var(--white);
                transition: border 0.15s ease-in-out;
            }
            &::after {
                display: inline-block;
                position: absolute;
                content: " ";
                width: 9px;
                height: 9px;
                left: 5px;
                top: 5px;
                margin-left: -20px;
                border-radius: 50%;
                background-color: #898989;
                transform: scale(0, 0);
                transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
            }
        }
    }
    .radio-secondary {
        input[type=radio] {
            &:checked {
                + {
                    label {
                        &::before {
                            border-color: var(--theme-secondary);
                        }
                        &::after {
                            background-color: var(--theme-secondary);
                        }
                    }
                }
            }
        }
        input[type=radio] {
            + {
                label {
                    &::before {
                        border-color: var(--theme-secondary);
                    }
                }
            }
        }
    }
    .radio {
        input[type=radio] {
            opacity: 0;
        }
        label {
            display: inline-block;
            position: relative;
            padding-left: 10px;
            cursor: pointer;
        }
        input[type=radio] {
            &:checked {
                + {
                    label {
                        &::after {
                            transform: scale(1, 1);
                        }
                    }
                }
            }
        }
    }
    .radio-success {
        input[type=radio] {
            + {
                label {
                    &::before {
                        border-color: var(--success-color);
                    }
                }
            }
        }
        input[type=radio] {
            &:checked {
                + {
                    label {
                        &::before {
                            border-color: var(--success-color);
                        }
                        &::after {
                            background-color: var(--success-color);
                        }
                    }
                }
            }
        }
    }
    .radio-danger {
        input[type=radio] {
            + {
                label {
                    &::before {
                        border-color: var(--danger-color);
                    }
                }
            }
        }
        input[type=radio] {
            &:checked {
                + {
                    label {
                        &::before {
                            border-color: var(--danger-color);
                        }
                        &::after {
                            background-color: var(--danger-color);
                        }
                    }
                }
            }
        }
    }
    .radio-info {
        input[type=radio] {
            + {
                label {
                    &::before {
                        border-color: var(--info-color);
                    }
                }
            }
        }
    }
    input[type=radio] {
        &:checked {
            + {
                label {
                    &::before {
                        border-color: var(--info-color);
                    }
                    &::after {
                        background-color: var(--info-color);
                    }
                }
            }
        }
    }
}
.radio-wrapper {
    li {
        .form-check-label {
            span {
                font-size: 16px;
            }
        }
    }
}
.icon-radio {
    .form-check-input {
        &:checked[type=radio] {
            background-image: unset;
        }
    }
}
.radio_animated {
    position: relative;
    margin: 0 1rem 0 0;
    cursor: pointer;
    &:before {
        transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
        transform: scale(0, 0);
        content: "";
        position: absolute;
        top: 0;
        left: 0.125rem;
        z-index: 1;
        width: 0.75rem;
        height: 0.75rem;
        background: var(--theme-default);
        border-radius: 50%;
    }
    &:checked {
        &:before {
            transform: scale(1, 1);
        }
    }
    &:after {
        content: "";
        position: absolute;
        top: -0.25rem;
        left: -0.125rem;
        width: 1.25rem;
        height: 1.25rem;
        background: var(--white);
        border: 2px solid #ecf3fa;
        border-radius: 50%;
    }
}
.animated-button {
    label {
        margin-bottom: 0.5rem;
    }
}
.checkbox_animated {
    cursor: pointer;
    position: relative;
    margin: 0 1rem 0 0;
    &:before {
        transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
        transform: rotate(-45deg) scale(0, 0);
        content: "";
        position: absolute;
        left: 0.25rem;
        top: 0.225rem;
        z-index: 1;
        width: 0.75rem;
        height: 0.375rem;
        border: 2px solid var(--theme-default);
        border-top-style: none;
        border-right-style: none;
    }
    &:after {
        content: "";
        position: absolute;
        top: -0.125rem;
        left: 0;
        width: 1.3rem;
        height: 1.3rem;
        background: var(--white);
        border: 2px solid #ecf3fa;
        cursor: pointer;
    }
    &:checked {
        &:before {
            transform: rotate(-45deg) scale(1, 1);
        }
    }
}
.checkbox-radio {
    .checkbox {
        label {
            display: inline-block;
            position: relative;
            padding-left: 16px;
            cursor: pointer;
        }
    }
    .checkbox {
        input[type=checkbox] {
            opacity: 0;
        }
        label {
            &::before {
                content: "";
                display: inline-block;
                position: absolute;
                width: 19px;
                height: 19px;
                left: 0;
                margin-left: -16px;
                border: 1px solid #efefef;
                border-radius: 3px;
                background-color: var(--white);
                transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
            }
        }
    }
    .checkbox-dark {
        label {
            &::before {
                border-color: var(--dark);
            }
        }
        input[type=checkbox] {
            &:checked {
                + {
                    label {
                        &::before {
                            border-color: var(--dark);
                            color: var(--dark);
                        }
                    }
                }
            }
        }
    }
}
.simple-radios {
    .radio-primary {
        input[type=radio] {
            &:checked {
                + {
                    label {
                        &::before {
                            border-color: var(--theme-default);
                        }
                    }
                }
            }
        }
    }
    .radio {
        label {
            display: inline-block;
            position: relative;
            padding-left: 5px;
            cursor: pointer;
            &::before {
                content: "";
                display: inline-block;
                position: absolute;
                width: 19px;
                height: 19px;
                left: 0;
                margin-left: -22px;
                border: 1px solid #efefef;
                border-radius: 50%;
                background-color: var(--white);
                transition: border 0.15s ease-in-out;
            }
            &::after {
                display: inline-block;
                position: absolute;
                content: " ";
                width: 9px;
                height: 9px;
                left: 3px;
                top: 5px;
                margin-left: -20px;
                border-radius: 50%;
                background-color: #898989;
                transform: scale(0, 0);
                transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
            }
        }
        input[type=radio] {
            &:checked {
                + {
                    label {
                        &::after {
                            transform: scale(1, 1);
                        }
                    }
                }
            }
        }
    }
    .radio-primary {
        input[type=radio] {
            &:checked {
                + {
                    label {
                        &::after {
                            background-color: var(--theme-default);
                        }
                    }
                }
            }
        }
    }
    .form-check-size {
        .form-check-inline {
            label {
                margin: 0;
            }
        }
        .form-check-input {
            width: 18px;
            height: 18px;
            margin-top: 0;
        }
        .form-check-inline {
            label {
                margin: 0;
            }
        }
    }
    input[type=checkbox] {
        opacity: 0;
    }
}
.main-radio-toggle {
    input {
        .list-light-secondary {
            border: none !important;
            &:hover {
                background-color: rgba($secondary-color, 0.2) !important;
            }
        }
        &:checked {
            ~.list-light-secondary {
                background-color: rgba($secondary-color, 0.2) !important;
                border: 3px solid rgba($secondary-color, 0.2) !important;
            }
        }
    }
}
.outline-chackbox {
    .btn {
        &.btn-outline-info {
            &:hover {
                color: var(--white);
            }
        }
    }
}
.input-group-wrapper {
    .input-group-text {
        display: flex;
        align-items: center;
        padding: 0.375rem 0.75rem;
        font-weight: 400;
        line-height: 1.5;
        color: #212529;
        text-align: center;
        white-space: nowrap;
        background-color: #e9ecef;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
    }
}
.main-custom-form {
    .input-group {
        .btn {
            &.btn-outline-secondary {
                padding-bottom: 0;
            }
        }
    }
}
.custom-input-file {
    .input-group {
        .form-control {
            line-height: 1.7;
            border: 1px solid var(--light-gray);
        }
    }
    .btn {
        &.btn-outline-success {
            padding: 0.375rem 1.75rem;
        }
    }
}
.main-segment-btn {
    .dropdown-toggle {
        padding-bottom: 0;
        &::after {
            content: '';
            margin-left: 0;
            border-top: 6px solid;
            border-right: 6px solid transparent;
            border-left: 6px solid transparent;
            vertical-align: 2px;
            color: var(--white);
        }
    }
    .dropdown-menu {
        z-index: 3;
    }
    .btn {
        &.btn-outline-info {
            padding: 0.375rem 1.75rem;
        }
    }
}
.input-group-wrapper {
    .input-group-text {
        border: 1px solid var(--light-gray);
    }
}
.multiple-inputs {
    .input-group {
        .form-control {
            border: 1px solid var(--light-gray);
            padding: 0.375rem 0.75rem;
        }
    }
    .multi-input {
        &.input-group {
            .input-group-text {
                &:nth-child(2) {
                    border-left: 1px solid #ced4da !important;
                }
            }
        }
    }
    .multi-input-2 {
        &.input-group {
            .input-group-text {
                &:nth-child(3) {
                    border-left: 1px solid #ced4da !important;
                }
            }
        }
    }
}
.wrapping-input {
    .form-control {
        border: 1px solid var(--light-gray);
        padding: 0.375rem 0.75rem;
    }
}
.variation-input {
    .input-group {
        .form-control {
            border: 1px solid var(--light-gray);
            padding: 0.375rem 0.75rem;
        }
    }
}
.radio-first-col-width {
    .custom-radio {
        .radio {
            &.radio-primary {
                input[type=radio] {
                    + {
                        label {
                            &::before {
                                border-color: var(--theme-default);
                            }
                        }
                    }
                }
            }
            input[type=radio] {
                &:disabled {
                    + {
                        label {
                            opacity: 0.65;
                            &::before {
                                cursor: not-allowed;
                            }
                        }
                    }
                }
            }
            &.radio-primary {
                input[type=radio] {
                    &:checked {
                        + {
                            label {
                                &::after {
                                    background-color: var(--theme-default);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .m-checkbox-inline {
        .radio {
            display: inline-block;
        }
    }
}
.select-components {
    .form-control {
        padding: 0.375rem 0.75rem;
    }
    select {
        border-color: var(--light-gray);
    }
}
.card-block {
    .table-responsive {
        .table-bordered {
            td {
                vertical-align: middle;
            }
        }
        @media (max-width: 767px) {
            .table {
                tbody {
                    tr {
                        td {
                            span {
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                max-width: 100px;
                            }
                        }
                    }
                }
            }
            .checkbox-td-width {
                tbody {
                    tr {
                        td {
                            min-width: 200px !important;
                        }
                    }
                }
            }
            .radio-first-col-width {
                tbody {
                    tr {
                        td {
                            min-width: 200px !important;
                        }
                    }
                }
            }
        }
    }
}
label {
    cursor: pointer;
}
.fill-radio {
    .radio {
        position: relative;
        margin: 0 20px 15px 0;
        cursor: pointer;
        box-shadow: none;
        vertical-align: -2px;
        &:checked:after {
            border-width: 10px !important;
        }
        &:checked::before {
            transform: scale(1, 1);
        }
        &::before {
            transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
            transform: scale(0, 0);
            content: "";
            position: absolute;
            top: 1px;
            left: 0.19rem;
            z-index: 1;
            width: 0.586rem;
            height: 0.556rem;
            border-radius: 50%;
        }
        &::after {
            content: "";
            position: absolute;
            top: -0.25rem;
            left: -0.125rem;
            width: 1.2rem;
            height: 1.2rem;
            background: var(--white);
            border-radius: 50%;
            border-width: 1px;
            border-style: solid;
        }
        &.radio-primary {
            &::before {
                background: var(--theme-default) !important;
            }
        }
        &.radio-border-primary {
            &::after {
                border-color: var(--theme-default) !important;
            }
        }
        &.radio-danger {
            &::before {
                background: var(--danger-color) !important;
            }
        }
        &.radio-border-danger {
            &::after {
                border-color: var(--danger-color) !important;
            }
        }
        &.radio-warning {
            &::before {
                background: var(--warning-color) !important;
            }
        }
        &.radio-border-warning {
            &::after {
                border-color: var(--warning-color) !important;
            }
        }
        &.radio-secondary {
            &::before {
                background: var(--theme-secondary) !important;
            }
        }
        &.radio-border-secondary {
            &::after {
                border-color: var(--theme-secondary) !important;
            }
        }
    }
}