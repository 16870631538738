.form-validation {
    .custom-input {
        .input-group-text {
            align-items: center;
            padding: 0.375rem 0.75rem;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5;
            color: #212529;
            text-align: center;
            white-space: nowrap;
            background-color: #e9ecef;
            border: 1px solid #ced4da;
            border-radius: 0.25rem;
        }
        .input-group {
            >.form-control {
                position: relative;
                flex: 1 1 auto;
                width: 1%;
                min-width: 0;
            }
        }
    }
    .radio-form {
        display: flex;
        gap: 18px;
        flex-wrap: wrap;
        .form-check {
            min-height: 0;
            margin-bottom: 0;
        }
        .form-check-input {
            &:checked {
                background-color: var(--succes-color);
            }
        }
    }
    .checkbox-checked {
        .form-check-input {
            &:checked {
                background-color: var(--success-color);
                border-color: var(--success-color);
            }
            &:focus {
                box-shadow: none;
            }
        }
    }
    .form-switch {
        .form-check-input {
            &:checked {
                background-color: var(--success-color);
            }
        }
    }
    .valid-form {
        .form-check-input {
            &:checked {
                background-color: var(--succes-color);
            }
        }
    }
}
.checkbox-checked {
    .radio-form {
        display: flex;
        gap: 18px;
        flex-wrap: wrap;
    }
    .form-check-input {
        &.primary {
            border-color: var(--theme-default);
            &:checked {
                background-color: var(--theme-default);
                border-color: var(--theme-default);
            }
        }
        &:focus {
            box-shadow: none;
        }
        &.secondary {
            border-color: var(--theme-secondary);
            &:checked {
                background-color: var(--theme-secondary);
                border-color: var(--theme-secondary);
            }
        }
        &.info {
            border-color: var(--info-color);
            &:checked {
                background-color: var(--info-color);
                border-color: var(--info-color);
            }
        }
        &.danger {
            border-color: var(--danger-color);
            &:checked {
                background-color: var(--danger-color);
                border-color: var(--danger-color);
            }
        }
        &.success {
            border-color: var(--success-color);
            &:checked {
                background-color: var(--success-color);
                border-color: var(--success-color);
            }
        }
    }
    &.custom-checkbox {
        .form-check-input {
            &:checked {
                background: none !important;
            }
        }
    }
    .form-check-input {
        &:checked {
            background-color: var(--theme-default);
            border-color: var(--theme-default);
        }
    }
}
.tooltip-valid {
    .input-group {
        flex-wrap: nowrap;
    }
}
.custom-input {
    .form-check {
        .form-check-input {
            &:invalid {
                border-color: var(--danger-color);
            }
            &:valid {
                border-color: var(--success-color);
            }
            &:focus {
                box-shadow: none;
            }
        }
    }
    .border {
        border: 1px solid var(--light-gray) !important;
    }
    .form-control {
        display: block;
        width: 100%;
        padding: 0.375rem 0.75rem;
        font-size: -2rem;
        font-weight: 400;
        line-height: 1.5;
        color: var(--dark-color);
        background-color: var(--white);
        background-clip: padding-box;
        border: 1px solid var(--light-gray);
        appearance: none;
        border-radius: 0.25rem;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        &:focus {
            border-color: var(--theme-default) !important;
        }
    }
}
.valid-form {
    .form-check-input {
        &:invalid {
            ~ {
                .form-check-label {
                    color: #dc3545;
                }
            }
        }
        &:valid {
            ~ {
                .form-check-label {
                    color: var(--success-color);
                }
            }
        }
    }
}
.form-validation {
    .form-check {
        .form-check-input {
            &:invalid {
                ~ {
                    .form-check-label {
                        color: #dc3545;
                    }
                }
            }
            &:valid {
                ~ {
                    .form-check-label {
                        color: var(--success-color);
                    }
                }
            }
        }
    }
}
.validation-forms {
    .needs-validation {
        .form-check {
            .form-check-input {
                &:valid {
                    ~ {
                        .form-check-label {
                            color: var(--success-color);
                        }
                    }
                }
            }
        }
    }
}
input {
    cursor: pointer;
}
.form-select {
    border: 1px solid var(--light-gray);
}
.basic-form {
    .checkbox-checked {
        .form-check-input {
            &:checked {
                background-color: var(--theme-default);
                border-color: var(--theme-default);
            }
            &:focus {
                box-shadow: none;
            }
        }
    }
    .border {
        border: 1px solid var(--light-gray) !important;
    }
    .form-control[type=file] {
        overflow: hidden;
    }
    .form-control {
        display: block;
        width: 100%;
        padding: 0.375rem 0.75rem;
        font-size: -2rem;
        font-weight: 400;
        line-height: 1.5;
        color: var(--body-font-color);
        background-color: var(--white);
        background-clip: padding-box;
        border: 1px solid var(--light-gray);
        appearance: none;
        border-radius: 0.25rem;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        &:focus {
            border-color: var(--theme-default) !important;
        }
    }
    .theme-form {
        &select {
            border-color: var(--gray-60);
            background-color: var(--light);
            font-size: 14px;
            color: #898989;
            padding: 12px 10px;
            box-shadow: none;
        }
    }
}
.custom-input {
    .form-control {
        color: #898989;
        &.form-control-color {
            padding: 0.375rem;
        }
    }
}
.form-select {
    &.btn-square {
        &.digits {
            color: #898989;
        }
    }
}
.btn-pill {
    border-radius: 60px;
}
.input-group-wrapper {
    .input-group {
        margin-bottom: 16px;
        .form-control {
            font-size: 16px;
            &:focus {
                border-color: var(--recent-border);
                box-shadow: unset;
            }
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
    .input-group> :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}
.input-group-wrapper {
    .input-group {
        .form-control {
            border: 1px solid var(--light-gray);
            border-radius: 8px;
        }
    }
    .form-control {
        padding: 0.375rem 0.75rem;
    }
}
.form-check-input {
    &:checked {
        background-color: var(--theme-default);
        border-color: var(--theme-default);
    }
    &:focus {
        box-shadow: none;
    }
}
.masks-input {
    .form-control {
        padding: 8px 12px;
        &:focus {
            border-color: var(--theme-default);
        }
    }
    .light-card {
        background-color: var(--body-color) !important;
    }
}